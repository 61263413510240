import React, { useState } from "react";
import {
  FaEnvelope,
  FaInstagram,
  FaLinkedinIn,
  FaPhone,
  FaPhoneAlt,
  FaTelegramPlane,
  FaYoutube,
} from "react-icons/fa";
import { FaFacebookF, FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import "./ContactUs.scss";
import { Input, LoadingButton, Select, TextArea } from "components/form";
import { submitQueryApi } from "services/master";
import { toastMessage } from "utils/toastMessage";
import { regex } from "utils/formValidator";
import { reasonData } from "utils/common";
const ContactUs = () => {
  const [submitQuery, setSubmitQuery] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const { email, name } = regex;

  // Validation for submit query
  const validation = () => {
    let errors = {};
    let status = true;
    if (!submitQuery?.name) {
      errors["name"] = "Name is mandatory";
      status = false;
    }
    // if (!submitQuery?.reason) {
    //   errors["reason"] = "Reason is mandatory";
    //   status = false;
    // }
    if (!submitQuery?.email) {
      errors["email"] = "Email is mandatory";
      status = false;
    } else if (!submitQuery.email.match(email)) {
      errors["email"] = "Please enter valid email";
      errors["emailErr"] = true;
      status = false;
    }
    if (!submitQuery?.type) {
      errors["type"] = "Reason is mandatory";
      status = false;
    }
    if (!submitQuery?.message) {
      errors["message"] = "Message is mandatory";
      status = false;
    }
    setError(errors);
    return status;
  };

  // Submit Query Handler
  const submitQueryHandler = async (e) => {
    e.preventDefault();
    if (validation()) {
      try {
        setLoading(true);
        const { data } = await submitQueryApi({
          ...submitQuery,
          type: submitQuery?.type,
        });
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "submitQuery");
          setSubmitQuery("");
        } else {
          toastMessage(data?.message, "error", "submitQuery");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <section className="contact_us">
        <div className="container">
          <div className="contactUs_content">
            <div className="content_left">
              <span>Contact us</span>
              <h1>Let’s Talk</h1>
              <p>Eightix India Pvt. Ltd.</p>
              <p className="address">
                kathghariya road, gurukul international school, kabdwal town,
                kamaluwa ghaja kabdal, nainital, uttarakhand, 263139, kamaluwa
                ghaja kabdal, uttarakhand 263139
              </p>
              <ul className="contact_info">
                <li>
                  <a href="tel:+919457483395">
                    <FaPhoneAlt />
                    +91 9457483395
                  </a>
                </li>
                <li>
                  <a href="mail:support@fitzure.com">
                    <FaEnvelope />
                    support@fitzure.com
                  </a>
                </li>
              </ul>
              <div className="socialIcons">
                <ul>
               
                  <li>
                    <Link to="https://www.instagram.com/fitzure" target="_blank">
                      <FaInstagram />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://x.com/fitzure30758" target="_blank">
                      <FaXTwitter />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.youtube.com/@fitzure" target="_blank">
                      <FaYoutube />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.facebook.com/Fitzure" target="_blank">
                      <FaFacebookF />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.linkedin.com/company/fitzure" target="_blank">
                      <FaLinkedinIn />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="form">
              <form>
                {/* <div className="form_field_wrap">
                  <div className="form_field">
                    <Input label="Name" type={"text"} placeholder="Your Name" />
                  </div>
                  <div className="form_field">
                    <Input
                      label="Email"
                      type={"email"}
                      placeholder="Your Email"
                    />
                  </div>
                </div>
                <div className="form_field">
                  <Select
                    label="Select Reason"
                    type={"text"}
                    placeholder="Your Name"
                  />
                </div>
                <div className="form_field">
                  <TextArea
                    label="Message"
                    type={"text"}
                    placeholder="Your Message"
                  />
                </div>

                <div className="button_wrap">
                    <LoadingButton className="w-100">Send Message</LoadingButton>
                </div> */}

                <div className="form_field_wrap">
                  <div className="form_field">
                    <Input
                      type="text"
                      label="Name"
                      placeholder="Your Name"
                      name="name"
                      value={submitQuery?.name || ""}
                      onChange={(e) => {
                        setSubmitQuery({
                          ...submitQuery,
                          name: e.target.value,
                        });
                      }}
                      error={error?.name}
                    />
                  </div>
                  <div className="form_field">
                    <Input
                      type="email"
                      label="Email"
                      placeholder="Your Email"
                      name="email"
                      value={submitQuery?.email || ""}
                      onChange={(e) => {
                        setSubmitQuery({
                          ...submitQuery,
                          email: e.target.value,
                        });
                      }}
                      error={error?.email}
                      extraError={error?.emailErr}
                    />
                  </div>
                </div>
                {/* <div className="form_field">
                  <Select
                    label="Select Reason"
                    name="reason"
                    value={submitQuery?.reason || ""}
                    onChange={(e) => {
                      setSubmitQuery({
                        ...submitQuery,
                        reason: e.target.value,
                      });
                    }}
                    error={error?.reason}
                  >
                    <option value="">Select Reason</option>
                    {resaonData.map((data) => (
                      <option value={data} key={data}>
                        {data}
                      </option>
                    ))}
                  </Select>
                </div> */}
                {/* <div className="form_field">
                  <Select
                    label="Select Type"
                    name="type"
                    value={submitQuery?.type || ""}
                    onChange={(e) => {
                      setSubmitQuery({ ...submitQuery, type: e.target.value });
                    }}
                    error={error?.type}
                  >
                    <option value="">Select Type</option>
                    <option value="feedback">Feedback</option>
                    <option value="query">Query</option>
                  </Select>
                </div> */}

                <div className="form_field">
                  <Select
                    label="Type"
                    name="type"
                    value={submitQuery?.type || ""}
                    onChange={(e) => {
                      setSubmitQuery({ ...submitQuery, type: e.target.value });
                    }}
                    error={error?.type}
                  >
                    <option value="">Select Type</option>
                    {reasonData.map((data) => (
                      <option value={data?.value} key={data?.value}>
                        {data?.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="form_field">
                  <TextArea
                    label="Your Message"
                    placeholder="Your Message"
                    name="message"
                    value={submitQuery?.message || ""}
                    onChange={(e) => {
                      setSubmitQuery({
                        ...submitQuery,
                        message: e.target.value,
                      });
                    }}
                    error={error?.message}
                  />
                </div>
                <div className="button_wrap">
                  <LoadingButton
                    loading={loading}
                    onClick={submitQueryHandler}
                    className="w-100"
                  >
                    Send Message
                  </LoadingButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
