import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../config/axiosInstance";

// Personal Detail
export const personDetailApi = async (id) => {
  return await axios.get(`expert/profile-details?screen=${id}`, setJwtToken());
};

// ---------------------Personal Detail Update ----------------------------------
export const personalUpdateApi = async (data) => {
  return await axios.put(
    "account/update-personal-details",
    data,
    setMultiPartHeader()
  );
};

// ---------------------Additional Detail Update ----------------------------------
export const professionalDetailUpdateApi = async (data) => {
  return await axios.put(
    "expert/update-professional-details",
    data,
    setMultiPartHeader()
  );
};

// ---------------------Account Detail Update ----------------------------------
export const accountUpdateApi = async (data) => {
  return await axios.put("expert/update-bank-details", data, setJwtToken());
};

// ---------------------Additional Detail Update ----------------------------------
export const additionalUpdateApi = async (data) => {
  return await axios.put(
    "expert/update-additional-details",
    data,
    setJwtToken()
  );
};

// Expert Detail
export const getExpertInfoApi = async () => {
  return await axios.get(`expert/my-details`, setJwtToken());
};

// Authorize Zoom Account
export const authorizeZoomAccountApi = async (code) => new Promise((resolve, reject)=> {
  // console.log(code,"Code");
  let endPoint;
  if (code) {
    endPoint = `expert/auth-zoom?code=${code}`;
  } else {
    // console.log(code,"Code");
    endPoint = `expert/auth-zoom`;
  }
  axios.get(endPoint, setJwtToken())
  .then(resolve)
  .catch(reject)
})

export const myProfile = async () => {
  return await axios.get(`account/details`, setJwtToken());
};
