import { service1 } from "assets/images";
import AuthLogin from "auth/login/Login";
import SignUp from "auth/signUp/SignUp";
import PurchaseButton from "components/PurchaseServices/PurchaseButton";
import { Button, LoadingButton } from "components/form";
import CustomModal from "components/modals/CustomModal";
import React, { useState } from "react";
import { IoCalendarOutline } from "react-icons/io5";
import { MdAccessTime } from "react-icons/md";
import { dateFormat } from "utils/dateFormat";

const ConfirmPayModal = ({
  show,
  handleClose,
  modalTitle,
  serviceTag,
  serviceTittle,
  duration,
  price,
  date,
  time,
  buttonText,
  onClick,
  isSubscriptionModel = false,
  handleAutoPay,
  autoPay,
  loginModal,
  setLoginModal,
  authModal,
  setAuthModal,
  loading,
  expertId,
  serviceId,
  token,
  setPayoutModal,
  payoutModal,
  expertServicesDetail,
  setPaymentStatusModal,
  bookingDate,
  days,
  bookingEndDate = "",
  ...rest
}) => {
  const [paymentDetails, setPaymentDetails] = useState({});

  const showTextHandler = () => {
    switch (serviceTag) {
      case "Priority DM":
        return (
          <p>
            Response Time <b>{rest?.responseTime}</b>
          </p>
        );

      case "Digital Course" || "Digital Product":
        if (expertServicesDetail?.digitalContentType === "course") {
          return (
            <p>
              No. Of Chapters <b>{expertServicesDetail?.noOfChapters}</b>
            </p>
          );
        } else {
          return (
            <p>
              Digital Course <b>{1}</b>
            </p>
          );
        }
      case "Webinar":
        return (
          <p>
            Duration <b>{`${duration}${" "}(Mins)`}</b>
          </p>
        );
      case "1:1 Consultation":
        return (
          <p>
            Duration <b>{`${duration}${" "}(Mins)`}</b>
          </p>
        );

      case "Personal Training" || "Group Training" || "Batch Training":
        return (
          <>
            <p>
              Start Date <b>{`${dateFormat(bookingDate)}${" "}`}</b>
            </p>
            <p>
              End Date <b>{`${dateFormat(rest?.endingDate)}${" "}`}</b>
            </p>
            <p>
              Time <b>{`${time}${" "}`}</b>
            </p>
            <p>
              Duration <b>{`${duration}${" "}`}</b>
            </p>
          </>
        );
      case "Package":
        return (
          <>
            <p>
              Total Services{" "}
              <b>
                {`${expertServicesDetail?.services &&
                  expertServicesDetail?.services.length}${" "}Services`}
              </b>
            </p>
          </>
        );

      default:
        return null;
    }
  };

  console.log(price,"price");

  return (
    <>
      <CustomModal
        show={show}
        handleClose={handleClose}
        className={`confirm_pay ${
          serviceTag !== "Priority DM" &&
          serviceTag !== "Digital Course" &&  serviceTag!=="Digital Product" &&
          serviceTag !== "Personal Training" &&  serviceTag !== "Group Training" &&  serviceTag !== "Batch Training" &&
          serviceTag !== "Package"
            ? "md"
            : "priority"
        }`}
      >
        <h3>{modalTitle}</h3>
        <div className="content_wrap">
          <div className="_card">
            <img src={service1} alt="" />
            <div className="content">
              <span>{serviceTag}</span>
              <h5>{serviceTittle}</h5>
              {showTextHandler()}
              <p>
                <b className="color-green">₹ {price}</b> (Include taxes)
              </p>
            </div>
          </div>

          {serviceTag !== "Priority DM" &&
            serviceTag !== "Digital Course" && serviceTag!=="Digital Product" &&
            // serviceTag !== "Subscription" &&
            serviceTag !== "Personal Training" &&  serviceTag !== "Group Training" &&  serviceTag !== "Batch Training" &&
            serviceTag !== "Package" && (
              <div className="content_right">
                <div className="schedule">
                  <h4>Your date & time</h4>
                  <p>
                    <IoCalendarOutline />
                    <span>{date}</span>
                  </p>
                  <p>
                    <MdAccessTime />
                    <span>{time}</span>
                  </p>
                </div>
                {isSubscriptionModel && (
                  <label
                    htmlFor="true"
                    className={`radio_button input ${autoPay ? "active" : ""}`}
                  >
                    <input
                      type="radio"
                      name="showSlashedAmount"
                      id="true"
                      checked={autoPay}
                      onClick={handleAutoPay}
                    />
                    <span>Set AutoPay</span>
                  </label>
                )}
                <PurchaseButton
                  className={"w-100 button dashboard_button"}
                  price={price || 0}
                  token={token}
                  bookingEndDate={bookingEndDate}
                  servicesDetail={expertServicesDetail}
                  setLoginModal={setLoginModal}
                  setPayoutModal={setPayoutModal}
                  serviceId={serviceId}
                  expertId={expertId}
                  setPaymentDetails={setPaymentDetails}
                  setPaymentStatusModal={setPaymentStatusModal}
                  bookingDate={bookingDate}
                  time={time}
                  serviceTag={serviceTag}
                  days={days}
                  {...rest}
                >
                  Process to Pay
                </PurchaseButton>

                {/* <div className="button_wrap">
                <LoadingButton
                  loading={loading}
                  className={"dashboard_button w-100"}
                  onClick={onClick}
                >
                  {buttonText}
                </LoadingButton>
              </div> */}
              </div>
            )}
          {(serviceTag === "Priority DM" ||
            serviceTag === "Digital Course" || serviceTag==="Digital Product" || 
            // serviceTag === "Subscription" ||
            serviceTag === "Personal Training" ||
            serviceTag === "Group Training" ||
            serviceTag === "Batch Training" ||
            serviceTag === "Package") && (
            <PurchaseButton
              className={"w-100 button dashboard_button"}
              token={token}
              price={price || 0}
              servicesDetail={expertServicesDetail}
              setLoginModal={setLoginModal}
              setPayoutModal={setPayoutModal}
              serviceId={serviceId}
              expertId={expertId}
              setPaymentDetails={setPaymentDetails}
              setPaymentStatusModal={setPaymentStatusModal}
              bookingDate={bookingDate}
              serviceTag={serviceTag}
              bookingEndDate={bookingEndDate}
              days={days}
              {...rest}
            >
              Process to Pay
            </PurchaseButton>
          )}
        </div>
      </CustomModal>

      <CustomModal show={loginModal} handleClose={() => setLoginModal(false)}>
        {authModal ? (
          <AuthLogin
            moveSignUp={() => setAuthModal(false)}
            setLoginModal={setLoginModal}
            showSignUp={false}
          />
        ) : (
          <SignUp
            moveLogin={() => setAuthModal(true)}
            setLoginModal={setLoginModal}
          />
        )}
      </CustomModal>
    </>
  );
};

export default ConfirmPayModal;
