import {
  Button,
  CommonSelect,
  Input,
  LoadingButton,
  Select,
  TextArea,
} from "components/form";
import React, { Fragment, useEffect, useState } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import {
  additionalUpdateApi,
  authorizeZoomAccountApi,
  personDetailApi,
} from "services/details";
import { toastMessage } from "utils/toastMessage";
import { regex } from "utils/formValidator";
import commonMessages from "utils/commonMessages";
import { setStepsInfo } from "store/loginInfoSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { proceedNextStep } from "utils/common";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { setPersonalInfo, setSteps } from "store/slices/configSlice";

const AdditionalDetails = ({ signUpForm = true }) => {
  const [loader, onShow, onHide] = FullPageLoader();
  const { url } = regex;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { VALID_URL } = commonMessages;
  const [additionalDetailField, setAdditionalDetailField] = useState({
    bio: "",
  });
  const [addSocialError, setAddSocialError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [error, setError] = useState({});
  const data = {
    id: Date.now(),
    name: "",
    url: "",
  };
  const [addSocialInfo, setAddSocialInfo] = useState([data]);

  const languageOptions = [
    { label: "English", value: "English" },
    { label: "Hindi", value: "Hindi" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdditionalDetailField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSocialInfoChange = (e, index) => {
    const { name, value } = e.target;
    const updatedSocialInfo = addSocialInfo.map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    setAddSocialInfo(updatedSocialInfo);
  };

  const validation = () => {
    let status = true;
    const errors = {};
    let socialInfoErrMsg = {};
    let socialErr = [];
    if (!additionalDetailField.bio) {
      errors["bio"] = "Bio is mandatory";
      status = false;
    }
    if (
      additionalDetailField?.introductionVideoLink &&
      !additionalDetailField?.introductionVideoLink.match(url)
    ) {
      errors["introductionVideoLink"] = VALID_URL;
      errors["introductionVideoLinkErr"] = true;
      status = false;
    }
    if (languages.length === 0) {
      errors["languages"] = "Language is mandatory";
      status = false;
    }
    if (addSocialInfo.length > 0) {
      addSocialInfo.forEach((element, index) => {
        const socialInfoErrMsg = {};
        const hasAnyField = element.name || element.url;

        if (hasAnyField) {
          if (!element.name) {
            socialInfoErrMsg["name"] = "Please select a social platform";
            status = false;
          }

          // Validate `url` field
          if (!element.url) {
            socialInfoErrMsg["url"] = "URL is mandatory";
            status = false;
          } else if (!element.url.match(url)) {
            socialInfoErrMsg["url"] = VALID_URL;
            socialInfoErrMsg["urlErr"] = true;
            status = false;
          }
        }

        // If there are any errors for this element, push it to `socialErr`
        if (Object.keys(socialInfoErrMsg).length > 0) {
          socialErr.push(socialInfoErrMsg);
        }
      });
    }

    setError(errors);
    setAddSocialError(socialErr);
    return status;
  };

  const addSocialInfoFieldHandler = () => {
    setAddSocialInfo([...addSocialInfo, { id: Date.now(), name: "", url: "" }]);
  };

  const removeSocialInfoFieldHandler = (index) => {
    setAddSocialInfo((prevState) => prevState.filter((_, i) => i !== index));
  };

  // Fetching Account Detail
  const fetchingAdditionalDetail = async (screen = 3) => {
    try {
      onShow();
      const response = await personDetailApi(screen);

      if (response && response.data && response.data.code === 200) {
        const { data } = response.data;

        const languages = data.languages.map((language) => ({
          label: language,
          value: language,
        }));

        setLanguages(languages);
        if (data?.socialLinks && data?.socialLinks.length > 0) {
          setAddSocialInfo(data.socialLinks);
        }
        setAdditionalDetailField({
          introductionVideoLink: data.introductionVideoLink,
          bio: data.bio,
        });
      } else {
        toastMessage(
          response?.data?.message || "Failed to fetch additional details",
          "error",
          "additionalDetail"
        );
      }
    } catch (error) {
      console.error("Error fetching additional details:", error.message);
      toastMessage(
        "An error occurred while fetching additional details.",
        "error",
        "additionalDetailError"
      );
    } finally {
      onHide();
    }
  };

  const additionalUpdateDetail = async () => {
    if (validation()) {
      try {
        setLoading(true);
        const languageData =
          languages && languages.length > 0
            ? languages.map((data) => data.value)
            : [];
        const formData = {
          ...additionalDetailField,
          languages: languageData || [],
          socialLinks: addSocialInfo,
        };

        const response = await additionalUpdateApi(formData);
        if (response?.data?.code === 200) {
          toastMessage(response?.data?.message, "success", "additonalDetail");
          const localData = JSON.parse(localStorage.getItem("fitzure_info"));
          localData.moduleSteps = response?.data?.data?.expertProfileStatus;
          localStorage.setItem("fitzure_info", JSON.stringify(localData));

          dispatch(setStepsInfo(response?.data.data?.expertProfileStatus));
          dispatch(
            setSteps({ steps: response?.data.data?.expertProfileStatus })
          );
          dispatch(setPersonalInfo({ personalInfo: localData }));
          if (signUpForm) {
            navigate("/dashboard/home");
          } else {
            navigate("/dashboard/profile");
          }
        } else {
          toastMessage(response?.data?.message, "error", "aditionalDetail");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const authorizeZoomAccountHandler = async () => {
    const res = await authorizeZoomAccountApi();
    // window.open("https://devnode.foodonreels.com:4567/api/v1/expert/auth-zoom", "_blank");
    console.log(res, "Res");
    // console.log(res, "Response");
  };

  // console.log(addSocialInfo, "AddSocialInfo");

  useEffect(() => {
    fetchingAdditionalDetail();
  }, []);

  console.log(error, "Errros");

  return (
    <>
      <div className="personal_detail additional_details">
        <h3 className="title">Additional Details</h3>
        {loader}
        <div className="form_field_wrap">
          <div className="form_field">
            <TextArea
              type="text"
              className="form-control input"
              placeholder="Enter Bio"
              label="Tell us about yourself"
              error={error.bio}
              value={additionalDetailField.bio}
              name="bio"
              onChange={handleChange}
            />
          </div>
          <div className="form_field input_wrapper">
            <div className="form_field">
              {/* <label className="label">Education</label>
              <select className="form-control input">
                <option>English, Hindi</option>
              </select> */}

              <CommonSelect
                className="form-control input"
                label={"Select Language"}
                options={languageOptions}
                onChange={setLanguages}
                value={languages}
                isMulti={true}
                isSearchable={true}
                // required
                error={languages.length === 0 && error.languages}
              />
            </div>
            <div className="form_field">
              <Input
                type="url"
                className="form-control input"
                placeholder="link"
                label="Introduction Video Link"
                error={error.introductionVideoLink}
                value={additionalDetailField?.introductionVideoLink || ""}
                name="introductionVideoLink"
                onChange={handleChange}
                extraError={error.introductionVideoLinkErr}
              />
            </div>
          </div>
        </div>

        <h3 className="title">Social links</h3>
        <div className="form_field_wrap multiple">
          {addSocialInfo &&
            addSocialInfo.length > 0 &&
            addSocialInfo.map((data, index) => {
              return (
                <Fragment key={data.id}>
                  <div className="form_field">
                    <Select
                      className="form-control input"
                      label="Select Link"
                      name="name"
                      value={data.name}
                      onChange={(e) => handleSocialInfoChange(e, index)}
                      error={addSocialError[index]?.name}
                    >
                      <option value="">Select Social Platform</option>
                      <option value="facebook">Facebook</option>
                      <option value="instagram">Instagram</option>
                      <option value="youtube">Youtube</option>
                      <option value="linkedIn">LinkedIn</option>
                      <option value="pinterest">Pinterest</option>
                      <option value="x">X</option>
                    </Select>
                  </div>
                  <div className="form_field">
                    <Input
                      type="url"
                      className="form-control input"
                      placeholder="Enter Url"
                      label="Enter Url"
                      name="url"
                      value={data?.url}
                      onChange={(e) => handleSocialInfoChange(e, index)}
                      error={addSocialError[index]?.url}
                      extraError={addSocialError[index]?.urlErr}
                    />
                  </div>
                  <div className="form_field">
                    {index === 0 ? (
                      <>
                        {" "}
                        <label className="label dummy">Dummy text</label>
                        <Button
                          className="w-100 light"
                          // isDisabled={addSocialInfo.length >= 5}
                          onClick={addSocialInfoFieldHandler}
                        >
                          Add Link
                        </Button>
                      </>
                    ) : (
                      <>
                        <label className="label dummy">Dummy text</label>
                        <Button
                          className="w-100 light"
                          onClick={() => removeSocialInfoFieldHandler(index)}
                        >
                          Remove Link
                        </Button>
                      </>
                    )}
                  </div>
                </Fragment>
              );
            })}

          {/* <div className="form_field">
            <Input
              type="checkbox"
              className="check"
              placeholder="Enter Url"
              label="Authorize your zoom account"
              name="url"
              onClick={authorizeZoomAccountHandler}
            />
          </div> */}
        </div>

        <div className="button_wrap dashboard">
          <p className="information">
            <AiFillInfoCircle />
            To set up your profile, please provide the following details.
          </p>
          <LoadingButton
            type="button"
            onClick={additionalUpdateDetail}
            loading={loading}
          >
            Save and Next
          </LoadingButton>
        </div>
      </div>
    </>
  );
};

export default AdditionalDetails;
