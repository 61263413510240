import AddService from "components/cards/servicesCard/AddService";
import "../ManageServices.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  DatePicker,
  Input,
  LoadingButton,
  Select,
  TextArea,
} from "components/form";
import { IoCloudUploadOutline } from "react-icons/io5";
import "../../../../components/cards/servicesCard/ServicesCard.scss";
import { FaTimes } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { BsCurrencyRupee } from "react-icons/bs";
import { testimonial } from "staticData";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import CustomModal from "components/modals/CustomModal";
import { getTestimonials } from "services/master";
import commonMessages, { slashedValidation } from "utils/commonMessages";
import { allowNumber, regex } from "utils/formValidator";
import { toastMessage } from "utils/toastMessage";
import { useNavigate, useParams } from "react-router-dom";
import { addOneToOneApi } from "services/expertServices/manage/consultations";
import { TimePicker } from "antd";
import {
  addServicesApi,
  getHowToApi,
  getServicesDetailApi,
  updateServicesApi,
} from "services/expertServices/manage";
import ReactDatePicker from "react-datepicker";
import {
  addDurationToTime,
  completeTimeFormat,
  dateFormatUtc,
  timeFormat,
  timeFormat_24,
} from "utils/dateFormat";
import moment from "moment-timezone";
import HowTo from "../HowTo";
import GetTestimonials from "../GetTestimonials";
import Placeholder from "components/placeholder/Placeholder";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import JoditEditor from "jodit-react";
import CoverImageCropper from "../CoverImageCropper";

const AddRecurringSubscription = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedWeekDay, setSelectedWeekDay] = useState([]);
  const { serviceType, serviceId, subscriptionType } = useParams();
  const { IMAGE_FORMAT } = commonMessages;
  const [image, setImage] = useState("");
  const { imageFileRegex, url } = regex;
  const [inputField, setInputField] = useState({ serviceType: serviceType });
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [testimonials, setTestimonials] = useState([]);
  const [active, setActive] = useState("yes");
  const [modal, setModal] = useState(false);
  const [bestTestimonials, setBestTestimonials] = useState([]);
  const [showTestimonialModal, setShowTestimonilModal] = useState(false);
  const navigate = useNavigate();
  const [viewInfoModal, setViewInfoModal] = useState(false);
  const [total, setTotal] = useState(null);
  const [pricing, setPricing] = useState([]);
  const [loader, onShow, onHide] = FullPageLoader();
  const [activePage, setActivePage] = useState(1);

  const duration = ["15", "30", "45", "60", "75", "90", "105", "120"];
  const typeOfServices = [
    {
      name: "Personal Trainings",
      value: "private",
    },
    {
      name: "Group Trainings",
      value: "public",
    },
  ];
  const weekDays = [
    { name: "Mon", value: 1 },
    { name: "Tue", value: 2 },
    { name: "Wed", value: 3 },
    { name: "Thu", value: 4 },
    { name: "Fri", value: 5 },
    { name: "Sat", value: 6 },
    { name: "Sun", value: 0 },
  ];

  const [subscriptionAvailability, setSubscriptionAvailability] = useState([]);
  const [
    privateSubscriptionAvailability,
    setPrivateSubscriptionAvailability,
  ] = useState({});

  //Fetching How To Api
  const fetchingHowTo = async () => {
    try {
      const { data } = await getHowToApi(serviceType);
      if (data?.code === 200) {
        setInputField({
          ...inputField,
          howTo: data?.data?.url,
          howToId: data?.data?._id,
        });
      } else {
        toastMessage(data?.message, "error", "howTo");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
    }
  };

  useEffect(() => {
    fetchingHowTo();
  }, []);

  const fetchingServiceDetailHandler = async () => {
    try {
      onShow();
      const { data } = await getServicesDetailApi(serviceId);
      if (data?.code === 200) {
        setInputField({
          ...data?.data,
          subscriptionCycle:
            data?.data?.subscriptionAvailability?.subscriptionCycle,
        });
        setBestTestimonials(data?.data?.testimonials || []);
        setPricing(
          {
            ...data?.data?.pricing,
            showSlashedAmount: data?.data?.pricing?.showSlashedAmount
              ? "yes"
              : "no",
          } || {}
        );
        setImage(data?.data?.image);

        // if (data?.data?.recurringServiceType === "private") {
        //   setSubscriptionAvailability(
        //     data?.data?.privateSubscriptionAvailability?.availabilities[0]?.range.map(
        //       (data) => {
        //         return {
        //           startTime: completeTimeFormat(data?.startTime),
        //           endTime: completeTimeFormat(data?.endTime),
        //         };
        //       }
        //     )
        //   );
        // } else {
        setSubscriptionAvailability(
          data?.data?.subscriptionAvailability?.range.map((data) => {
            return {
              startTime: completeTimeFormat(data?.startTime),
              endTime: completeTimeFormat(data?.endTime),
            };
          })
        );
        // }

        let selectedWeekDay = {};

        if (data?.data?.recurringServiceType === "private") {
          selectedWeekDay = weekDays.filter((day) =>
            data?.data?.privateSubscriptionAvailability?.days.includes(
              day.value
            )
          );
        } else {
          selectedWeekDay = weekDays.filter((day) =>
            data?.data?.subscriptionAvailability?.days.includes(day.value)
          );
        }

        setSelectedWeekDay(selectedWeekDay);
      } else {
        toastMessage(data?.message, "error", "ServiceDetail");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  useEffect(() => {
    fetchingServiceDetailHandler();
  }, []);

  // console.log(inputField,"InputField");

  // For text editor
  const editor = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false,
      placeholer: "",
      toolbar: true,
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      uploader: {
        insertImageAsBase64URI: true,
      },
      controls: {
        font: {
          command: "fontname",
          list: {
            "'Open Sans',sans-serif": "Open Sans",
            "Helvetica,sans-serif": "Helvetica",
            "Arial,Helvetica,sans-serif": "Arial",
            "Georgia,serif": "Georgia",
            "Impact,Charcoal,sans-serif": "Impact",
            "Tahoma,Geneva,sans-serif": "Tahoma",
            "'Times New Roman',Times,serif": "Times New Roman",
            "Verdana,Geneva,sans-serif": "Verdana",
            "Consolas,monaco,monospace": "Consolas",
          },
        },
      },
    }),
    []
  );

  const isWeekday = (date) => {
    const day = date.getDay();
    return selectedWeekDay.some((weekday) => weekday.value === day);
  };

  const selectedWeekDayHandler = (data) => {
    const objectIndex = selectedWeekDay.findIndex(
      (day) => day.value === data.value
    );
    const tempWeekDay = [...selectedWeekDay];

    if (objectIndex !== -1) {
      tempWeekDay.splice(objectIndex, 1);
    } else {
      tempWeekDay.push(data);
    }

    setSelectedWeekDay(tempWeekDay);
  };

  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        setImage(e.target.files[0]);
      } else {
        e.target.value = "";
        setImage("");
        toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      }
    }
  };

  const deleteImg = (e) => {
    e.target.value = null;
    setImagePreview(null);
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    setActive(event);
  };

  const fetchingTestimonialHandler = async () => {
    try {
      setTestimonials([]);
      const sendData = {
        serviceType: serviceType,
        page: activePage - 1,
        pageSize: 10,
      };
      const { data } = await getTestimonials(sendData);
      if (data?.code === 200) {
        setTestimonials(data?.data);
        setTotal(data?.totalItems);
      } else {
        setTestimonials([]);
        toastMessage(data?.message, "error", "GetTestimonials");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Input Field Handler
  const inputFieldHandler = (e) => {
    const { name, value } = e.target;
    if (name === "video") {
      setInputField({ ...inputField, [name]: value });
    }
    if (name === "amount" || name === "slashAmount") {
      setInputField({ ...inputField, [name]: allowNumber(value) });
    } else setInputField({ ...inputField, [name]: value });
  };

  // Pricing Handler
  const pricingHandler = (e) => {
    const { name, value } = e.target;
    if (name === "showSlashedAmount") {
      setPricing({ ...pricing, [name]: value, slashAmount: "" });
    } else setPricing({ ...pricing, [name]: allowNumber(value) });
  };

  const validation = () => {
    let status = true;
    let errors = {};
    if (!inputField?.title) {
      errors["title"] = "Title is mandatory";
      status = false;
    }
    if (!inputField?.description) {
      errors["description"] = "Description is mandatory";
      status = false;
    }
    if (!inputField?.recurringServiceType) {
      errors["recurringServiceType"] = "Please select service type";
      status = false;
    }
    if (!inputField?.duration) {
      errors["duration"] = "Select duration";
      status = false;
    }
    // if (!subscriptionAvailability?.length) {
    //   errors["time"] = "Select Time";
    //   status = false;
    // }

    if (inputField?.video && !inputField?.video.match(url)) {
      errors["video"] = "Invalid Url";
      errors["videoErr"] = true;
      status = false;
    }
    if (!pricing?.amount) {
      errors["amount"] = "Amount is mandatory";
      status = false;
    }
    if (!pricing?.showSlashedAmount) {
      errors["showSlashedAmount"] = "Show slash pricing?";
      status = false;
    }
    if (pricing?.showSlashedAmount == "yes" && !pricing?.slashAmount) {
      errors["slashAmount"] = "Enter Slash amount";
      status = false;
    }
    if (selectedWeekDay.length === 0 || !selectedWeekDay) {
      errors["selectedWeekDay"] = "Please select atleast 1 week day";
      status = false;
    }
    let { error, slashstatus } = slashedValidation(
      pricing?.showSlashedAmount,
      pricing?.amount,
      pricing?.slashAmount
    );
    if (Object.keys(error).length > 0) {
      Object.assign(errors, error);
      status = slashstatus;
    }

    setError(errors);
    return status;
  };

  const addTimeHandler = () => {
    const { time, duration } = inputField || {};
    if (time && duration) {
      setSubscriptionAvailability((prev) => [
        ...prev,
        {
          startTime: time,
          endTime: addDurationToTime(time, duration),
        },
      ]);
      setInputField({ ...inputField, time: "" });
    } else {
      toastMessage(
        "Please provide both time and duration",
        "error",
        "timeslotadd"
      );
    }
  };

  const updateServiceHandler = async () => {
    if (validation()) {
      const subscriptionAvailabilityData = {
        days:
          selectedWeekDay &&
          selectedWeekDay.length &&
          selectedWeekDay.map((data) => data?.value),
        range: subscriptionAvailability.map((data) => {
          return {
            startTime: timeFormat_24(data?.startTime),
            endTime: timeFormat_24(data?.endTime),
          };
        }),
        subscriptionCycle: inputField?.subscriptionCycle,
      };

      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("_id", serviceId);
        if (image && image?.name) {
          formData.append("image", image);
        }
        // formData.append(
        //   "pricing",
        //   JSON.stringify({
        //     ...pricing,
        //     showSlashedAmount:
        //       pricing?.showSlashedAmount === "yes" ? true : false,
        //   })
        // );

        formData.append("description", inputField?.description);
        if (inputField?.video) {
          formData.append("video", inputField?.video);
        } else {
          formData.append("video", "");
        }
        // formData.append("duration", inputField?.duration);
        formData.append("title", inputField?.title);
        formData.append("serviceType", serviceType);
        formData.append(
          "recurringServiceType",
          inputField?.recurringServiceType
        );
        formData.append("subscriptionType", subscriptionType);
        // formData.append(
        //   "subscriptionAvailability",
        //   JSON.stringify(subscriptionAvailabilityData)
        // );

        const { data } = await updateServicesApi(formData);
        // console.log(data, "data");
        if (data?.code === 200) {
          navigate(
            `/dashboard/manage-services/subscription/detail/${serviceType}/${serviceId}/${subscriptionType}`
          );
          toastMessage(data?.message, "success", "subscription");
          setInputField({});
          setError({});
          navigate("/dashboard/manage-services?serviceType=subscription");
        } else {
          toastMessage(data?.message, "error", "subscription");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  // console.log(selectedWeekDay, "selectedWeekDay");
  const removeTimeSlotHandler = (idx) => {
    const tempData = subscriptionAvailability.filter(
      (_, index) => index !== idx
    );
    setSubscriptionAvailability(tempData);
  };

  useEffect(() => {
    fetchingTestimonialHandler();
  }, []);

  console.log(inputField, "Input Field");

  return (
    <>
      <div className="edit_service">
        <div className="dashboard_title">
          <h3>Recurring Trainings</h3>
          {/* <span onClick={() => setViewInfoModal(true)}>How To</span> */}
        </div>

        <div className="dashboard_content_inner">
          <div className="add_services_from">
            <form>
              <div className="content_left">
                <div className="form_field">
                  <Input
                    label={"Title"}
                    name="title"
                    value={inputField?.title || ""}
                    placeholder={"Enter Title"}
                    onChange={inputFieldHandler}
                    maxlength="100"
                    error={error?.title}
                  />
                </div>
                <div className="form_field">
                <label className="label">Description</label>
                  <JoditEditor
                    ref={editor}
                    value={inputField?.description}
                    tabIndex={1}
                    onBlur={(value) =>
                      setInputField({ ...inputField, description: value })
                    }
                    config={config}
                  />
                  <p className="invalid-feedback d-block">
                    {error?.description}
                  </p>
                </div>
                <div className="form_field">
                  <Input
                    label={"Overview Video Link"}
                    name="video"
                    value={inputField?.video || ""}
                    placeholder={"Enter url"}
                    onChange={inputFieldHandler}
                    error={error?.video}
                    extraError={error?.videoErr}
                  />
                </div>
              </div>
              <div className="content_right">
                <div className="form_field">
                  <Select
                    label={"Type of services"}
                    disabled
                    name="recurringServiceType"
                    value={inputField?.recurringServiceType || ""}
                    onChange={inputFieldHandler}
                    error={error?.recurringServiceType}
                  >
                    {typeOfServices &&
                      typeOfServices.length &&
                      typeOfServices.map((data, index) => (
                        <option key={index} value={data?.value}>
                          {data?.name}
                        </option>
                      ))}
                  </Select>
                </div>

                {/* <div className="form_field position-relative">
                  <label className="label d-block">Upload Cover Image</label>
                  {imagePreview && (
                    <span className="delete_button" onClick={deleteImg}>
                      <FaTimes />
                    </span>
                  )}
                  <label className="imagePreview" htmlFor="fileUpload">
                    <input
                      type="file"
                      name="image"
                      id="fileUpload"
                      onChange={imageHandler}
                      error={error?.image}
                    />
                    {image ? (
                      <>
                        <img
                          src={
                            image && image?.name
                              ? URL.createObjectURL(image)
                              : image
                          }
                          alt=""
                        />
                      </>
                    ) : (
                      <div className="content">
                        <IoCloudUploadOutline />
                        <label className="button" htmlFor="fileUpload">
                          Browse
                        </label>
                        <span>drop a file here</span>
                        <p>*File supported .png, .jpg, .webp</p>
                      </div>
                    )}
                  </label>
                </div>

                {error.image && (
                  <span className="invalid-feedback d-block">
                    {error?.image}
                  </span>
                )} */}

<CoverImageCropper
                  imageSelect={(img) => {
                    setImage(img);
                  }}
                  prevImage={image}
                  error={error?.image}
                />
              </div>
            </form>
            <div className="pricing">
              <h5>Pricing</h5>
              <div className="pricing_content">
                <div className="form_field">
                  <label className="label">Enter Amount</label>
                  <div className="input_wrap">
                    <Input
                      type={"text"}
                      name="amount"
                      disabled
                      value={pricing?.amount || ""}
                      placeholder="Enter Amount"
                      onChange={pricingHandler}
                      maxlength="6"
                      error={error?.amount}
                    />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>

                <div className="form_field">
                  <label className="label">Show Slash Pricing</label>
                  <div className="radio_button_wrap">
                    <label
                      htmlFor="yes"
                      className={`radio_button input ${
                        pricing?.showSlashedAmount === "yes" ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        disabled
                        name="showSlashedAmount"
                        id="yes"
                        value="yes"
                        checked={pricing?.showSlashedAmount === "yes"}
                        onChange={pricingHandler}
                      />
                      <span>Yes</span>
                    </label>
                    <label
                      htmlFor="no"
                      className={`radio_button input ${
                        pricing?.showSlashedAmount == "no" ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        disabled
                        name="showSlashedAmount"
                        id="no"
                        value="no"
                        checked={pricing?.showSlashedAmount === "no"}
                        onChange={pricingHandler}
                      />
                      <span>No</span>
                    </label>
                  </div>
                </div>

                <div className="form_field">
                  <label className="label">Actual amount</label>
                  <div className="input_wrap">
                    <Input
                      type={"text"}
                      disabled
                      name="amount"
                      value={pricing?.amount || 0}
                      placeholder="Enter Amount"
                      onChange={pricingHandler}
                      maxlength="6"
                    />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Slashed amount</label>
                  <div className="input_wrap">
                    <Input
                      type={"text"}
                      disabled
                      placeholder="Enter Amount"
                      // disabled={pricing?.showSlashedAmount == "no"}
                      name="slashAmount"
                      value={pricing?.slashAmount || ""}
                      onChange={pricingHandler}
                      maxlength="6"
                      error={error?.slashAmount}
                      extraError={error?.slashAmountErr}
                    />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Display Price</label>
                  <div className="display_price input">
                    {!Number(pricing?.slashAmount) ? (
                      <span>
                        {Number(pricing?.amount)
                          ? Number(pricing?.amount).toFixed(2)
                          : 0}
                      </span>
                    ) : (
                      <>
                        {" "}
                        <del>
                          <BsCurrencyRupee />{" "}
                          {Number(pricing?.slashAmount)
                            ? Number(pricing?.slashAmount)
                            : 0}
                        </del>
                        <span>
                          {Number(pricing?.amount)
                            ? // Number(pricing?.amount) -
                              Number(pricing?.amount || 0).toFixed(2)
                            : 0}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="pricing">
              <h5> Scheduled Time </h5>
              <div className="pricing_content">
                <div className="form_field">
                  <Select
                    label={"Duration (mins)"}
                    name="duration"
                    disabled
                    value={inputField?.duration || ""}
                    onChange={inputFieldHandler}
                    error={error?.duration}
                  >
                    <option value="">Select Duration</option>
                    {duration &&
                      duration.length &&
                      duration.map((data, index) => (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      ))}
                  </Select>
                </div>

                <div className="form_field">
                  <Select
                    label={"Subscription Cycle"}
                    name="subscriptionCycle"
                    disabled
                    value={
                      // inputField?.recurringServiceType === "public"
                      //   ?
                      inputField?.subscriptionAvailability?.subscriptionCycle
                      // : inputField?.privateSubscriptionAvailability
                      //     ?.subscriptionCycle
                    }
                    onChange={inputFieldHandler}
                    error={error?.subscriptionCycle}
                  >
                    <option value="">Select Cycle</option>

                    <option key={"monthly"} value={"monthly"}>
                      Monthly
                    </option>
                    <option key={"quarterly"} value={"quarterly"}>
                      Quaterly
                    </option>
                    <option key={"halfYearly"} value={"halfYearly"}>
                      Half Yearly
                    </option>
                    <option key={"yearly"} value={"yearly"}>
                      Yearly
                    </option>
                  </Select>
                </div>

                <div className="form_field">
                  <div className="input_wrap">
                    <label className="label">Select Time Slots</label>
                    <ReactDatePicker
                      showTimeSelect
                      disabled={true}
                      placeholderText="Select Start Time"
                      dateFormat="h:mm aa"
                      minTime={
                        subscriptionAvailability &&
                        subscriptionAvailability.length &&
                        subscriptionAvailability[
                          subscriptionAvailability.length - 1
                        ].endTime
                      }
                      maxTime={
                        subscriptionAvailability &&
                        subscriptionAvailability.length &&
                        moment("23:59", "HH:mm").toDate()
                      }
                      timeFormat="h:mm aa"
                      timeCaption="Time"
                      timeIntervals={5}
                      showTimeSelectOnly
                      selected={inputField?.time}
                      onChange={(time) =>
                        setInputField({ ...inputField, ["time"]: time })
                      }
                      className="form-control input w-100 cursor-pointer"
                    />
                    {error?.time && (
                      <span
                        className={`${
                          error?.time ? "invalid-feedback d-block" : ""
                        }`}
                      >
                        {error?.time}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form_field">
                  <div className="button_wrap button_left">
                    <Button isDisabled={true} onClick={addTimeHandler}>
                      {" "}
                      Add Time{" "}
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="pricing">
              <div className="pricing_content">
                {/* Needs Styling */}

                {/* <div className="form_field days_selection">
                {weekDays &&
                  weekDays.length &&
                  weekDays.map((data) => (
                    <div className="days_list">
                        <input
                        type="checkbox"
                        id={data.value}
                        label={data.name}
                        name={data?.name}
                        value={data?.value}
                        placeholder={"Enter url"}
                        checked={
                          !!selectedWeekDay.find(
                            (day) => day.value === data.value
                          )
                        }
                        onChange={() => selectedWeekDayHandler(data)}
                      />
                      <label htmlFor={data.value}>{data.name}</label>
                      
                    </div>
                  ))}
                {error?.selectedWeekDay && (
                  <span
                    className={`${
                      error?.selectedWeekDay ? "invalid-feedback d-block" : ""
                    }`}
                  >
                    {error?.selectedWeekDay}
                  </span>
                )}
                </div> */}

                {/*  */}

                {weekDays &&
                  weekDays.length &&
                  weekDays.map((data) => (
                    <div className="cstm_checkbox mx-1">
                      <label className="label">{data.name}</label>
                      <input
                        type="checkbox"
                        disabled
                        className="form-control input"
                        label={data.name}
                        name={data?.name}
                        value={data?.value}
                        placeholder={"Enter url"}
                        checked={
                          selectedWeekDay &&
                          !!selectedWeekDay.find(
                            (day) => day.value === data.value
                          )
                        }
                        // error={error?.selectedWeekDay}
                        onChange={() => selectedWeekDayHandler(data)}
                      />
                    </div>
                  ))}
                  {error?.selectedWeekDay && (
                    <span className={`${"invalid-feedback"}`}>
                      {error?.selectedWeekDay}
                    </span>
                  )}
              </div>
            </div>

            {/* Show Schedule Time */}
            <div className="pricing">
              <div className="pricing_content">
                {subscriptionAvailability && subscriptionAvailability.length
                  ? subscriptionAvailability.map((data, index) => {
                      return (
                        <div
                          className="d-flex justify-content-center"
                          style={{
                            backgroundColor: "rgb(244, 250, 249)",
                            color: "#259D8F",marginRight: "10px",padding:"5px 10px"
                          }}
                        >
                          <p className="my-0 mx-2">
                            {timeFormat(data?.startTime) || "---"} -{" "}
                            {timeFormat(data?.endTime) || "---"}
                          </p>
                          <p className="m-0">
                            <RxCross2
                              onClick={() => removeTimeSlotHandler(index)}
                            />
                          </p>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>

            {/* <div className="table_radius spacing">
              <div class="_sub_title">
                <h4>
                  Display Testimonials for this service
                  <small>
                    Select the best testimonials that you want to display for
                    this service
                  </small>
                </h4>
                <Button
                  className={"dashboard_button"}
                  onClick={() => setModal(true)}
                
                  Select Testimonials>
                </Button>
                <Button
                  className={"dashboard_button"}
                  onClick={() => setShowTestimonilModal(true)}
                >
                  Select Testimonials
                </Button>
              </div>
              <div className="content">
                <div className="card_wrap">
                  {testimonial &&
                    testimonial.map((item) => {
                      return (
                        <TestimonialCard
                          key={item.id}
                          title={item.title}
                          date={item.date}
                          description={item.description}
                          deleteButton={true}
                        />
                      );
                    })}
                  {bestTestimonials && bestTestimonials.length ? (
                    <TestimonialCard
                      bestTestimonials={bestTestimonials}
                      setBestTestimonials={setBestTestimonials}
                    />
                  ) : (
                    <Placeholder
                      text={"Please select testimonials to display"}
                    />
                  )}
                </div>
              </div>
            </div> */}
            <div className="button_wrap button_right">
              <LoadingButton loading={loading} onClick={updateServiceHandler}>
                Save
              </LoadingButton>
            </div>
          </div>
          {/* 
          <CustomModal
            className={"xl select_testimonials"}
            show={modal}
            handleClose={() => setModal(false)}
          >
            <h3>Display Testimonials for this service</h3>
            <p>
              Select the best testimonials that you want to display for this
              service
            </p>

            <div className="card_wrap">
              {testimonial &&
                testimonial.map((item) => {
                  return (
                    <>
                      <div className="inner_testimonial_card" key={item.id}>
                        <label className="item" htmlFor={item.id}>
                          <input
                            type="checkbox"
                            className="check"
                            id={item.id}
                          />
                          <div className="head">
                            <h5>{item.title}</h5>
                            <p>{item.date}</p>
                          </div>
                          <p>{item.description}</p>
                        </label>
                      </div>
                    </>
                  );
                })}
            </div>
            <div className="button_wrap button_right mt-3">
              <LoadingButton>Select Testimonials</LoadingButton>
            </div>
          </CustomModal> */}

          {viewInfoModal && (
            <HowTo
              url={inputField?.howTo}
              viewInfoModal={viewInfoModal}
              setViewInfoModal={setViewInfoModal}
            />
          )}

          <GetTestimonials
            testimonial={testimonials}
            setTestimonial={setTestimonials}
            showTestimonialModal={showTestimonialModal}
            setShowTestimonilModal={setShowTestimonilModal}
            bestTestimonials={bestTestimonials}
            setBestTestimonials={setBestTestimonials}
            total={total}
            activePage={active}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default AddRecurringSubscription;
