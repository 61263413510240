import React, { useEffect, useState } from "react";
import "./Services.scss";
import { banner1, expert1Img, expert2Img, service1 } from "assets/images";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import { testimonial } from "staticData";
import { Button, LoadingButton } from "components/form";
import ConfirmPayModal from "components/modals/ConfirmPayModal";
import CustomModal from "components/modals/CustomModal";
import { userIcon } from "assets/icons";
import {
  bookOneToOneApi,
  getExpertServiceDetailApi,
} from "services/userServices/servicesDetail&Booking";
import { toastMessage } from "utils/toastMessage";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { useNavigate, useParams } from "react-router-dom";
import {
  addDateDuration,
  addDurationToTime,
  dateFormat,
  dateFormatWithTime,
  timeFormat,
  timeFormat_24,
} from "utils/dateFormat";
import VideoPlayer from "common/YouTubeVideoPlayer";
import { useSelector } from "react-redux";
import SuccessPayment from "components/SuccessfulPaymentModal/SuccessPayment";

const Webinars = () => {
  const [payoutModal, setPayoutModal] = useState(false);
  const { personalInfo } = useSelector((state) => state.config);
  const [loginModal, setLoginModal] = useState(false);
  const [authModal, setAuthModal] = useState(true);
  const { expertId, serviceId } = useParams();
  const [bestTestimonials, setBestTestimonials] = useState([]);
  const [paymentStatusModal, setPaymentStatusModal] = useState(false);
  const [expertServicesDetail, setExpertServicesDetail] = useState({});
  const [loader, onShow, onHide] = FullPageLoader();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchingExpertServicesDetail = async () => {
    try {
      onShow();
      const { data } = await getExpertServiceDetailApi(expertId, serviceId);
      if (data?.code === 200) {
        setExpertServicesDetail(data?.data);
      } else {
        toastMessage(data?.message, "error", "ServiceDetail");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  // BookWebinar
  // const BookWebinarHandler = async () => {
  //   if (personalInfo?.token) {
  //     try {
  //       setLoading(true);
  //       const { data } = await bookOneToOneApi({
  //         _id: serviceId,
  //         bookedFor: expertServicesDetail?.webinarAvailability,
  //         amount: expertServicesDetail?.pricing?.amount,
  //         totalAmount: expertServicesDetail?.pricing?.totalAmount,
  //         transactionFeePercentage:
  //           expertServicesDetail?.pricing?.transactionFeePercentage,
  //         slot: {
  //           startTime: timeFormat_24(expertServicesDetail?.webinarAvailability),
  //           endTime: timeFormat_24(
  //             addDurationToTime(
  //               expertServicesDetail?.webinarAvailability,
  //               expertServicesDetail?.duration
  //             )
  //           ),
  //           duration: expertServicesDetail?.duration,
  //         },
  //       });
  //       if (data?.code === 200) {
  //         toastMessage(data?.message, "success", "bookWebinarHandler");
  //         navigate(`/expert-details/${expertId}?serviceType=webinar`);
  //         setPayoutModal(false);
  //         setPaymentStatusModal(true);
  //         fetchingExpertServicesDetail();
  //       } else {
  //         toastMessage(data?.message, "error", "bookWebinarHandler");
  //       }
  //     } catch (e) {
  //       console.log(e.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   } else {
  //     setLoginModal(true);
  //     setPayoutModal(false);
  //   }
  // };

  useEffect(() => {
    fetchingExpertServicesDetail();
  }, []);
  return (
    <>
      {loader}
      <div className="services_page webinars">
        <div className="container">
          <img src={banner1} alt="" className="services_banner" />

          <div className="service_content_wrap">
            <div className="service_banner">
              <div className="banner_content">
                <img src={expertServicesDetail?.image || service1} alt="" />
                <div className="content">
                  {/* <h4>Pulled Hamstring causes, Treatment and recovery </h4> */}
                  <h4>{expertServicesDetail?.title || "---"}</h4>

                  <p className="duration">
                    <span>Duration</span>{" "}
                    <b> ({expertServicesDetail?.duration || "---"}) mins</b>{" "}
                    <span> Start Date & Time</span>{" "}
                    <b>
                      (
                      {dateFormatWithTime(
                        expertServicesDetail?.webinarAvailability
                      )}
                      )
                    </b>
                  </p>
                  <p className="price">
                    {expertServicesDetail?.pricing?.showSlashedAmount ? (
                      <>
                        <del>
                          ₹ {Number(expertServicesDetail.pricing.slashAmount)}
                        </del>
                        <b>
                          ₹{" "}
                          {
                            Number(expertServicesDetail.pricing.amount)
                            // -
                            //   Number(expertServicesDetail.pricing.slashAmount)
                          }
                        </b>
                      </>
                    ) : (
                      <>₹ {Number(expertServicesDetail?.pricing?.amount)}</>
                    )}
                  </p>
                </div>
              </div>

              {/* This Button Show Only To User */}

              {(personalInfo?.currentType === 1 || !personalInfo?.token) && (
                <div className="banner_tag">
                  <span className="name_tag">Webinar</span>
                  {expertServicesDetail?.alreadyBooked ? (
                    <p>You Already Booked This Webinar</p>
                  ) : (
                    <Button
                      className={"dashboard_button"}
                      onClick={() => setPayoutModal(true)}
                    >
                      Book Now
                    </Button>
                  )}
                </div>
              )}
            </div>
            <div className="service_content">
              <div className="content_left">
                <h5 className="_title">Overview</h5>
                <div className="content">
                  {/* {expertServicesDetail?.description || "---"} */}
                  {expertServicesDetail?.description ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: expertServicesDetail.description,
                      }}
                    ></p>
                  ) : (
                    <p>---</p>
                  )}
                </div>
              </div>
              <div className="content_right">
                <h5 className="_title">
                  {expertServicesDetail?.serviceBookingsCount || "---"} People
                  join this webinars
                </h5>
                <div className="content">
                  <ul className="people_listing">
                    {expertServicesDetail?.serviceBookings &&
                      expertServicesDetail?.serviceBookings.length &&
                      expertServicesDetail?.serviceBookings.map((data) => (
                        <li>
                          <img src={data?.user?.image || expert1Img} alt="" />
                          <p>
                            <b>{`${data?.user?.firstName || "---"} ${" "}${
                              data?.user?.lastName
                            }`}</b>
                            {/* <span>Booking ID : #123DBS4282</span> */}
                          </p>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <VideoPlayer src={expertServicesDetail?.video || "---"} />
            {bestTestimonials.length > 0 && (
              <div className="content_box">
                <h5 className="_title">Testimonials</h5>

                <div className="content">
                  <div className="card_wrap">
                    {bestTestimonials &&
                      bestTestimonials.map((item) => {
                        return (
                          <TestimonialCard
                            bestTestimonials={bestTestimonials}
                            setBestTestimonials={setBestTestimonials}
                            display={false}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <ConfirmPayModal
        show={payoutModal}
        handleClose={() => setPayoutModal(false)}
        modalTitle={"Confirm and pay"}
        serviceTag={"Webinar"}
        serviceTittle={expertServicesDetail?.title || "---"}
        duration={`${expertServicesDetail?.duration || "---"}(mins)`}
        price={expertServicesDetail?.pricing?.amount || "---"}
        date={dateFormat(expertServicesDetail?.webinarAvailability) || "---"}
        time={timeFormat(expertServicesDetail?.webinarAvailability) || "---"}
        buttonText={"Process to Pay"}
        // onClick={BookOneToOneHandler}
        loginModal={loginModal}
        setLoginModal={setLoginModal}
        authModal={authModal}
        setAuthModal={setAuthModal}
        loading={loading}
        serviceId={serviceId}
        expertId={expertId}
        expertServicesDetail={expertServicesDetail}
        token={personalInfo?.token}
        setPayoutModal={setPayoutModal}
        payoutModal={payoutModal}
        setPaymentStatusModal={setPaymentStatusModal}
        slotTime={{
          startTime: timeFormat_24(expertServicesDetail?.webinarAvailability),
          endTime: timeFormat_24(
            addDurationToTime(
              expertServicesDetail?.webinarAvailability,
              expertServicesDetail?.duration
            )
          ),
          duration: expertServicesDetail?.duration,
        }}
        bookingEndDate={addDateDuration(expertServicesDetail?.webinarAvailability,expertServicesDetail?.duration)}
        // bookingDate={bookingDate}
      />
      <SuccessPayment
        paymentStatusModal={paymentStatusModal}
        setPaymentStatusModal={setPaymentStatusModal}
        expertId={expertId}
        serviceType="webinar"
      />
    </>
  );
};

export default Webinars;
