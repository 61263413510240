import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Input,
  LoadingButton,
  Select,
  TextArea,
} from "components/form";
import { IoCloudUploadOutline } from "react-icons/io5";
import { FaMinus, FaPlus, FaTimes } from "react-icons/fa";
import { BsCurrencyRupee } from "react-icons/bs";
import { serviceCardData, testimonial } from "staticData";
import CustomModal from "components/modals/CustomModal";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import "components/cards/servicesCard/ServicesCard.scss";
import { LiaRupeeSignSolid, LiaTimesSolid } from "react-icons/lia";
import { getPackageServicesApi } from "services/packages";
import { toastMessage } from "utils/toastMessage";
import Placeholder from "components/placeholder/Placeholder";
import { LuIndianRupee } from "react-icons/lu";
import GetServices from "./GetServices";
import { addServicesApi, getHowToApi } from "services/expertServices/manage";
import { useNavigate } from "react-router-dom";
import { allowNumber, regex } from "utils/formValidator";
import JoditEditor from "jodit-react";
import commonMessages, { slashedValidation } from "utils/commonMessages";
import GetTestimonials from "../manageServices/GetTestimonials";
import { getTestimonials } from "services/master";
import HowTo from "../manageServices/HowTo";
import { sendArrayDataId } from "utils/mediaDataSender";
import { IoIosBook } from "react-icons/io";
import CoverImageCropper from "../manageServices/CoverImageCropper";

const AddPackage = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const [showTestimonialModal, setShowTestimonilModal] = useState(false);
  const [inputField, setInputField] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [serviceActivePage, setServiceActivePage] = useState(1);
  const [packageServicesList, setPackageServicesList] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [bestTestimonials, setBestTestimonials] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [total, setTotal] = useState(null);
  const [totalService, setTotalService] = useState(null);
  const [active, setActive] = useState("yes");
  const [modal, setModal] = useState(false);
  const [selectServiceModal, setSelectServiceModal] = useState(false);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const { IMAGE_FORMAT } = commonMessages;
  const { imageFileRegex, url } = regex;
  const [viewInfoModal, setViewInfoModal] = useState(false);
  

  const fetchingPackageServicesList = async () => {
    try {
      setPackageServicesList([]);
      const sendData = {
        page: serviceActivePage - 1,
        pageSize: 10,
      };
      const { data } = await getPackageServicesApi(sendData);
      if (data?.code === 200) {
        setPackageServicesList(
          data?.data.map((item) => ({ ...item, provided: 1 }))
        );
        setTotalService(data?.totalItems);
      } else {
        setPackageServicesList([]);
        toastMessage(data?.message, "error", "setPackageServicesList");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  //Fetching How To Api
  const fetchingHowTo = async () => {
    try {
      const { data } = await getHowToApi("package");
      if (data?.code === 200) {
        setInputField({
          ...inputField,
          howTo: data?.data?.url,
          howToId: data?.data?._id,
        });
      } else {
        toastMessage(data?.message, "error", "howTo");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
    }
  };

  useEffect(() => {
    fetchingHowTo();
  }, []);

  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false,
      placeholer: "",
      toolbar: true,
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      uploader: {
        insertImageAsBase64URI: true,
      },
      controls: {
        font: {
          command: "fontname",
          list: {
            "'Open Sans',sans-serif": "Open Sans",
            "Helvetica,sans-serif": "Helvetica",
            "Arial,Helvetica,sans-serif": "Arial",
            "Georgia,serif": "Georgia",
            "Impact,Charcoal,sans-serif": "Impact",
            "Tahoma,Geneva,sans-serif": "Tahoma",
            "'Times New Roman',Times,serif": "Times New Roman",
            "Verdana,Geneva,sans-serif": "Verdana",
            "Consolas,monaco,monospace": "Consolas",
          },
        },
      },
    }),
    []
  );

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    setActive(event);
  };

  //  Handle Pagination for services
  const handleServicePagination = (event) => {
    setServiceActivePage(event);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImagePreview(file);
    e.target.value = null;
  };

  const deleteImg = (e) => {
    e.target.value = null;
    setImagePreview(null);
  };

  // Increment Services Quantity Handler
  const incrementQuantityHandler = (serviceId, qty, index) => {
    const tempData = [...selectedServices];
    tempData[index].provided = qty + 1;
    setSelectedServices(tempData);
  };

  // Decrement Services Quantity Handler
  const decrementQuantityHandler = (serviceId, qty, index) => {
    const tempData = [...selectedServices];
    if (qty >= 2) {
      tempData[index].provided = qty - 1;
      setSelectedServices(tempData);
    } else {
      removeServiceHandler(serviceId);
    }
  };

  //Input Field Handler
  const inputFieldHandler = (e) => {
    const { name, value } = e.target;

    if (name === "video") {
      setInputField({ ...inputField, [name]: value });
    } else if (name === "showSlashedAmount") {
      setInputField({ ...inputField, [name]: value, slashAmount: "" });
    } else if (name === "amount" || name === "slashAmount") {
      setInputField({ ...inputField, [name]: allowNumber(value) });
    } else {
      setInputField({ ...inputField, [name]: value });
    }
  };

  // Image Handler
  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        setImage(e.target.files[0]);
      } else {
        e.target.value = "";
        setImage("");
        toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      }
    }
  };

  // Validation
  const validation = () => {
    let status = true;
    let errors = {};
    const emptyDescriptionRegex = /^<p><br><\/p>$/;
    if (
      !inputField.description ||
      emptyDescriptionRegex.test(inputField.description)
    ) {
      errors["description"] = `Description is mandatory`;
      status = false;
    }
    if (!inputField?.title) {
      errors["title"] = "Title is mandatory";
      status = false;
    }
    // if (!inputField?.video) {
    //   errors["video"] = "Video Url is mandatory";
    //   status = false;
    // }
    if (inputField?.video && !inputField?.video.match(url)) {
      errors["video"] = "Invalid Url";
      errors["videoErr"] = true;
      status = false;
    }
    // if (!image) {
    //   errors["image"] = "Cover image is mandatory";
    //   status = false;
    // }
    if (!inputField?.amount) {
      errors["amount"] = "Amount is mandatory";
      status = false;
    }
    if (!inputField?.showSlashedAmount) {
      errors["showSlashedAmount"] = "Show slash pricing?";
      status = false;
    }
    if (inputField?.showSlashedAmount === "true" && !inputField?.slashAmount) {
      errors["slashAmount"] = "Enter Slash amount";
      status = false;
    }
    if (status && !selectedServices.length) {
      toastMessage(
        "Please add services in packages",
        "error",
        "AddPackagesServices"
      );
      status = false;
    }
    let { error, slashstatus } = slashedValidation(
      inputField?.showSlashedAmount,
      inputField?.amount,
      inputField?.slashAmount
    );
    if (Object.keys(error).length > 0) {
      Object.assign(errors, error);
      status = slashstatus;
    }

    setError(errors);
    return status;
  };

  // Fetching Testimonials
  const fetchingTestimonialHandler = async () => {
    try {
      setTestimonials([]);
      const sendData = {
        serviceType: "package",
        page: activePage - 1,
        pageSize: 10,
      };
      const { data } = await getTestimonials(sendData);
      if (data?.code === 200) {
        setTestimonials(data?.data);
        setTotal(data?.totalItems);
      } else {
        setTestimonials([]);
        toastMessage(data?.message, "error", "GetTestimonials");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Add Package Service
  const addServiceHandler = async () => {
    if (validation()) {
      try {
        setLoading(true);
        const formData = new FormData();
        if (image && image?.name) {
          formData.append("image", image);
        }
        formData.append(
          "pricing",
          JSON.stringify({
            amount: inputField?.amount,
            showSlashedAmount: inputField?.showSlashedAmount === "true",
            slashAmount: inputField?.slashAmount || 0,
          })
        );

        // if (bestTestimonials && bestTestimonials.length) {
        //   const selectedTestimonials = sendArrayDataId(bestTestimonials);
        //   formData.append("testimonials", JSON.stringify(selectedTestimonials));
        // } else {
        //   formData.append("testimonials", `[]`);
        // }

        formData.append("description", inputField?.description);
        if (inputField?.video) {
          formData.append("video", inputField?.video);
        } else {
          formData.append("video", "");
        }

        formData.append("title", inputField?.title);
        formData.append("serviceType", "package");
        formData.append(
          "services",
          JSON.stringify(
            selectedServices.map((data) => ({
              serviceType: data?.serviceType,
              service: data?._id,
              provided: data?.provided,
            }))
          )
        );

        const { data } = await addServicesApi(formData);
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "addWebinar");
          setInputField("");
          navigate(`/dashboard/package`);
          setError({});
        } else {
          toastMessage(data?.message, "error", "addWebinar");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchingPackageServicesList();
  }, [serviceActivePage]);

  useEffect(() => {
    if (showTestimonialModal) {
      fetchingTestimonialHandler();
    }
  }, [showTestimonialModal, activePage]);

  const removeServiceHandler = (_id) => {
    setSelectedServices((prev) =>
      prev
        .map((service) =>
          service?._id === _id ? { ...service, provided: 1 } : service
        )
        .filter((service) => service?._id !== _id)
    );
  };

  useEffect(() => {
    if (inputField?.howTo) {
      setViewInfoModal(true);
    }
  }, [inputField?.howTo]);

  return (
    <>
      <div className="edit_digital_content">
        <div className="dashboard_title how_add">
          <h3>Create Package</h3>{" "}
          <button type="button" onClick={() => setViewInfoModal(true)}>
            <IoIosBook />
            Tutorial
          </button>
        </div>

        <div className="dashboard_content_inner">
          <div className="add_services_from">
            <form>
              <div className="content_left">
                <div className="form_field">
                  <Input
                    label={"Title"}
                    name="title"
                    value={inputField?.title || ""}
                    placeholder={"Enter Title"}
                    onChange={inputFieldHandler}
                    maxlength="100"
                    error={error?.title}
                  />
                </div>
                <div className="form_field">
                <label className="label">Description</label>
                  <JoditEditor
                    ref={editor}
                    value={inputField?.description}
                    tabIndex={1}
                    onBlur={(value) =>
                      setInputField({ ...inputField, description: value })
                    }
                    config={config}
                  />
                  <p className="invalid-feedback d-block">
                    {error?.description}
                  </p>
                </div>
              </div>
              <div className="content_right">
                {/* <div className="form_field position-relative">
                  <label className="label d-block">Upload Cover Image</label>
                  {imagePreview && (
                    <span className="delete_button" onClick={deleteImg}>
                      <FaTimes />
                    </span>
                  )}
                  <label className="imagePreview" htmlFor="fileUpload">
                    <input
                      type="file"
                      name="image"
                      id="fileUpload"
                      onChange={imageHandler}
                      error={error?.image}
                    />
                    {image ? (
                      <>
                        <img
                          src={
                            image && image?.name
                              ? URL.createObjectURL(image)
                              : image
                          }
                          alt=""
                        />
                      </>
                    ) : (
                      <div className="content">
                        <IoCloudUploadOutline />
                        <label className="button" htmlFor="fileUpload">
                          Browse
                        </label>
                        <span>drop a file here</span>
                        <p>*File supported .png, .jpg, .webp</p>
                      </div>
                    )}
                  </label>
                </div>

                {error.image && (
                  <span className="invalid-feedback d-block">
                    {error?.image}
                  </span>
                )} */}

<CoverImageCropper
                  imageSelect={(img) => {
                    setImage(img);
                  }}
                  prevImage={image}
                  error={error?.image}
                />

              </div>
            </form>
            <div className="form_field">
              <Input
                label={"Overview Video Link"}
                name="video"
                value={inputField?.video || ""}
                placeholder={"Enter url"}
                onChange={inputFieldHandler}
                error={error?.video}
                extraError={error?.videoErr}
              />
            </div>
            <div className="pricing">
              <h5>Pricing</h5>
              <div className="pricing_content">
                <div className="form_field">
                  <label className="label">Enter Amount</label>
                  <div className="input_wrap">
                    <Input
                      type={"text"}
                      name="amount"
                      value={inputField?.amount || ""}
                      placeholder="Enter Amount"
                      onChange={inputFieldHandler}
                      maxlength="6"
                      error={error?.amount}
                    />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Show Slash Pricing</label>
                  <div className="radio_button_wrap">
                    <label
                      htmlFor="true"
                      className={`radio_button input ${
                        inputField?.showSlashedAmount === "true" ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name="showSlashedAmount"
                        id="true"
                        value="true"
                        checked={inputField?.showSlashedAmount === "true"}
                        onChange={inputFieldHandler}
                      />
                      <span>Yes</span>
                    </label>

                    <label
                      htmlFor="false"
                      className={`radio_button input ${
                        inputField?.showSlashedAmount === "false"
                          ? "active"
                          : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name="showSlashedAmount"
                        id="false"
                        value="false"
                        checked={inputField?.showSlashedAmount === "false"}
                        onChange={inputFieldHandler}
                      />
                      <span>No</span>
                    </label>
                  </div>
                  {error && (
                    <span
                      className={`${
                        error?.showSlashedAmount
                          ? "invalid-feedback d-block"
                          : ""
                      }`}
                    >
                      {error?.showSlashedAmount}
                    </span>
                  )}
                </div>
                <div className="form_field">
                  <label className="label">Actual amount</label>
                  <div className="input_wrap">
                    <Input
                      type={"text"}
                      disabled
                      name="amount"
                      value={inputField?.amount || 0}
                      placeholder="Enter Amount"
                      onChange={inputFieldHandler}
                      maxlength="6"
                    />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Slashed amount</label>
                  <div className="input_wrap">
                    <Input
                      type={"text"}
                      placeholder="Enter Amount"
                      disabled={inputField?.showSlashedAmount == "false"}
                      name="slashAmount"
                      value={inputField?.slashAmount || ""}
                      onChange={inputFieldHandler}
                      maxlength="6"
                      error={error?.slashAmount}
                      extraError={error?.slashAmountErr}
                    />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Display Price</label>
                  <div className="display_price input">
                    {!inputField?.slashAmount ? (
                      <span>
                        {inputField?.amount
                          ? Number(inputField?.amount).toFixed(2)
                          : 0}
                      </span>
                    ) : (
                      <>
                        {" "}
                        <del>
                          <BsCurrencyRupee />{" "}
                          {inputField?.slashAmount
                            ? Number(inputField?.slashAmount)
                            : 0}
                        </del>
                        <span>
                          {inputField?.amount
                            ? Number(inputField?.amount || 0).toFixed(2)
                            : 0}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="chapter_list spacing">
              <ul>
                <li>
                  <p>
                    <b>Add Services</b>
                    <span>
                      Select the best testimonials that you want to display for
                      this service
                    </span>
                  </p>

                  <Button
                    className={"dashboard_button"}
                    onClick={() => {
                      setSelectServiceModal(true);
                    }}
                  >
                    Select Services
                  </Button>
                </li>
              </ul>
            </div>

            <div className="chapter_list spacing">
              <h5>Add Services</h5>
              <ul>
                {!selectServiceModal &&
                selectedServices &&
                selectedServices.length > 0 ? (
                  selectedServices.map((data, index) => {
                    return (
                      <li>
                        <p>
                          <b>
                            {/* Chapters {index + 1} :  */}
                            {data?.title || "---"}
                          </b>
                          <p className="m-0 d-flex">
                            {data?.pricing?.showSlashedAmount && (
                              <del>
                                <LuIndianRupee /> {data?.pricing?.slashAmount}
                              </del>
                            )}

                            <b className="ms-2">
                              ₹{" "}
                              {
                                // data?.pricing?.showSlashedAmount
                                //   ? Number(
                                //       Number(data?.pricing?.amount) -
                                //         Number(data?.pricing?.slashAmount)
                                //     )
                                //   :
                                Number(data?.pricing?.amount)
                              }
                            </b>
                          </p>
                        </p>
                        <div className="action">
                          <div className="quantity_button">
                            <span
                              className="decrement"
                              onClick={() =>
                                decrementQuantityHandler(
                                  data?._id,
                                  data?.provided,
                                  index
                                )
                              }
                            >
                              <FaMinus />
                            </span>
                            <input
                              type="number"
                              value={data?.provided || "---"}
                            />
                            <span
                              className="increment"
                              onClick={() =>
                                incrementQuantityHandler(
                                  data?._id,
                                  data?.provided,
                                  index
                                )
                              }
                            >
                              <FaPlus />
                            </span>
                          </div>

                          <button
                            type="button"
                            onClick={() =>
                              // setSelectedServices((prev) =>
                              //   prev
                              //     .map((service) =>
                              //       service?._id === data?._id
                              //         ? { ...service, provided: 1 }
                              //         : service
                              //     )
                              //     .filter(
                              //       (service) => service?._id !== data?._id
                              //     )
                              // )
                              removeServiceHandler(data?._id)
                            }
                          >
                            <LiaTimesSolid />
                          </button>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <Placeholder text={"No services is selected "} />
                )}
              </ul>
            </div>

            {/* <div className="table_radius spacing">
              <div class="_sub_title">
                <h4>
                  Display Testimonials for this service
                  <small>
                    Select the best testimonials that you want to display for
                    this service
                  </small>
                </h4>
                <Button
                  className={"dashboard_button"}
               
                  onClick={() => setShowTestimonilModal(true)}
                >
                  Select Testimonials
                </Button>
              </div>
              <div className="content">
                <div className="card_wrap">
                  {testimonial &&
                    testimonial.map((item) => {
                      return (
                        <TestimonialCard
                          key={item.id}
                          title={item.title}
                          date={item.date}
                          description={item.description}
                          deleteButton={true}
                        />
                      );
                    })}

                  {bestTestimonials && bestTestimonials.length ? (
                    <TestimonialCard
                      bestTestimonials={bestTestimonials}
                      setBestTestimonials={setBestTestimonials}
                    />
                  ) : (
                    "No Testimonials Found"
                  )}
                </div>
              </div>
            </div> */}
            <div className="button_wrap button_right">
              <LoadingButton loading={loading} onClick={addServiceHandler}>
                Save
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>

      {viewInfoModal && (
        <HowTo
          url={inputField?.howTo}
          viewInfoModal={viewInfoModal}
          setViewInfoModal={setViewInfoModal}
        />
      )}

      {/* Get Services */}
      <GetServices
        packageServicesList={packageServicesList}
        setPackageServicesList={setPackageServicesList}
        selectServiceModal={selectServiceModal}
        setSelectServiceModal={setSelectServiceModal}
        selectedServices={selectedServices}
        setSelectedServices={setSelectedServices}
        total={totalService}
        activePage={serviceActivePage}
        handlePageChange={handleServicePagination}
      />

      {/* Get Testimonials */}
      <GetTestimonials
        testimonial={testimonials}
        setTestimonial={setTestimonials}
        showTestimonialModal={showTestimonialModal}
        setShowTestimonilModal={setShowTestimonilModal}
        bestTestimonials={bestTestimonials}
        setBestTestimonials={setBestTestimonials}
        total={total}
        activePage={active}
        handlePageChange={handlePageChange}
      />

      {/* <CustomModal
        className={"xl select_testimonials"}
        show={modal}
        handleClose={() => setModal(false)}
      >
        <h3>Display Testimonials for this service</h3>
        <p>
          Select the best testimonials that you want to display for this service
        </p>

        <div className="card_wrap">
          {testimonial &&
            testimonial.map((item) => {
              return (
                <>
                  <div className="inner_testimonial_card" key={item.id}>
                    <label className="item" htmlFor={item.id}>
                      <input type="checkbox" className="check" id={item.id} />
                      <div className="head">
                        <h5>{item.title}</h5>
                        <p>{item.date}</p>
                      </div>
                      <p>{item.description}</p>
                    </label>
                  </div>
                </>
              );
            })}
        </div>
        <div className="button_wrap button_right mt-3">
          <LoadingButton onClick={addServiceHandler}>
            Select Testimonials
          </LoadingButton>
        </div>
      </CustomModal> */}

      {/* Select Services */}
      {/* <CustomModal
        className={"xl select_testimonials"}
        show={selectServiceModal}
        handleClose={() => setSelectServiceModal(false)}
      >
        <h3>Display Testimonials for this service</h3>
        <p>
          Select the best testimonials that you want to display for this service
        </p>

        <div className="card_wrap">
          {packageServicesList && packageServicesList.length > 0 ? (
            packageServicesList.map((item) => {
              return (
                <>
                  <div className="inner_testimonial_card service" key={item.id}>
                    <label className="item" htmlFor={item.id}>
                      <input type="checkbox" className="check" id={item.id} />
                      <h5>{item.title}</h5>
                      <p className="price">
                        {item?.pricing?.showSlashedAmount && (
                          <del>
                            <LuIndianRupee /> {item?.pricing?.amount}
                          </del>
                        )}

                        <span>
                          <LuIndianRupee />
                          {item?.pricing?.showSlashedAmount
                            ? Number(
                                Number(item?.pricing?.amount) -
                                  Number(item?.pricing?.slashAmount)
                              )
                            : Number(item?.pricing?.amount)}
                        </span>
                      </p>
                      <div className="bottom_text">
                        <span>(1 to 1 Consultation)</span>
                      </div>
                    </label>
                  </div>
                </>
              );
            })
          ) : (
            <Placeholder text={"No services found to select"} />
          )}
        </div>
        <div className="button_wrap button_right mt-3">
          <LoadingButton>Select Services</LoadingButton>
        </div>
      </CustomModal> */}
    </>
  );
};

export default AddPackage;
