import AddService from "components/cards/servicesCard/AddService";
import "../ManageServices.scss";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Input,
  LoadingButton,
  Select,
  TextArea,
} from "components/form";
import { IoCloudUploadOutline } from "react-icons/io5";
import "../../../../components/cards/servicesCard/ServicesCard.scss";
import { FaTimes } from "react-icons/fa";
import { BsCurrencyRupee } from "react-icons/bs";
import { testimonial } from "staticData";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import CustomModal from "components/modals/CustomModal";
import { getTestimonials } from "services/master";
import commonMessages, { slashedValidation } from "utils/commonMessages";
import { allowNumber, regex } from "utils/formValidator";
import { toastMessage } from "utils/toastMessage";
import { useNavigate, useParams } from "react-router-dom";
import { addOneToOneApi } from "services/expertServices/manage/consultations";
import { addServicesApi, getHowToApi } from "services/expertServices/manage";
import GetTestimonials from "../GetTestimonials";
import { sendArrayDataId } from "utils/mediaDataSender";
import JoditEditor from "jodit-react";
import Placeholder from "components/placeholder/Placeholder";
import HowTo from "../HowTo";
import { IoIosBook } from "react-icons/io";
import CoverImageCropper from "../CoverImageCropper";

const AddConsultations = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const { serviceType } = useParams();
  const { IMAGE_FORMAT } = commonMessages;
  const [image, setImage] = useState("");
  const { imageFileRegex, url } = regex;
  const [inputField, setInputField] = useState({ serviceType: serviceType });
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [testimonials, setTestimonials] = useState([]);
  const [bestTestimonials, setBestTestimonials] = useState([]);
  const [showTestimonialModal, setShowTestimonilModal] = useState(false);
  const navigate = useNavigate();
  const [viewInfoModal, setViewInfoModal] = useState(false);

  const duration = ["15", "30", "45", "60", "75", "90", "105", "120"];

  //Fetching How To Api
  const fetchingHowTo = async () => {
    try {
      const { data } = await getHowToApi(serviceType);
      if (data?.code === 200) {
        setInputField({
          ...inputField,
          howTo: data?.data?.url,
          howToId: data?.data?._id,
        });
      } else {
        toastMessage(data?.message, "error", "howTo");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
    }
  };

  useEffect(() => {
    fetchingHowTo();
  }, []);

  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        setImage(e.target.files[0]);
      } else {
        e.target.value = "";
        setImage("");
        toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      }
    }
  };

  const deleteImg = (e) => {
    e.target.value = null;
    setImagePreview(null);
  };

  // For Text Editor
  const editor = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false,
      placeholer: "",
      toolbar: true,
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      uploader: {
        insertImageAsBase64URI: true,
      },
      controls: {
        font: {
          command: "fontname",
          list: {
            "'Open Sans',sans-serif": "Open Sans",
            "Helvetica,sans-serif": "Helvetica",
            "Arial,Helvetica,sans-serif": "Arial",
            "Georgia,serif": "Georgia",
            "Impact,Charcoal,sans-serif": "Impact",
            "Tahoma,Geneva,sans-serif": "Tahoma",
            "'Times New Roman',Times,serif": "Times New Roman",
            "Verdana,Geneva,sans-serif": "Verdana",
            "Consolas,monaco,monospace": "Consolas",
          },
        },
      },
    }),
    []
  );

  const fetchingTestimonialHandler = async () => {
    try {
      setTestimonials([]);
      const sendData = {
        serviceType: serviceType,
        page: 0,
        pageSize: 10,
      };
      const { data } = await getTestimonials(sendData);
      if (data?.code === 200) {
        setTestimonials(data?.data);
      } else {
        setTestimonials([]);
        toastMessage(data?.message, "error", "GetTestimonials");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const inputFieldHandler = (e) => {
    const { name, value } = e.target;

    if (name === "video") {
      setInputField({ ...inputField, [name]: value });
    } else if (name === "showSlashedAmount") {
      setInputField({ ...inputField, [name]: value, slashAmount: "" });
    } else if (name === "amount" || name === "slashAmount") {
      setInputField({ ...inputField, [name]: allowNumber(value) });
    } else {
      setInputField({ ...inputField, [name]: value });
    }
  };

  // console.log(
  //   inputField?.showSlashedAmount,
  //   inputField?.slashAmount,
  //   inputField?.amount,
  //   "inner"
  // );

  const validation = () => {
    let status = true;
    let errors = {};
    const emptyDescriptionRegex = /^<p><br><\/p>$/;
    if (
      !inputField.description ||
      emptyDescriptionRegex.test(inputField.description)
    ) {
      errors["description"] = `Description is mandatory`;
      status = false;
    }
    if (!inputField?.title) {
      errors["title"] = "Title is mandatory";
      status = false;
    }
    if (!inputField?.duration) {
      errors["duration"] = "Select duration";
      status = false;
    }
    // if (!inputField?.video) {
    //   errors["video"] = "Video Url is mandatory";
    //   status = false;
    // }
    if (inputField?.video && !inputField?.video.match(url)) {
      errors["video"] = "Invalid Url";
      errors["videoErr"] = true;
      status = false;
    }
    // if (!image) {
    //   errors["image"] = "Cover image is mandatory";
    //   status = false;
    // }
    if (!inputField?.amount) {
      errors["amount"] = "Amount is mandatory";
      status = false;
    }
    if (!inputField?.showSlashedAmount) {
      errors["showSlashedAmount"] = "Show slash pricing?";
      status = false;
    }
    if (inputField?.showSlashedAmount === "true" && !inputField?.slashAmount) {
      errors["slashAmount"] = "Enter Slash amount";
      status = false;
    }

    let { error, slashstatus } = slashedValidation(
      inputField?.showSlashedAmount,
      inputField?.amount,
      inputField?.slashAmount
    );
    if (Object.keys(error).length > 0) {
      Object.assign(errors, error);
      status = slashstatus;
      console.log(slashstatus, "SlashStatus");
    }
    setError(errors);
    return status;
  };

  const addServiceHandler = async () => {
    console.log(validation(), "status validation");
    if (validation()) {
      try {
        setLoading(true);
        const formData = new FormData();
        if (image && image?.name) {
          formData.append("image", image);
        }
        formData.append(
          "pricing",
          JSON.stringify({
            amount: inputField?.amount,
            showSlashedAmount: inputField?.showSlashedAmount === "true",
            slashAmount: inputField?.slashAmount || 0,
          })
        );

        formData.append("description", inputField?.description);
        if (inputField?.video) {
          formData.append("video", inputField?.video);
        }
        formData.append("duration", inputField?.duration);
        formData.append("title", inputField?.title);
        formData.append("serviceType", serviceType);
        if (bestTestimonials && bestTestimonials.length) {
          const selectedTestimonials = sendArrayDataId(bestTestimonials);
          formData.append("testimonials", JSON.stringify(selectedTestimonials));
        } else {
          formData.append("testimonials", `[]`);
        }

        const { data } = await addServicesApi(formData);
        // console.log(data, "data");
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "addOneToOne");
          setInputField("");
          navigate(`/dashboard/manage-services?serviceType=${serviceType}`);
          setError({});
        } else {
          toastMessage(data?.message, "error", "addOneToOne");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (showTestimonialModal) {
      fetchingTestimonialHandler();
    }
  }, [showTestimonialModal]);

  useEffect(() => {
    if (inputField?.howTo) {
      setViewInfoModal(true);
    }
  }, [inputField?.howTo]);

  return (
    <>
      <div className="edit_service">
        <div className="dashboard_title how_add">
          <h3>Add Consultations</h3>
          <button type="button" onClick={() => setViewInfoModal(true)}>
            <IoIosBook /> Tutorial
          </button>
        </div>

        <div className="dashboard_content_inner">
          <div className="add_services_from">
            <form>
              <div className="content_left">
                <div className="form_field">
                  <Input
                    label={"Title"}
                    name="title"
                    value={inputField?.title || ""}
                    placeholder={"Enter Title"}
                    onChange={inputFieldHandler}
                    maxlength="100"
                    error={error?.title}
                  />
                </div>
                <div className="form_field">
                  <label className="label">Description</label>
                  <JoditEditor
                    ref={editor}
                    value={inputField?.description}
                    tabIndex={1}
                    onBlur={(value) =>
                      setInputField({ ...inputField, description: value })
                    }
                    config={config}
                  />
                  <p className="invalid-feedback d-block">
                    {error?.description}
                  </p>
                </div>
                <div className="form_field">
                  <Input
                    label={"Overview Video Link"}
                    name="video"
                    value={inputField?.video || ""}
                    placeholder={"Enter url"}
                    onChange={inputFieldHandler}
                    error={error?.video}
                    extraError={error?.videoErr}
                  />
                </div>
              </div>
              <div className="content_right">
                <div className="form_field">
                  <Select
                    label={"Duration (mins)"}
                    name="duration"
                    value={inputField?.duration || ""}
                    onChange={inputFieldHandler}
                    error={error?.duration}
                  >
                    <option value="">Select Duration</option>
                    {duration &&
                      duration.length &&
                      duration.map((data, index) => (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      ))}
                  </Select>
                </div>

                {/* <div className="form_field position-relative">
                  <label className="label d-block">Upload Cover Image</label>
                  {imagePreview && (
                    <span className="delete_button" onClick={deleteImg}>
                      <FaTimes />
                    </span>
                  )}
                  <label className="imagePreview" htmlFor="fileUpload">
                    <input
                      type="file"
                      name="image"
                      id="fileUpload"
                      onChange={imageHandler}
                      error={error?.image}
                    />
                    {image ? (
                      <>
                        <img
                          src={
                            image && image?.name
                              ? URL.createObjectURL(image)
                              : image
                          }
                          alt=""
                        />
                      </>
                    ) : (
                      <div className="content">
                        <IoCloudUploadOutline />
                        <label className="button" htmlFor="fileUpload">
                          Browse
                        </label>
                        <span>drop a file here</span>
                        <p>*File supported .png, .jpg, .webp</p>
                      </div>
                    )}
                  </label>
                </div> */}

                {/* {error.image && (
                  <span className="invalid-feedback d-block">
                    {error?.image}
                  </span>
                )} */}

                <CoverImageCropper
                  imageSelect={(img) => {
                    setImage(img);
                  }}
                  prevImage={image}
                  error={error?.image}
                />
              </div>
            </form>
            <div className="pricing">
              <div className="pricing_content">
                <div className="form_field">
                  <label className="label">Enter Amount</label>
                  <div className="input_wrap">
                    <Input
                      type={"text"}
                      name="amount"
                      value={inputField?.amount || ""}
                      placeholder="Enter Amount"
                      onChange={inputFieldHandler}
                      maxlength="6"
                      error={error?.amount}
                    />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Show Slash Pricing</label>
                  <div className="radio_button_wrap">
                    <label
                      htmlFor="true"
                      className={`radio_button input ${
                        inputField?.showSlashedAmount === "true" ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name="showSlashedAmount"
                        id="true"
                        value="true"
                        checked={inputField?.showSlashedAmount === "true"}
                        onChange={inputFieldHandler}
                      />
                      <span>Yes</span>
                    </label>
                    <label
                      htmlFor="false"
                      className={`radio_button input ${
                        inputField?.showSlashedAmount === "false"
                          ? "active"
                          : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name="showSlashedAmount"
                        id="false"
                        value="false"
                        checked={inputField?.showSlashedAmount === "false"}
                        onChange={inputFieldHandler}
                      />
                      <span>No</span>
                    </label>
                  </div>
                  {error && (
                    <span
                      className={`${
                        error?.showSlashedAmount
                          ? "invalid-feedback d-block"
                          : ""
                      }`}
                    >
                      {error?.showSlashedAmount}
                    </span>
                  )}
                </div>
                <div className="form_field">
                  <label className="label">Actual amount</label>
                  <div className="input_wrap">
                    <Input
                      type={"text"}
                      disabled
                      name="amount"
                      value={inputField?.amount || 0}
                      placeholder="Enter Amount"
                      onChange={inputFieldHandler}
                      maxlength="6"
                    />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Slashed amount</label>
                  <div className="input_wrap">
                    <Input
                      type={"text"}
                      placeholder="Enter Amount"
                      disabled={inputField?.showSlashedAmount === "false"}
                      name="slashAmount"
                      value={
                        inputField?.showSlashedAmount === "false"
                          ? ""
                          : inputField?.slashAmount
                      }
                      onChange={inputFieldHandler}
                      maxlength="6"
                      error={error?.slashAmount}
                      extraError={error?.slashAmountErr}
                    />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Display Price</label>
                  <div className="display_price input">
                    {!inputField?.slashAmount ? (
                      <span>
                        {inputField?.amount
                          ? Number(inputField?.amount).toFixed(2)
                          : 0}
                      </span>
                    ) : (
                      <>
                        {" "}
                        <del>
                          <BsCurrencyRupee />{" "}
                          {inputField?.slashAmount
                            ? Number(inputField?.slashAmount)
                            : 0}
                        </del>
                        <span>
                          {inputField?.amount
                            ? Number(inputField?.amount || 0).toFixed(2)
                            : 0}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="table_radius spacing">
              <div class="_sub_title">
                <h4>
                  Display Testimonials for this service
                  <small>
                    Select the best testimonials that you want to display for
                    this service
                  </small>
                </h4>
                <Button
                  className={"dashboard_button"}
                  onClick={() => setShowTestimonilModal(true)}
                >
                  Select Testimonials
                </Button>
              </div>
              <div className="content">
                <div className="card_wrap">
                  {bestTestimonials && bestTestimonials.length ? (
                    <TestimonialCard
                      bestTestimonials={bestTestimonials}
                      setBestTestimonials={setBestTestimonials}
                    />
                  ) : (
                    <Placeholder text={"No Testimonial Found"} />
                  )}
                </div>
              </div>
            </div> */}
            <div className="button_wrap button_right">
              <LoadingButton loading={loading} onClick={addServiceHandler}>
                Save
              </LoadingButton>
            </div>
          </div>

          {viewInfoModal && (
            <HowTo
              url={inputField?.howTo}
              viewInfoModal={viewInfoModal}
              setViewInfoModal={setViewInfoModal}
            />
          )}

          <GetTestimonials
            testimonial={testimonials}
            setTestimonial={setTestimonials}
            showTestimonialModal={showTestimonialModal}
            setShowTestimonilModal={setShowTestimonilModal}
            bestTestimonials={bestTestimonials}
            setBestTestimonials={setBestTestimonials}
          />
        </div>
      </div>
    </>
  );
};

export default AddConsultations;
