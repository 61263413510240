import { Button, DatePicker } from "components/form";
import React, { useState } from "react";
import { Dropdown, DropdownItem } from "react-bootstrap";
import { LuFilter } from "react-icons/lu";
import { useSearchParams } from "react-router-dom";
import { dateFormat } from "utils/dateFormat";

const DateFilter = ({className}) => {
  const [searchParam, setSearchParam] = useSearchParams();

  const [fromDateFilter, setFromDateFilter] = useState(
    searchParam.get("fromDate") || ""
  );
  const [toDateFilter, setTodateFilter] = useState(
    searchParam.get("toDate") || ""
  );



  const dateHandler = () => {
    searchParam.set("fromDate", fromDateFilter);
    searchParam.set("toDate", toDateFilter);
    setSearchParam(searchParam);
  };

  const resetFilterHandler = () => {
    searchParam.delete("fromDate");
    searchParam.delete("toDate");
    setFromDateFilter("");
    setTodateFilter("");
    setSearchParam(searchParam);
  };

  return (
    <Dropdown className={`filter_button_dropdown ${className}`}>
      <Dropdown.Toggle
        className="button light filter_button"
        id="dropdown-basic"
      >
        <LuFilter /> Filters by
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <strong>Filters By Date</strong>
        <div className="form_field_wrap">
          <div className="form_field">
            <DatePicker
              label="Start Date"
              name="fromDate"
              value={fromDateFilter || ""}
              placeholder="Select Start Date"
              onChange={(date) => {
                setFromDateFilter(dateFormat(date));
                setTodateFilter("");
              }}
              maxlength="6"
            />
          </div>
          <div className="form_field">
            <DatePicker
              label="End Date"
              name="toDate"
              value={toDateFilter || ""}
              placeholder="Select End Date"
              onChange={(date) => setTodateFilter(dateFormat(date))}
              maxlength="6"
              minDate={fromDateFilter}
            />
          </div>
        </div>
        <div className="button_wrap button_center">
          <DropdownItem as={"span"}>
            <Button className={"light"} onClick={resetFilterHandler}>
              Reset
            </Button>
          </DropdownItem>
          <DropdownItem as={"span"}>
            <Button
              isDisabled={fromDateFilter && toDateFilter ? false : true}
              onClick={() => dateHandler()}
            >
              Apply
            </Button>
          </DropdownItem>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DateFilter;
