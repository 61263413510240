import React from "react";
import "./Footer.scss";
import { logoWhiteImg } from "assets/images";
import {
  FaInstagram,
  FaLinkedinIn,
  FaTelegramPlane,
  FaYoutube,
} from "react-icons/fa";
import { FaFacebookF, FaXTwitter } from "react-icons/fa6";

import { Link } from "react-router-dom";
const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="footer_content">
            <div className="logo_section">
              <img src={logoWhiteImg} alt="" />
              <p>
                Fitzure empowers fitness professionals and influencers to
                transform their expertise into a thriving business. Our platform
                simplifies service management, bookings, and payments so you can
                focus on your passion—helping others live healthier lives.
              </p>

              <div className="socialIcons">
                <ul>
                  {/* <li>
                    <Link to="https://web.telegram.org/k/" target="_blank">
                      <FaTelegramPlane />
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      to="https://www.instagram.com/fitzure"
                      target="_blank"
                    >
                      <FaInstagram />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://x.com/fitzure30758" target="_blank">
                      <FaXTwitter />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.youtube.com/@fitzure" target="_blank">
                      <FaYoutube />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.facebook.com/Fitzure" target="_blank">
                      <FaFacebookF />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/company/fitzure"
                      target="_blank"
                    >
                      <FaLinkedinIn />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="quickLinks">
              <h3>Quick Links</h3>
              <ul>
                <li>
                  <Link to="/aboutus">About us</Link>
                </li>
                {/* <li>
                  <Link to="/explore-experts">Experts</Link>
                </li> */}
                <li>
                  <Link to="/contactus">Contact us</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy policy</Link>
                </li>
                <li>
                  <Link to="/refund-policy">Cancellation & Refund Policy</Link>
                </li>
                <li>
                  <Link to="/terms">Terms and Conditions</Link>
                </li>
              </ul>
            </div>

            <div className="connect">
              <h3>Contact</h3>
              <ul>
                <li>
                  <a href="tel:+91 9457483395">
                    <span>Call us</span>
                    <strong>+91 9457483395</strong>
                  </a>
                </li>
                <li>
                  <a href="mailto:support@fitzure.com">
                    <span>Email us</span>
                    <strong>support@fitzure.com</strong>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="copyright">
            <p>© {year} All rights are reserved - Powered by Fitzure</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
