import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getTermsApi } from "services/master";
import { toastMessage } from "utils/toastMessage";
// import "./CMS.scss";

const CMSTerms = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  let type = +searchParam.get("type") || "termsAndCondition";
  const [terms, setTerms] = useState([]);
  const [submitQuery, setSubmitQuery] = useState({});
  const [total, setTotal] = useState(null);
  const [loader, onShow, onHide] = FullPageLoader();
  const [loading, setLoading] = useState(false);

  const fetchTerms = async () => {
    try {
      const sendData = {};
      sendData.type = type;
      onShow();
      const { data } = await getTermsApi(sendData);
      if (data?.code === 200) {
        setTerms(data?.data || []);
        setTotal(data?.totalItems || 0);
      } else {
        toastMessage(data?.message, "error", "terms");
        setTerms([]);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      onHide();
    }
  };

  useEffect(() => {
    fetchTerms();
  }, []);
  return (
    <>
    {loader}
      <div className="cms_content ">
        <div className="dashboard_title">
          <h3>Terms & Conditions</h3>
        </div>
        <div className="dashboard_content_inner">
          {/* <h3>Terms & Conditions</h3> */}
            <p
                    dangerouslySetInnerHTML={{
                      __html: terms?.text,
                    }}
                  ></p>
        </div>
      </div>
    </>
  );
};

export default CMSTerms;
