import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Input,
  LoadingButton,
  Select,
  TextArea,
} from "components/form";
import { IoCloudUploadOutline } from "react-icons/io5";
import { FaTimes, FaTrash } from "react-icons/fa";
import { BsCurrencyRupee } from "react-icons/bs";
import { testimonial } from "staticData";
import CustomModal from "components/modals/CustomModal";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import "components/cards/servicesCard/ServicesCard.scss";
import { RiEdit2Line } from "react-icons/ri";
import { LiaTimesSolid } from "react-icons/lia";
import { IoIosBook, IoMdAdd } from "react-icons/io";
import JoditEditor from "jodit-react";
import { allowNumber, createArrayUpTo, getFileExtension, regex } from "utils/formValidator";
import commonMessages, { slashedValidation } from "utils/commonMessages";
import { toastMessage } from "utils/toastMessage";
import { getTestimonials } from "services/master";
import GetTestimonials from "../manageServices/GetTestimonials";
import Placeholder from "components/placeholder/Placeholder";
import { useNavigate } from "react-router-dom";
import { addServicesApi, getHowToApi } from "services/expertServices/manage";
import HowTo from "../manageServices/HowTo";
import CoverImageCropper from "../manageServices/CoverImageCropper";

const AddDigitalProduct = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const [addChapters, setAddChapters] = useState([]);
  const [addTopic, setAddTopic] = useState([]);
  const [active, setActive] = useState("yes");
  const [inputField, setInputField] = useState({});
  const [image, setImage] = useState("");
  const [error, setError] = useState({});
  const { imageFileRegex, documentFileRegex } = regex;
  const { IMAGE_FORMAT } = commonMessages;
  const [modal, setModal] = useState(false);
  const [testimonials, setTestimonials] = useState([]);
  const [bestTestimonials, setBestTestimonials] = useState([]);
  const [showTestimonialModal, setShowTestimonilModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewInfoModal, setViewInfoModal] = useState(false);
  const navigate = useNavigate();

  //   Fetching Testimonial Handler
  const fetchingTestimonialHandler = async () => {
    try {
      setTestimonials([]);
      const sendData = {
        serviceType: "digital",
        page: 0,
        pageSize: 10,
      };
      const { data } = await getTestimonials(sendData);
      if (data?.code === 200) {
        setTestimonials(data?.data);
      } else {
        setTestimonials([]);
        toastMessage(data?.message, "error", "GetTestimonials");
      }
    } catch (e) {
      console.log(e.message);
    }
  };


    //Fetching How To Api
    const fetchingHowTo = async () => {
      try {
        const { data } = await getHowToApi("digital");
        if (data?.code === 200) {
          setInputField({
            ...inputField,
            howTo: data?.data?.url,
            howToId: data?.data?._id,
          });
        } else {
          toastMessage(data?.message, "error", "howTo");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
      }
    };
  
    useEffect(() => {
      fetchingHowTo();
    }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImagePreview(file);
    e.target.value = null;
  };

  const deleteImg = (e) => {
    e.target.value = null;
    setImagePreview(null);
  };


  const noOfChapters=createArrayUpTo(71);

  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false,
      placeholer: "",
      toolbar: true,
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      uploader: {
        insertImageAsBase64URI: true,
      },
      controls: {
        font: {
          command: "fontname",
          list: {
            "'Open Sans',sans-serif": "Open Sans",
            "Helvetica,sans-serif": "Helvetica",
            "Arial,Helvetica,sans-serif": "Arial",
            "Georgia,serif": "Georgia",
            "Impact,Charcoal,sans-serif": "Impact",
            "Tahoma,Geneva,sans-serif": "Tahoma",
            "'Times New Roman',Times,serif": "Times New Roman",
            "Verdana,Geneva,sans-serif": "Verdana",
            "Consolas,monaco,monospace": "Consolas",
          },
        },
      },
    }),
    []
  );

  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        setImage(e.target.files[0]);
      } else {
        e.target.value = "";
        setImage("");
        toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      }
    }
  };

  const inputFieldHandler = (e) => {
    const { name, value } = e.target;

    if (name === "video") {
      setInputField({ ...inputField, [name]: value });
    } else if (name === "showSlashedAmount") {
      setInputField({ ...inputField, [name]: value, slashAmount: "" });
    } else if (name === "amount" || name === "slashAmount") {
      setInputField({ ...inputField, [name]: allowNumber(value) });
    } else {
      setInputField({ ...inputField, [name]: value });
    }
  };

  // Handle Chapter Change
  const handleChapterChange = (id, field, e) => {
    const { name, value } = e.target;
    setAddChapters((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const handleChange = (field, chapterIndex, e, topicIndex) => {
    const { value } = e.target;
    if (field === "uploadType") {
      setAddChapters((prevChapters) => {
        const newChapters = [...prevChapters];
        const topic = newChapters[chapterIndex].topics[topicIndex];
        let newTopic = {
          ...topic,
          [field]: value,
          ["topicsFiles"]: "",
          ["topicVIdeoLinks"]: "",
        };
        newChapters[chapterIndex].topics[topicIndex] = newTopic;
        return newChapters;
      });
    } else {
      setAddChapters((prevChapters) => {
        const newChapters = [...prevChapters];
        const topic = newChapters[chapterIndex].topics[topicIndex];
        let newTopic = { ...topic, [field]: value };
        newChapters[chapterIndex].topics[topicIndex] = newTopic;
        return newChapters;
      });
    }
  };

  //   Handle Document
  const handleDocument = (field, chapterIndex, e, topicIndex) => {

    const { files } = e.target;

    if (files.length === 0) return;

    const file = files[0];
    const isValidFile = documentFileRegex.test(file.name);

    setAddChapters((prevChapters) => {
      const newChapters = [...prevChapters];
      const topic = { ...newChapters[chapterIndex].topics[topicIndex] };

      if (isValidFile) {
        topic[field] = file;
      } else {
        e.target.value = "";
        topic[field] = "";
        toastMessage("Please upload pdf, txt, or doc", "error", "fileFormat");
      }

      newChapters[chapterIndex].topics[topicIndex] = topic;
      return newChapters;
    });
  };

  useEffect(() => {
    const currentChaptersLength = addChapters.length;
    const newChaptersLength = inputField.noOfChapters;

    if (newChaptersLength > currentChaptersLength) {
      const newChapters = Array.from(
        { length: newChaptersLength - currentChaptersLength },
        (_, index) => ({
          id: currentChaptersLength + index + 1,
          name: "",
          expert: JSON.parse(localStorage.getItem("fitzure_info"))._id,
          topics: [
            {
              topicId: Date.now(),
              name: "",
              uploadType: "",
              topicsFiles: "",
              topicVIdeoLinks: "",
            },
          ],
        })
      );
      setAddChapters((prevChapters) => [...prevChapters, ...newChapters]);
    } else if (newChaptersLength < currentChaptersLength) {
      setAddChapters((prevChapters) =>
        prevChapters.slice(0, newChaptersLength)
      );
    }
  }, [inputField.noOfChapters]);

  //   Add Topic
  const addTopicHandler = (chapterIndex) => {
    setAddChapters((prev) => {
      const updatedChapters = [...prev];
      const updatedChapter = { ...updatedChapters[chapterIndex] };
      updatedChapter.topics = [
        ...updatedChapter.topics,
        {
          topicId: Date.now(),
          name: "",
          uploadType: "",
          topicsFiles: "",
          topicVIdeoLinks: "",
        },
      ];
      updatedChapters[chapterIndex] = updatedChapter;

      return updatedChapters;
    });
  };

  //   Delete Topic
  const deleteTopicHandler = (chapterId, topicId, chapterIndex, topicIndex) => {
    setAddChapters((prev) => {
      const updatedChapters = [...prev];
      const updatedChapter = { ...updatedChapters[chapterIndex] };
      const updatedTopics = [...updatedChapter.topics];
      updatedTopics.splice(topicIndex, 1);
      updatedChapter.topics = updatedTopics;
      updatedChapters[chapterIndex] = updatedChapter;

      return updatedChapters;
    });
  };

  // apply validation

  const validation = () => {
    let status = true;
    let errors = {};

    if (!inputField.title) {
      errors["title"] = "Title is mandatory";
      status = false;
    }

    if (!inputField.noOfChapters) {
      errors["noOfChapters"] = "noOfChapters is mandatory";
      status = false;
    }
    if (!inputField?.amount) {
      errors["amount"] = "Amount is mandatory";
      status = false;
    }
    if (!inputField?.showSlashedAmount) {
      errors["showSlashedAmount"] = "Show slash pricing?";
      status = false;
    }
    if (inputField?.showSlashedAmount === "true" && !inputField?.slashAmount) {
      errors["slashAmount"] = "Enter Slash amount";
      status = false;
    }
    let { error, slashstatus } = slashedValidation(
      inputField?.showSlashedAmount,
      inputField?.amount,
      inputField?.slashAmount
    );
    if (Object.keys(error).length > 0) {
      Object.assign(errors, error);
      status = slashstatus;
    }
    if (
      !inputField.description
      // ||
      // emptyDescriptionRegex.test(inputField.description)
    ) {
      errors["description"] = `Description is mandatory`;
      status = false;
    }

    const newErrors = [];
    if (
      inputField.noOfChapters &&
      inputField.noOfChapters > 0 &&
      addChapters.length > 0
    ) {
      addChapters.forEach((chapter, chapterIndex) => {
        if (!newErrors[chapterIndex]) {
          newErrors[chapterIndex] = {};
        }

        if (chapter && !chapter.name) {
          newErrors[chapterIndex] = { name: "Chapter name is mandatory" };
          status = false;
        }
        chapter?.topics.length > 0 &&
          chapter?.topics.forEach((topic, topicIndex) => {
            // if (!newErrors[chapterIndex]) {
            //   newErrors[chapterIndex] = {}
            // }

            // Ensure newErrors[chapterIndex][topicIndex] is initialized
            if (!newErrors[chapterIndex][topicIndex]) {
              newErrors[chapterIndex][topicIndex] = {};
            }

            // Add error messages for missing fields
            if (!topic?.name) {
              newErrors[chapterIndex][topicIndex] = {
                name: "name Option is mandatory",
              };
              status = false;
            }
            if (!topic?.uploadType) {
              newErrors[chapterIndex][topicIndex].uploadType =
                "upload Option is mandatory";
              status = false;
            }

            if (
              topic?.uploadType &&
              topic?.uploadType === "file" &&
              !topic?.topicsFiles
            ) {
              newErrors[chapterIndex][topicIndex] = {
                topicsFiles: "topicsFiles is mandatory",
              };
              status = false;
            }
            if (
              topic?.uploadType &&
              topic?.uploadType === "video" &&
              !topic?.topicVIdeoLinks
            ) {
              newErrors[chapterIndex][topicIndex] = {
                topicVIdeoLinks: "topicVIdeoLinks is mandatory",
              };
              status = false;
            }
          });
      });
      errors.newError = newErrors;
    }

    setError(errors);
    return status;
  };

  // console.log(error);

  //   Add Digital Product
  const addServiceHandler = async () => {
    // console.log(validation());
    if (validation()) {
      const topicVIdeoLinks = addChapters.flatMap((chapter) =>
        chapter.topics
          .filter((topic) => topic.topicVIdeoLinks)
          .map((topic) => topic.topicVIdeoLinks)
      );

      // For getting indexes of url in chapters
      const mergeTopic = addChapters.flatMap((chapter) => chapter.topics);
      const topicVideoIndexes = mergeTopic.reduce((acc, data, index) => {
        if (data?.topicVIdeoLinks) {
          acc.push(index);
        }
        return acc;
      }, []);

      const topicsFiles = addChapters.flatMap((chapter) =>
        chapter.topics
          .map((topic) => topic.topicsFiles)
          .filter((file) => file?.name)
      );

      const chapters = addChapters.reduce((acc, data) => {
        acc.push({
          name: data?.name,
          expert: data?.expert,
          topics: data?.topics.map((topic) => ({
            name: topic?.name,
            uploadType: topic?.uploadType,
          })),
        });
        return acc;
      }, []);

      try {
        setLoading(true)
        const formData = new FormData();
        formData.append("chapter", JSON.stringify(chapters));
        formData.append("topicVIdeoLinks", JSON.stringify(topicVIdeoLinks));
        formData.append("topicVideoIndexes", JSON.stringify(topicVideoIndexes));
        if (topicsFiles && topicsFiles.length > 0) {
          topicsFiles.forEach((data, index) => {
            formData.append("topicsFiles", data);
          });
        }
        // formData.append("topicsFiles", JSON.stringify(topicsFiles));
        formData.append(
          "pricing",
          JSON.stringify({
            amount: inputField?.amount,
            showSlashedAmount: inputField?.showSlashedAmount === "true",
            slashAmount: inputField?.slashAmount || 0,
          })
        );
        if (inputField?.video) {
          formData.append("video", inputField?.video);
        }else{
          formData.append("video", "");
        }
        formData.append("description", inputField?.description);
        formData.append("noOfChapters", inputField?.noOfChapters);
        formData.append("digitalContentType", "course");
        formData.append("serviceType", "digital");
        formData.append("title", inputField?.title);
        if (image && image?.name) {
          formData.append("image", image);
        }
        const { data } = await addServicesApi(formData);
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "addDigital");
          navigate(`/dashboard/digital-contents`);
          setError({});
        } else {
          toastMessage(data?.message, "error", "addDigital");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (showTestimonialModal) {
      fetchingTestimonialHandler();
    }
  }, [showTestimonialModal]);

  useEffect(() => {
    if (inputField?.howTo) {
      setViewInfoModal(true);
    }
  }, [inputField?.howTo]);

  return (
    <>
      <div className="edit_digital_content">
        <div className="dashboard_title how_add">
          <h3>Add Digital Course</h3>
          <button type="button" onClick={() => setViewInfoModal(true)}>
            <IoIosBook />
            Tutorial
          </button>
        </div>

        <div className="dashboard_content_inner">
          <div className="add_services_from">
            <form>
              <div className="content_left">
                <div className="form_field">
                  <Input
                    label={"Title"}
                    name="title"
                    value={inputField?.title || ""}
                    placeholder={"Enter Title"}
                    onChange={inputFieldHandler}
                    maxlength="100"
                    error={error?.title}
                  />
                </div>
                <div className="form_field">
                <label className="label">Description</label>
                  <JoditEditor
                    ref={editor}
                    value={inputField?.description}
                    tabIndex={1}
                    onBlur={(value) =>
                      setInputField({ ...inputField, description: value })
                    }
                    config={config}
                  />
                  <p className="invalid-feedback d-block">
                    {error?.description}
                  </p>
                </div>
                <div className="form_field">
                  <Input
                    label={"Overview Video Link"}
                    name="video"
                    value={inputField?.video || ""}
                    placeholder={"Enter url"}
                    onChange={inputFieldHandler}
                    error={error?.video}
                    extraError={error?.videoErr}
                  />
                </div>
              </div>
              <div className="content_right">
                <div className="form_field">
                  <Select
                    label={"No. Of Chapters"}
                    name="noOfChapters"
                    value={inputField?.noOfChapters || ""}
                    onChange={inputFieldHandler}
                    error={error?.noOfChapters}
                  >
                    <option value="">Select No. Of Chapters</option>
                    {noOfChapters &&
                      noOfChapters.length &&
                      noOfChapters.map((data) => (
                        <option value={data}>{data}</option>
                      ))}
                  </Select>
                </div>

                {/* <div className="form_field position-relative">
                  <label className="label d-block">Upload Cover Image</label>
                  {imagePreview && (
                    <span className="delete_button" onClick={deleteImg}>
                      <FaTimes />
                    </span>
                  )}
                  <label className="imagePreview" htmlFor="coverImage">
                    <input
                      type="file"
                      name="image"
                      id="coverImage"
                      onChange={imageHandler}
                      error={error?.image}
                    />
                    {image ? (
                      <>
                        <img
                          src={
                            image && image?.name
                              ? URL.createObjectURL(image)
                              : image
                          }
                          alt=""
                        />
                      </>
                    ) : (
                      <div className="content">
                        <IoCloudUploadOutline />
                        <label className="button" htmlFor="coverImage">
                          Browse
                        </label>
                        <span>drop a file here</span>
                        <p>*File supported .png, .jpg, .webp</p>
                      </div>
                    )}
                  </label>
                </div>

                {error.image && (
                  <span className="invalid-feedback d-block">
                    {error?.image}
                  </span>
                )} */}

<CoverImageCropper
                  imageSelect={(img) => {
                    setImage(img);
                  }}
                  prevImage={image}
                  error={error?.image}
                />

              </div>
            </form>
            <div className="pricing">
              <h5>Pricing</h5>
              <div className="pricing_content">
                <div className="form_field">
                  <label className="label">Enter Amount</label>
                  <div className="input_wrap">
                    <Input
                      type={"text"}
                      name="amount"
                      value={inputField?.amount || ""}
                      placeholder="Enter Amount"
                      onChange={inputFieldHandler}
                      maxlength="6"
                      error={error?.amount}
                    />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Show Slash Pricing</label>
                  <div className="radio_button_wrap">
                    <label
                      htmlFor="true"
                      className={`radio_button input ${
                        inputField?.showSlashedAmount === "true" ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name="showSlashedAmount"
                        id="true"
                        value="true"
                        checked={inputField?.showSlashedAmount === "true"}
                        onChange={inputFieldHandler}
                      />
                      <span>Yes</span>
                    </label>

                    <label
                      htmlFor="false"
                      className={`radio_button input ${
                        inputField?.showSlashedAmount === "false"
                          ? "active"
                          : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name="showSlashedAmount"
                        id="false"
                        value="false"
                        checked={inputField?.showSlashedAmount === "false"}
                        onChange={inputFieldHandler}
                      />
                      <span>No</span>
                    </label>
                  </div>
                  {error && (
                    <span
                      className={`${
                        !inputField?.showSlashedAmount
                          ? "invalid-feedback d-block"
                          : ""
                      }`}
                    >
                      {!inputField?.showSlashedAmount &&
                        error?.showSlashedAmount}
                    </span>
                  )}
                </div>
                <div className="form_field">
                  <label className="label">Actual amount</label>
                  <div className="input_wrap">
                    <Input
                      type={"text"}
                      disabled
                      name="amount"
                      value={inputField?.amount || 0}
                      placeholder="Enter Amount"
                      onChange={inputFieldHandler}
                      maxlength="6"
                    />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Slashed amount</label>
                  <div className="input_wrap">
                    <Input
                      type={"text"}
                      placeholder="Enter Amount"
                      disabled={inputField?.showSlashedAmount == "false"}
                      name="slashAmount"
                      value={inputField?.slashAmount || ""}
                      onChange={inputFieldHandler}
                      maxlength="6"
                      error={error?.slashAmount}
                      extraError={error?.slashAmountErr}
                    />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Display Price</label>
                  <div className="display_price input">
                  {!inputField?.slashAmount ? (
                      <span>
                        {inputField?.amount
                          ? Number(inputField?.amount).toFixed(2)
                          : 0}
                      </span>
                    ) : (
                      <>
                        {" "}
                        <del>
                          <BsCurrencyRupee />{" "}
                          {inputField?.slashAmount ? Number(inputField?.slashAmount) : 0}
                        </del>
                        <span>
                          {inputField?.amount
                            ? (
                                Number(inputField?.amount || 0)
                              ).toFixed(2)
                            : 0}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="add_chapters spacing">
              <h5>Add Chapters</h5>
              {addChapters && addChapters.length > 0 ? (
                addChapters.map((data, index) => {
                  return (
                    <>
                      <div className="button_wrap button_right">
                        <Button
                          className={"light dashboard_button"}
                          onClick={() => addTopicHandler(index)}
                        >
                          <IoMdAdd /> Add Topic
                        </Button>
                      </div>
                      <div className="form_field">
                        <Input
                          type="text"
                          label={`Chapters ${index+1} Name `}
                          name="name"
                          placeholder="Content management"
                          value={data?.name}
                          onChange={(e) =>
                            handleChapterChange(data?.id, "name", e)
                          }
                          error={
                            !data.name &&
                            error.newError &&
                            error.newError[index]?.name
                          }
                        />
                      </div>
                      {data?.topics && data?.topics.length > 0
                        ? data?.topics.map((topic, topicIndex) => {
                            return (
                              <>
                                <div className="form_field_wrap">
                                  <div className="form_field">
                                    <Input
                                      type="text"
                                      label={`Topic ${topicIndex+1} Name`}
                                      placeholder="Design Review"
                                      name="name"
                                      value={topic?.name}
                                      onChange={(e) =>
                                        handleChange(
                                          "name",
                                          index,
                                          e,
                                          topicIndex
                                        )
                                      }
                                      error={
                                        !topic.name &&
                                        error.newError &&
                                        error.newError[index][topicIndex]?.name
                                      }
                                    />
                                  </div>
                                  <div className="form_field">
                                    <Select
                                      label="Upload Option"
                                      name="uploadType"
                                      value={topic?.uploadType}
                                      onChange={(e) =>
                                        handleChange(
                                          "uploadType",
                                          index,
                                          e,
                                          topicIndex
                                        )
                                      }
                                      error={
                                        !topic.uploadType &&
                                        error.newError &&
                                        error.newError[index][topicIndex]
                                          ?.uploadType
                                      }
                                    >
                                      <option value="">
                                        Select Upload Type
                                      </option>
                                      <option value="file">Document</option>
                                      <option value="video">URL</option>
                                    </Select>
                                  </div>
                                </div>

                                <div className="form_field_wrap">
                                  <div className="form_field">
                                    {topic?.uploadType &&
                                      topic?.uploadType === "video" && (
                                        <Input
                                          type="url"
                                          label="Video Link"
                                          placeholder="Enter url"
                                          name="topicVIdeoLinks"
                                          value={topic?.topicVIdeoLinks}
                                          onChange={(e) =>
                                            handleChange(
                                              "topicVIdeoLinks",
                                              index,
                                              e,
                                              topicIndex
                                            )
                                          }
                                          error={
                                            topic.uploadType === "video" &&
                                            !topic.topicVIdeoLinks &&
                                            error.newError &&
                                            error.newError[index][topicIndex]
                                              ?.topicVIdeoLinks
                                          }
                                        />
                                      )}{" "}
                                    {topic?.uploadType &&
                                      topic?.uploadType === "file" && (
                                        <>
                                          <label className="label d-block">
                                            Upload Document
                                          </label>
                                          <label
                                            className="imagePreview fileUpload"
                                            htmlFor={`fileUpload-${index}-${topicIndex}`}
                                          >
                                            <input
                                              type="file"
                                              name="topicsFiles"
                                              id={`fileUpload-${index}-${topicIndex}`}
                                              onChange={(e) =>
                                                handleDocument(
                                                  "topicsFiles",
                                                  index,
                                                  e,
                                                  topicIndex
                                                )
                                              }
                                              //   error={error?.image}
                                            />
                                            {topic?.topicsFiles ? (
                                              <>
                                                {topic?.topicsFiles &&
                                                  topic?.topicsFiles?.name &&
                                                  getFileExtension(
                                                    topic?.topicsFiles.name
                                                  )}
                                              </>
                                            ) : (
                                              <div className="content">
                                                <IoCloudUploadOutline />
                                                <label
                                                  className="button"
                                                  htmlFor={`fileUpload-${index}-${topicIndex}`}
                                                >
                                                  Browse
                                                </label>
                                                <span>drop a file here</span>
                                                <p>
                                                  *File supported .pdf & .word &
                                                  .txt
                                                </p>
                                              </div>
                                              //  error={
                                              //   topic.uploadType === 'file' &&
                                              //   !topic.topicsFiles &&
                                              //   error.newError &&
                                              //   error.newError[index][topicIndex]
                                              //     ?.topicsFiles
                                              // }
                                            )}
                                          </label>
                                        </>
                                      )}
                                  </div>
                                  <div className="form_field">
                                    <div className="button_wrap button_right">
                                 

                                      {topicIndex !== 0 && (
                                        <button
                                          type="button"
                                          className={"delete_trash"}
                                          onClick={() =>
                                            deleteTopicHandler(
                                              data?.id,
                                              topic?.id,
                                              index,
                                              topicIndex
                                            )
                                          }
                                        >
                                          <FaTrash />
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })
                        : ""}
                    </>
                  );
                })
              ) : (
                <Placeholder text="Select No . Of Chapter" />
              )}
            </div>

            {/* <div className="chapter_list spacing">
              <h5>Chapters List</h5>
              <ul>
                <li>
                  <p>
                    <b>Chapters 1 : Content management</b>
                    <span>Total Topic : 2</span>
                  </p>
                  <div className="action">
                    <button type="button">
                      <RiEdit2Line />
                    </button>
                    <button type="button">
                      <LiaTimesSolid />
                    </button>
                  </div>
                </li>
                <li>
                  <p>
                    <b>Chapters 1 : Content management</b>
                    <span>Total Topic : 2</span>
                  </p>
                  <div className="action">
                    <button type="button">
                      <RiEdit2Line />
                    </button>
                    <button type="button">
                      <LiaTimesSolid />
                    </button>
                  </div>
                </li>
                <li>
                  <p>
                    <b>Chapters 1 : Content management</b>
                    <span>Total Topic : 2</span>
                  </p>
                  <div className="action">
                    <button type="button">
                      <RiEdit2Line />
                    </button>
                    <button type="button">
                      <LiaTimesSolid />
                    </button>
                  </div>
                </li>
              </ul>
            </div> */}
            {/* <div className="table_radius spacing">
              <div class="_sub_title">
                <h4>
                  Display Testimonials for this service
                  <small>
                    Select the best testimonials that you want to display for
                    this service
                  </small>
                </h4>
                <Button
                  className={"dashboard_button"}
                  onClick={() => setShowTestimonilModal(true)}
                >
                  Select Testimonials
                </Button>
              </div>
              <div className="content">
                <div className="card_wrap">
                  {bestTestimonials && bestTestimonials.length ? (
                    <TestimonialCard
                      bestTestimonials={bestTestimonials}
                      setBestTestimonials={setBestTestimonials}
                    />
                  ) : (
                    <Placeholder text={"No Testimonial Found"} />
                  )}
                </div>
              </div>
            </div> */}
            <div className="button_wrap button_right">
              <LoadingButton loading={loading} onClick={addServiceHandler}>
                Save
              </LoadingButton>
            </div>
          </div>

          {viewInfoModal && (
            <HowTo
              url={inputField?.howTo}
              viewInfoModal={viewInfoModal}
              setViewInfoModal={setViewInfoModal}
            />
          )}

          <GetTestimonials
            testimonial={testimonials}
            setTestimonial={setTestimonials}
            showTestimonialModal={showTestimonialModal}
            setShowTestimonilModal={setShowTestimonilModal}
            bestTestimonials={bestTestimonials}
            setBestTestimonials={setBestTestimonials}
          />
        </div>
      </div>
    </>
  );
};

export default AddDigitalProduct;
