import React, { useEffect, useMemo, useRef, useState } from "react";
import "./Services.scss";
import {
  banner1,
  expert1Img,
  imagePlaceholder,
  service1,
  serviceImg1,
} from "assets/images";
// import VideoPlayer from "components/video-player/VideoPlayer";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import { testimonial } from "staticData";
import { Button, Input, LoadingButton } from "components/form";
import CustomModal from "components/modals/CustomModal";
import {
  bookPriorityDmAPi,
  getExpertServiceDetailApi,
  verifySignatureApi,
} from "services/userServices/servicesDetail&Booking";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { toastMessage } from "utils/toastMessage";
import { useNavigate, useParams } from "react-router-dom";
import VideoPlayer from "common/YouTubeVideoPlayer";
import { pdfIcon } from "assets/icons";
import JoditEditor from "jodit-react";
import { regex } from "utils/formValidator";
import { textPriorityDmApi } from "services/userServices/startServices";
import Placeholder from "components/placeholder/Placeholder";
import { useSelector } from "react-redux";
import AuthLogin from "auth/login/Login";
import SignUp from "auth/signUp/SignUp";
import { useCallback } from "react";
import useRazorpay from "react-razorpay";
import PurchaseButton from "components/PurchaseServices/PurchaseButton";
import ConfirmPayModal from "components/modals/ConfirmPayModal";
import SuccessPayment from "components/SuccessfulPaymentModal/SuccessPayment";

const PriorityDMService = () => {
  const { personalInfo } = useSelector((state) => state.config);
  const [Razorpay, isLoaded] = useRazorpay();
  const [loginModal, setLoginModal] = useState(false);
  const [authModal, setAuthModal] = useState(true);
  const { expertId, serviceId } = useParams();
  const { pdfFileRegex } = regex;
  const [loading, setLoading] = useState(false);
  const [payoutModal, setPayoutModal] = useState(false);
  const [sendDmModal, setSendDmModal] = useState(false);
  const [image, setImage] = useState("");
  const [paymentStatusModal, setPaymentStatusModal] = useState(false);
  const [expertServicesDetail, setExpertServicesDetail] = useState({});
  const [bestTestimonials, setBestTestimonials] = useState([]);
  const [loader, onShow, onHide] = FullPageLoader();
  const [inputField, setInputField] = useState({});
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [paymentDetails, setPaymentDetails] = useState("");

  const fetchingExpertServicesDetail = async () => {
    try {
      onShow();
      const { data } = await getExpertServiceDetailApi(expertId, serviceId);
      if (data?.code === 200) {
        setExpertServicesDetail(data?.data);
        setBestTestimonials(data?.data?.testimonials);
      } else {
        toastMessage(data?.message, "error", "ServiceDetail");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false,
      placeholer: "",
      toolbar: true,
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      uploader: {
        insertImageAsBase64URI: true,
      },
      controls: {
        font: {
          command: "fontname",
          list: {
            "'Open Sans',sans-serif": "Open Sans",
            "Helvetica,sans-serif": "Helvetica",
            "Arial,Helvetica,sans-serif": "Arial",
            "Georgia,serif": "Georgia",
            "Impact,Charcoal,sans-serif": "Impact",
            "Tahoma,Geneva,sans-serif": "Tahoma",
            "'Times New Roman',Times,serif": "Times New Roman",
            "Verdana,Geneva,sans-serif": "Verdana",
            "Consolas,monaco,monospace": "Consolas",
          },
        },
      },
    }),
    []
  );

  // Add Banner image handler
  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (pdfFileRegex.test(e.target.files[0].name)) {
        setImage(e.target.files[0]);
      } else {
        e.target.value = "";
        setImage("");
        toastMessage("Invalid Format", "error", "imageFormat");
      }
    } else {
      e.target.value = "";
      setImage("");
    }
  };

  // Send Priority DM
  const sendPriorityDmHandler = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      if (image && image?.name) {
        formData.append("attachment", image);
      }
      formData.append("_id", expertServicesDetail?.alreadyBooked?._id);
      formData.append("text", inputField?.description);
      const { data } = await textPriorityDmApi(formData);
      if (data?.code === 200) {
        toastMessage(data?.message, "success", "bookPriorityDm");
        setSendDmModal(false);
        fetchingExpertServicesDetail();
      } else {
        toastMessage(data?.message, "error", "addWebinar");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  // Book Priority Dm Handler
  // const bookPriorityDmHandler = async () => {
  //   if (personalInfo?.token) {
  //     try {
  //       setLoading(true);
  //       const { data } = await bookPriorityDmAPi({
  //         _id: serviceId,
  //         amount: expertServicesDetail?.pricing?.amount,
  //         totalAmount: expertServicesDetail?.pricing?.totalAmount,
  //         transactionFeePercentage:
  //           expertServicesDetail?.pricing?.transactionFeePercentage,
  //       });
  //       if (data?.code === 200) {
  //         const options = {
  //           key: process.env.REACT_APP_RAZORPAY_KEY,
  //           amount: data?.data?.amount,
  //           currency: data?.data?.currency || "INR",
  //           order_id: data?.data?.orderId,
  //           handler: async (res) => {
  //             if (res) {
  //               const verifyPayment = await verifySignatureApi({
  //                 paymentId: res?.razorpay_payment_id,
  //                 orderId: res?.razorpay_order_id,
  //                 signature: res?.razorpay_signature,
  //               });

  //               const paymentStatus = verifyPayment?.data?.status;

  //               switch (paymentStatus) {
  //                 case "captured":
  //                   toastMessage(
  //                     "Payment successful!",
  //                     "success",
  //                     "bookPriorityDm"
  //                   );
  //                   setPaymentDetails(verifyPayment?.data?.paymentDetails);
  //                   navigate(
  //                     `/expert-details/${expertId}?serviceType=prioritydm`
  //                   );
  //                   setPayoutModal(false);
  //                   setPaymentStatusModal(true);
  //                   fetchingExpertServicesDetail();
  //                   break;

  //                 case "authorized":
  //                   toastMessage(
  //                     "Payment authorized but not yet captured.",
  //                     "info",
  //                     "bookPriorityDm"
  //                   );
  //                   break;

  //                 case "failed":
  //                   toastMessage(
  //                     "Payment failed. Please try again.",
  //                     "error",
  //                     "bookPriorityDm"
  //                   );
  //                   break;

  //                 case "refunded":
  //                   toastMessage("Payment refunded.", "info", "bookPriorityDm");
  //                   break;

  //                 case "partial_refund":
  //                   toastMessage(
  //                     "Partial refund processed.",
  //                     "info",
  //                     "bookPriorityDm"
  //                   );
  //                   break;

  //                 case "created":
  //                   toastMessage(
  //                     "Payment order created. Awaiting completion.",
  //                     "info",
  //                     "bookPriorityDm"
  //                   );
  //                   break;

  //                 case "pending":
  //                   toastMessage(
  //                     "Payment is still pending.",
  //                     "warning",
  //                     "bookPriorityDm"
  //                   );
  //                   break;

  //                 case "authorized_and_voided":
  //                   toastMessage(
  //                     "Payment authorized but voided.",
  //                     "info",
  //                     "bookPriorityDm"
  //                   );
  //                   break;

  //                 default:
  //                   toastMessage(
  //                     "Unknown payment status.",
  //                     "error",
  //                     "bookPriorityDm"
  //                   );
  //                   break;
  //               }
  //             }
  //           },
  //         };

  //         const rzpay = new Razorpay(options);
  //         rzpay.open();
  //       }
  //     } catch (e) {
  //       console.log(e.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   } else {
  //     setLoginModal(true);
  //     setPayoutModal(false);
  //   }
  // };

  useEffect(() => {
    fetchingExpertServicesDetail();
  }, []);

  return (
    <>
      {loader}
      <div className="services_page priorityDM">
        <div className="container">
          <img src={banner1} alt="" className="services_banner" />

          <div className="service_content_wrap">
            <div className="service_banner">
              <div className="banner_content">
                <img src={expertServicesDetail?.image || service1} alt="" />
                <div className="content">
                  <h4>{expertServicesDetail?.title || "---"}</h4>

                  <p className="duration">
                    <span>Promised response</span>{" "}
                    <b> {expertServicesDetail?.responseTime || "---"}</b>{" "}
                  </p>
                  <p className="price">
                    {expertServicesDetail?.pricing?.showSlashedAmount ? (
                      <>
                        <del>
                          ₹ {Number(expertServicesDetail.pricing.slashAmount)}
                        </del>
                        <b>
                          ₹ {Number(expertServicesDetail.pricing.amount)}
                        </b>
                      </>
                    ) : (
                      <>₹ {Number(expertServicesDetail?.pricing?.amount)}</>
                    )}
                  </p>
                </div>
              </div>

              {/* This Button Show Only To User */}
              
              {(personalInfo?.currentType === 1 || !personalInfo?.token) && (
                <div className="banner_tag">
                  <span className="name_tag">PriorityDM</span>
                  {expertServicesDetail?.alreadyBooked?._id ? (
                    <Button
                      className={"dashboard_button"}
                      onClick={() => setSendDmModal(true)}
                    >
                      Send DM
                    </Button>
                  ) : (
                    <Button
                      className={"dashboard_button"}
                      onClick={() => setPayoutModal(true)}
                    >
                      Send DM
                    </Button>
                  )}
                </div>
              )}
            </div>
            <div className="service_content">
              <div className="content_left">
                <h5 className="_title">Overview</h5>
                <div className="content">
                  {expertServicesDetail?.description ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: expertServicesDetail.description,
                      }}
                    ></p>
                  ) : (
                    <p>---</p>
                  )}
                </div>
              </div>
            </div>
            <VideoPlayer src={expertServicesDetail?.video || ""} />

            {bestTestimonials && bestTestimonials.length > 0 && (
              <div className="content_box">
                <h5 className="_title">Testimonials</h5>

                <div className="content">
                  <div className="card_wrap">
                    {bestTestimonials && bestTestimonials.length ? (
                      bestTestimonials.map((item) => {
                        return (
                          <TestimonialCard
                            bestTestimonials={bestTestimonials}
                            setBestTestimonials={setBestTestimonials}
                            display={false}
                          />
                        );
                      })
                    ) : (
                      <Placeholder text="No testimonial available for this service" />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* <CustomModal
        show={payoutModal}
        handleClose={() => setPayoutModal(false)}
        className={"confirm_pay priority"}
      >
        <h3>Confirm and pay</h3>
        <div className="content_wrap">
          <div className="_card">
            <img src={expertServicesDetail?.image || imagePlaceholder} alt="" />
            <div className="content">
              <span>Priority DM</span>
              <h5>{expertServicesDetail?.title} </h5>
              <p>
                Promised response{" "}
                <b> {`(${expertServicesDetail?.responseTime || "---"})`}</b>
              </p>
              <p>
                <b className="color-green">{`₹ ${
                  Number(expertServicesDetail?.pricing?.totalAmount) || "---"
                }`}</b>{" "}
                (Include taxes)
              </p>
            </div>
          </div>
          <PurchaseButton
            className={"w-100 button dashboard_button"}
            token={personalInfo?.token}
            servicesDetail={expertServicesDetail}
            setLoginModal={setLoginModal}
            setPayoutModal={setPayoutModal}
            serviceId={serviceId}
            expertId={expertId}
            setPaymentDetails={setPaymentDetails}
            setPaymentStatusModal={setPaymentStatusModal}

          >
            Process to Pay
          </PurchaseButton>
        </div>
      </CustomModal> */}

      {/* Common Payout Component */}
      <ConfirmPayModal
        show={payoutModal}
        handleClose={() => setPayoutModal(false)}
        modalTitle={"Confirm and pay"}
        serviceTag={"Priority DM"}
        serviceTittle={expertServicesDetail?.title || "---"}
        // duration={`${expertServicesDetail?.duration || "---"}(mins)`}
        responseTime={`${expertServicesDetail?.responseTime || "---"}(days)`}
        price={expertServicesDetail?.pricing?.amount || "---"}
        // date={dateFormat(bookingDate) || "---"}
        // time={slotTime?.startTime || "---"}
        buttonText={"Process to Pay"}
        // onClick={BookOneToOneHandler}
        loginModal={loginModal}
        setLoginModal={setLoginModal}
        authModal={authModal}
        setAuthModal={setAuthModal}
        loading={loading}
        serviceId={serviceId}
        expertId={expertId}
        expertServicesDetail={expertServicesDetail}
        token={personalInfo?.token}
        payoutModal={payoutModal}
        setPayoutModal={setPayoutModal}
        setPaymentStatusModal={setPaymentStatusModal}
      />

      <SuccessPayment
        paymentStatusModal={paymentStatusModal}
        setPaymentStatusModal={setPaymentStatusModal}
        expertId={expertId}
        serviceType="prioritydm"
      />

      <CustomModal show={sendDmModal} handleClose={() => setSendDmModal(false)}>
        <h3>Priority DM</h3>
        {/* <p>{JSON.parse(localStorage.getItem("fitzure_info"))?.email}</p> */}

        {/* <div className="_card has-border attachment"> */}
        {image && image?.name && (
          <>
            {" "}
            <img src={pdfIcon} alt="" />
            <span>attachment.pdf</span>
          </>
        )}
        {/* </div> */}

        <div className="_card has-border">
          <div className="form_field">
            <Input
              label={"Attachment"}
              type="file"
              placeholder={"Enter Title"}
              onChange={imageHandler}
              maxlength="100"
              error={error?.title}
            />
          </div>

          <div className="form_field">
            <JoditEditor
              ref={editor}
              value={inputField?.description}
              tabIndex={1}
              onBlur={(value) =>
                setInputField({ ...inputField, description: value })
              }
              config={config}
            />
            <p className="invalid-feedback d-block">{error?.description}</p>
          </div>
        </div>
        <div className="button_wrap">
          <LoadingButton className="w-100" onClick={sendPriorityDmHandler}>
            Submit
          </LoadingButton>
        </div>
      </CustomModal>
    </>
  );
};

export default PriorityDMService;
