import { pdfIcon } from "assets/icons";
import { Button } from "components/form";
import CustomModal from "components/modals/CustomModal";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { FaDiamond } from "react-icons/fa6";
import "./PriorityDM.scss";
import RatingModal from "components/modals/RatingModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getUserPiorityListApi } from "services/userServices/servicesListing";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { toastMessage } from "utils/toastMessage";
import Placeholder from "components/placeholder/Placeholder";
import { dateFormat } from "utils/dateFormat";
import expertPlaceholderImg from "../../assets/images/expertPlaceholder.png";
import { charLimit, handleMediaDownload } from "utils/mediaDataSender";
import { rateWebinarApi } from "services/userServices/startServices";
import DescriptionModal from "common/DescriptionModal";

import { textPriorityDmApi } from "services/userServices/startServices";
import { regex } from "utils/formValidator";
import { Input, LoadingButton } from "components/form";
import JoditEditor from "jodit-react";

const PriorityDM = () => {
  const [priorityList, setPriorityList] = useState([]);
  // const [activeClass, setActiveClass] = useState("");
  const [reviewModal, setReviewModal] = useState(false);
  const [searchParam, setSearchParam] = useSearchParams();
  let page = +searchParam.get("page") || 1;
  let isReplied = searchParam.get("isReplied") || "true";
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [ratingField, setRatingField] = useState({});
  const [ratingModal, setRatingModal] = useState(false);
  const [loader, onShow, onHide] = FullPageLoader();
  const [viewReplyData, setViewReplyModal] = useState({});
  const [viewExpertiseReply, setViewExpertiseReply] = useState({});
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [description, setDescription] = useState("");
  const [filterData, setFilterData] = useState({});

  const { pdfFileRegex } = regex;
  const editor = useRef(null);
  const [sendDmModal, setSendDmModal] = useState(false);
  const [bookingId, setBookingId] = useState(null);
  const [image, setImage] = useState("");
  const [inputField, setInputField] = useState({});
  const navigate = useNavigate();

  const config = useMemo(
    () => ({
      readonly: false,
      placeholer: "",
      toolbar: true,
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      uploader: {
        insertImageAsBase64URI: true,
      },
      controls: {
        font: {
          command: "fontname",
          list: {
            "'Open Sans',sans-serif": "Open Sans",
            "Helvetica,sans-serif": "Helvetica",
            "Arial,Helvetica,sans-serif": "Arial",
            "Georgia,serif": "Georgia",
            "Impact,Charcoal,sans-serif": "Impact",
            "Tahoma,Geneva,sans-serif": "Tahoma",
            "'Times New Roman',Times,serif": "Times New Roman",
            "Verdana,Geneva,sans-serif": "Verdana",
            "Consolas,monaco,monospace": "Consolas",
          },
        },
      },
    }),
    []
  );

  // Add Banner image handler
  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (pdfFileRegex.test(e.target.files[0].name)) {
        setImage(e.target.files[0]);
      } else {
        e.target.value = "";
        setImage("");
        toastMessage("Invalid Format", "error", "imageFormat");
      }
    } else {
      e.target.value = "";
      setImage("");
    }
  };

  // Send Priority DM
  const sendPriorityDmHandler = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      if (image && image?.name) {
        formData.append("attachment", image);
      }
      formData.append("_id", bookingId);
      formData.append("text", inputField?.description);
      const { data } = await textPriorityDmApi(formData);
      if (data?.code === 200) {
        toastMessage(data?.message, "success", "bookPriorityDm");
        setSendDmModal(false);
        fetchingPriorityDmsHandler();
      } else {
        toastMessage(data?.message, "error", "addWebinar");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  const repliedData = [
    {
      name: "Replied",
      value: "true",
    },
    {
      name: "Pending",
      value: "false",
    },
  ];

  // OnChange Handler for rating
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setRatingField({ ...ratingField, [name]: value });
  };

  // Validation
  const validation = () => {
    let status = true;
    if (!ratingField?.rating) {
      setError("Please provide rating for this service");
      status = false;
    }
    return status;
  };

  const repliedButtonHandler = (service) => {
    searchParam.set("isReplied", service);
    setSearchParam(searchParam);
  };

  const rating = () => {
    setReviewModal(false);
    setRatingModal(true);
  };

  const fetchingPriorityDmsHandler = async () => {
    try {
      onShow();
      const sendData = {};
      sendData.isReplied = isReplied;
      sendData.pageSize = 10;
      sendData.page = page - 1;
      // setSearchParam(sendData);
      const { data } = await getUserPiorityListApi(sendData);
      if (data?.code === 200) {
        setPriorityList(data?.data);
      } else {
        toastMessage(data?.message, "error", "ServiceDetail");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  // Submit Review Handler
  const submitReviewHandler = async () => {
    if (validation()) {
      try {
        setLoading(true);
        const { data } = await rateWebinarApi(ratingField);
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "ratePriority");
          setRatingField({});
          setRatingModal(false);
          fetchingPriorityDmsHandler();
        } else {
          toastMessage(data?.message, "error", "ratePriority");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (page === 0) {
      searchParam.delete("page");
      setSearchParam(searchParam);
    }
    fetchingPriorityDmsHandler();
  }, [searchParam]);

  const navigateHandler = (expertId, serviceId) => {
    navigate(`/priorityDM-detail/${expertId}/${serviceId}`);
  };

  return (
    <>
      <div className="exploreExperts">
        <div className="container">
          <div className="page_title text-center">
            <span>Priority DM</span>
            {/* <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's
            </p> */}
          </div>

          <div className="priorityDM_section">
            <div className="tab_button_wrap">
              {repliedData &&
                repliedData.length &&
                repliedData.map((data) => (
                  <button
                    type="button"
                    onClick={() => repliedButtonHandler(data?.value)}
                    className={`button ${
                      isReplied === data.value ? "active" : ""
                    }`}
                  >
                    {data?.name}
                    <FaDiamond />
                  </button>
                ))}
            </div>
          </div>

          <div className="table_wrap">
            <div className="table_inner">
              <table className="table">
                <thead>
                  <tr>
                    <th>Expert</th>
                    <th>Services</th>
                    <th>Date</th>
                    <th>Message</th>
                    <th>Attachment</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {priorityList && priorityList.length ? (
                    priorityList?.map((data) => {
                      return (
                        <tr>
                          <td>
                            {data?.expert &&
                              `${data?.expert?.firstName || "---"} ${" "} ${
                                data?.expert?.lastName || "---"
                              }`}
                          </td>
                          <td>
                            {(data?.service && data.service?.title) || "---"}
                          </td>
                          <td>{dateFormat(data?.createdAt)}</td>
                          {/* <td>
                          {(data?.service && data.service?.description) ||
                            "---"}
                          Lorem Ipsum is simply dummy text of the printing ...
                          <span className="readMoreButton">Read More</span>
                        </td> */}
                          <td
                            // onClick={() =>
                            //   navigateHandler(
                            //     data?.expert?._id,
                            //     data?.service?._id
                            //   )
                            // }
                          >
                            {data?.userMessage?.text ? (
                              <>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: charLimit(
                                      data?.userMessage?.text,
                                      50
                                    ),
                                  }}
                                ></p>
                                {data?.userMessage?.text?.length > 50 && (
                                  <span
                                    onClick={() => {
                                      if (
                                        data?.userMessage?.text &&
                                        data?.userMessage?.text?.length > 50
                                      ) {
                                        setDescriptionModal(true);
                                        setDescription(
                                          <p className="word-wrap"
                                            dangerouslySetInnerHTML={{
                                              __html: data?.userMessage?.text,
                                            }}
                                          ></p>
                                        );
                                      }
                                    }}
                                  >
                                    <button type="button">Read More</button>
                                  </span>
                                )}
                              </>
                            ) : (
                              <p>---</p>
                            )}
                          </td>
                          <td>
                            {data?.userMessage?.attachment ? (
                              <div
                                className="attachment"
                                onClick={() =>
                                  handleMediaDownload(
                                    data?.userMessage?.attachment
                                  )
                                }
                              >
                                <img src={pdfIcon} alt="" />
                                <span>attachment.pdf</span>
                              </div>
                            ) : isReplied === "false" &&
                              data?.userMessage?.attachment &&
                              data?.userMessage?.attachment?.length ? (
                              <div
                                className="attachment"
                                onClick={() =>
                                  handleMediaDownload(
                                    data?.userMessage?.attachment
                                  )
                                }
                              >
                                <img src={pdfIcon} alt="" />
                                <span>attachment.pdf</span>
                              </div>
                            ) : (
                              <div>N/A</div>
                            )}
                          </td>
                          <td>
                            {isReplied === "true" ? (
                              <button
                                type="button"
                                className="button"
                                onClick={() => {
                                  setReviewModal(true);
                                  setViewExpertiseReply({
                                    ...data?.expertMessage,
                                    _id: data?._id,
                                    isRated: data?.isRated,
                                  });
                                }}
                              >
                                View Reply
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="button"
                                onClick={() => {
                                  setBookingId(data?._id || null);
                                  setSendDmModal(true);
                                }}
                                disabled={
                                  data?.userMessage?.text?.length ? true : false
                                }
                              >
                                Send DM
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <Placeholder
                          // image={expertPlaceholderImg}
                          text={"No DMs Found"}
                        />
                      </td>
                    </tr>
                  )}
                  {/* <tr>
                  <td>Vivek Kumar </td>
                  <td>Design Review</td>
                  <td>05/21/2024</td>
                  <td>
                    Lorem Ipsum is simply dummy text of the printing ...
                    <span className="readMoreButton">Read More</span>
                  </td>
                  <td>
                    <div className="attachment">
                      <img src={pdfIcon} alt="" />
                      <span>attachment.pdf</span>
                    </div>
                  </td>
                  <td>
                    <button
                      type="button"
                      className="button"
                      onClick={() => setReviewModal(true)}
                    >
                      View Reply
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Vivek Kumar </td>
                  <td>Design Review</td>
                  <td>05/21/2024</td>
                  <td>
                    Lorem Ipsum is simply dummy text of the printing ...
                    <span className="readMoreButton">Read More</span>
                  </td>
                  <td>
                    <div className="attachment">
                      <img src={pdfIcon} alt="" />
                      <span>attachment.pdf</span>
                    </div>
                  </td>
                  <td>
                    <button type="button" className="button">
                      View Reply
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="nowrap">Vivek Kumar </td>
                  <td className="nowrap">Design Review</td>
                  <td>05/21/2024</td>
                  <td>
                    Lorem Ipsum is simply dummy text of the printing ...
                    <span className="readMoreButton">Read More</span>
                  </td>
                  <td>
                    <div className="attachment">
                      <img src={pdfIcon} alt="" />
                      <span>attachment.pdf</span>
                    </div>
                  </td>
                  <td className="nowrap">
                    <button type="button" className="button">
                      View Reply
                    </button>
                  </td>
                </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <CustomModal show={sendDmModal} handleClose={() => setSendDmModal(false)}>
        <h3>Priority DM</h3>
        <p>{JSON.parse(localStorage.getItem("fitzure_info"))?.email}</p>

        <div className="_card has-border attachment">
          {image && image?.name && (
            <>
              {" "}
              <img src={pdfIcon} alt="" />
              <span>attachment.pdf</span>
            </>
          )}
        </div>

        <div className="_card has-border">
          {/* <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p> */}
          <div className="form_field">
            <Input
              label={"Attachment"}
              type="file"
              placeholder={"Enter Title"}
              onChange={imageHandler}
              maxlength="100"
              error={error?.title}
            />
          </div>

          <div className="form_field">
            <JoditEditor
              ref={editor}
              value={inputField?.description}
              tabIndex={1}
              onBlur={(value) =>
                setInputField({ ...inputField, description: value })
              }
              config={config}
            />
            <p className="invalid-feedback d-block">{error?.description}</p>
          </div>
        </div>
        <div className="button_wrap">
          <LoadingButton className="w-100" onClick={sendPriorityDmHandler}>
            Submit
          </LoadingButton>
        </div>
      </CustomModal>

      <CustomModal show={reviewModal} handleClose={() => setReviewModal(false)}>
        <h3>View Reply Details</h3>
        {/* <p>Please enter your registered email address to continue</p> */}

        {viewExpertiseReply?.attachment && (
          <div
            className="_card has-border attachment"
            onClick={() => handleMediaDownload(viewExpertiseReply?.attachment)}
          >
            <img src={pdfIcon} alt="" />
            <span>attachment.pdf</span>
          </div>
        )}

        <div className="_card has-border">
          <p>{viewExpertiseReply?.text}</p>
        </div>
        <div className="button_wrap">
          <Button
            className="w-100"
            isDisabled={
              viewExpertiseReply?.isRated &&
              viewExpertiseReply?.isRated.toString() === "true"
            }
            onClick={() => {
              rating();
              setRatingField({
                ...ratingField,
                _id: viewExpertiseReply?._id,
                serviceType: "prioritydm",
              });
            }}
          >
            Rate Service
          </Button>
        </div>
      </CustomModal>

      <RatingModal
        show={ratingModal}
        handleClose={() => setRatingModal(false)}
      />

      {/* Rating Webinar Services Modal */}
      {ratingModal && (
        <RatingModal
          show={ratingModal}
          handleClose={() => {
            setRatingModal(false);
            setRatingField({});
            setError("");
          }}
          ratingField={ratingField}
          setRatingField={setRatingField}
          onChangeHandler={onChangeHandler}
          loading={loading}
          action={submitReviewHandler}
          error={error}
        />
      )}

      {descriptionModal && (
        <DescriptionModal
          show={descriptionModal}
          handleClose={() => {
            setDescriptionModal(false);
          }}
          description={description}
          title={"Description"}
        />
      )}
    </>
  );
};

export default PriorityDM;
