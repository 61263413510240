import { expert1Img, expert2Img, expert3Img } from "assets/images";




export const experts_card = [
  {
    id: "01",
    profileImage: expert1Img,
    expertName: "Vivek Kumar",
    expertService: "Calisthenics",
    rating: 4.5,
    users: "20",
  },
  {
    id: "02",
    profileImage: expert2Img,
    expertName: "Vivek Kumar",
    expertService: "Calisthenics",
    rating: 4.5,
    users: "20",
  },
  {
    id: "03",
    profileImage: expert1Img,
    expertName: "Vivek Kumar",
    expertService: "Calisthenics",
    rating: 4.5,
    users: "20",
  },
  {
    id: "04",
    profileImage: expert2Img,
    expertName: "Vivek Kumar",
    expertService: "Calisthenics",
    rating: 4.5,
    users: "20",
  },
  {
    id: "05",
    profileImage: expert1Img,
    expertName: "Vivek Kumar",
    expertService: "Calisthenics",
    rating: 4.5,
    users: "20",
  },
  {
    id: "06",
    profileImage: expert3Img,
    expertName: "Vivek Kumar",
    expertService: "Calisthenics",
    rating: 4.5,
    users: "20",
  },
];



export const testimonial = [
  {
    id: "01",
    title: "Rahul Kumar",
    date: "20 May 2024",
    description:
      "Dr vivek sharma has done his MDS in prosthodontics and crown and bridge and has a vast experience of working in government and private dental setups. He is member of indian dental association, indian prosthodontc society and academy of.",
  },
  {
    id: "02",
    title: "Rahul Kumar",
    date: "20 May 2024",
    description:
      "Dr vivek sharma has done his MDS in prosthodontics and crown and bridge and has a vast experience of working in government and private dental setups. He is member of indian dental association, indian prosthodontc society and academy of.",
  },
  {
    id: "04",
    title: "Rahul Kumar",
    date: "20 May 2024",
    description:
      "Dr vivek sharma has done his MDS in prosthodontics and crown and bridge and has a vast experience of working in government and private dental setups. He is member of indian dental association, indian prosthodontc society and academy of.",
  },
  {
    id: "03",
    title: "Rahul Kumar",
    date: "20 May 2024",
    description:
      "Dr vivek sharma has done his MDS in prosthodontics and crown and bridge and has a vast experience of working in government and private dental setups. He is member of indian dental association, indian prosthodontc society and academy of.",
  },
];
