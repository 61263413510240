import { LoadingButton } from "components/form";
import React, { useState } from "react";
import {
  bookPriorityDmAPi,
  verifySignatureApi,
} from "services/userServices/servicesDetail&Booking";
import { toastMessage } from "utils/toastMessage";
import useRazorpay from "react-razorpay";
import { useNavigate } from "react-router-dom";
import {
  convertTo12HourFormat,
  mergeTimeDate,
  setDynamicTime12Hour,
} from "utils/dateFormat";
import { useSelector } from "react-redux";

const PurchaseButton = ({
  children,
  className,
  token,
  servicesDetail,
  setLoginModal,
  setPayoutModal,
  setPaymentStatusModal,
  setPaymentDetails,
  expertId,
  serviceId,
  serviceTag,
  bookingEndDate = "",
  time,
  days,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);
  const { personalInfo } = useSelector((state) => state.config);
  const [Razorpay, isLoaded] = useRazorpay();

  // Book Services Handler
  const bookServicesHandler = async () => {
    if (token) {
      try {
        // Common Payload For All Services || Priority || Digital
        const reqData = {
          _id: serviceId,
          amount: servicesDetail?.pricing?.amount,
          totalAmount: servicesDetail?.pricing?.amount,
          transactionFeePercentage:
            servicesDetail?.pricing?.transactionFeePercentage,
        };
        // For 1:1 Services
        if (rest?.slotTime && rest?.bookingDate) {
          reqData.slot = rest?.slotTime;
          reqData.bookedFor = setDynamicTime12Hour(
            rest?.slotTime?.startTime,
            rest?.bookingDate
          );
          reqData.bookingEndDate = bookingEndDate;
        }

        // For Webinar Services
        if (servicesDetail?.webinarAvailability && rest?.slotTime) {
          reqData.bookedFor = servicesDetail?.webinarAvailability;
          reqData.bookingEndDate = bookingEndDate;
          reqData.slot = rest?.slotTime;
        }

        // For Subscription Services
        if (
          serviceTag === "Personal Training" ||
          serviceTag === "Group Training" ||
          serviceTag === "Batch Training"
        ) {
          reqData.slot = { ...rest?.slotTime, days: days };
          reqData.bookedFor = setDynamicTime12Hour(
            convertTo12HourFormat(rest?.slotTime?.startTime),
            rest?.bookingDate
          );
        }
        setLoading(true);
        const { data } = await bookPriorityDmAPi(reqData);
        if (data?.code === 200) {
          if (data?.data?.shortUrl) {
            window.open(data?.data?.shortUrl);
            toastMessage("Payment successful!", "success", serviceTag);
            setPayoutModal(false);
            // setPaymentStatusModal(true);
          } else {
            const options = {
              key: process.env.REACT_APP_RAZORPAY_KEY,
              amount: data?.data?.amount,
              contact: personalInfo?.countryCode + personalInfo?.phone,
              name: `${personalInfo?.firstName}${" "}${personalInfo?.lastName}`,
              currency: data?.data?.currency || "INR",
              order_id: data?.data?.orderId,
              handler: async (res) => {
                if (res && !data?.data?.shortUrl) {
                  const verifyPayment = await verifySignatureApi({
                    paymentId: res?.razorpay_payment_id,
                    orderId: res?.razorpay_order_id,
                    signature: res?.razorpay_signature,
                  });

                  const paymentStatus = verifyPayment?.data?.status;
                  switch (paymentStatus) {
                    case "captured":
                      toastMessage(
                        "Payment successful!",
                        "success",
                        "bookPriorityDm"
                      );
                      setPayoutModal(false);
                      setPaymentStatusModal(true);
                      // setPaymentDetails(verifyPayment?.data?.paymentDetails);
                      // navigate(
                      //   `/expert-details/${expertId}?serviceType=prioritydm`
                      // );
                      // fetchingExpertServicesDetail();
                      break;

                    case "authorized":
                      toastMessage(
                        "Payment authorized but not yet captured.",
                        "info",
                        "bookPriorityDm"
                      );
                      break;

                    case "failed":
                      toastMessage(
                        "Payment failed. Please try again.",
                        "error",
                        "bookPriorityDm"
                      );
                      break;

                    case "refunded":
                      toastMessage(
                        "Payment refunded.",
                        "info",
                        "bookPriorityDm"
                      );
                      break;

                    case "partial_refund":
                      toastMessage(
                        "Partial refund processed.",
                        "info",
                        "bookPriorityDm"
                      );
                      break;

                    case "created":
                      toastMessage(
                        "Payment order created. Awaiting completion.",
                        "info",
                        "bookPriorityDm"
                      );
                      break;

                    case "pending":
                      toastMessage(
                        "Payment is still pending.",
                        "warning",
                        "bookPriorityDm"
                      );
                      break;

                    case "authorized_and_voided":
                      toastMessage(
                        "Payment authorized but voided.",
                        "info",
                        "bookPriorityDm"
                      );
                      break;

                    default:
                      toastMessage(
                        "Unknown payment status.",
                        "error",
                        "bookPriorityDm"
                      );
                      break;
                  }
                }
              },
              prefill: {
                name: `${personalInfo?.firstName}${" "}${
                  personalInfo?.lastName
                }`,
                email: personalInfo?.email,
                contact: personalInfo?.countryCode + personalInfo?.phone,
              },
            };

            const rzpay = new Razorpay(options);
            rzpay.open();
          }
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    } else {
      setLoginModal(true);
      setPayoutModal(false);
    }
  };
  return (
    <div className="button_wrap">
      <LoadingButton
        className={className}
        onClick={bookServicesHandler}
        loading={loading}
      >
        {children}
      </LoadingButton>
    </div>
  );
};

export default PurchaseButton;
