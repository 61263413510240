import React, { useEffect, useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { LuFilter, LuReply } from "react-icons/lu";
import {
  Button,
  DatePicker,
  Input,
  LoadingButton,
  Select,
  TextArea,
} from "components/form";
import { Dropdown } from "react-bootstrap";
import { pdfIcon } from "assets/icons";
import CustomModal from "components/modals/CustomModal";
import { GrAttachment } from "react-icons/gr";
import { IoClose } from "react-icons/io5";
import "./TransitionHistory.scss";
import {
  authorizeRazorpayApi,
  getExpertTransactionListApi,
} from "services/transaction";
import DateFilter from "../priorityDMS/DateFilter";
import { useSearchParams } from "react-router-dom";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { toastMessage } from "utils/toastMessage";
import { dateFormatWithTime, dateFormatYear } from "utils/dateFormat";
import CommonPagination from "common/CommonPagination";
import { getLocalStorage, setLocalStorage } from "config/config";
import UseDebounce from "common/UseDebounce";
import { useSelector } from "react-redux";
import { personDetailApi } from "services/details";
import Placeholder from "components/placeholder/Placeholder";

const TransitionHistory = () => {
  const [tabButton, setTabButton] = useState("pending");
  const [loading, setLoading] = useState(false);
  const [accountDetail, setAccountDetail] = useState({});
  const [razorPayAuthorizeModal, setRazorPayAuthorizeModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [ReplyModal, setReplyModal] = useState(false);
  const [attached, setAttached] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  const [total, setTotal] = useState(null);
  const [loader, onShow, onHide] = FullPageLoader();
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  let fromDate = searchParam.get("fromDate") || "";
  let toDate = searchParam.get("toDate") || "";
  const [search, setSearch] = useState(
    getLocalStorage("expertTransactionList", "search") || ""
  );
  const { personalInfo } = useSelector((state) => state.config);
  const debouncedValue = UseDebounce(search, 300);

  const fetchingTransactionListHandler = async () => {
    try {
      onShow();
      const sendData = {};
      sendData.page = activePage - 1;
      if (debouncedValue) {
        sendData.search = debouncedValue;
        sendData.pageSize = 10;
        sendData.page = 0;
      }
      if (fromDate && toDate) {
        sendData.fromDate = dateFormatYear(fromDate);
        sendData.toDate = dateFormatYear(toDate);
      }
      const { data } = await getExpertTransactionListApi(sendData);
      if (data?.code === 200) {
        setTransactionList(data?.data || []);
        setTotal(data?.totalItems || 0);
      } else {
        toastMessage(data?.message, "error", "transactionList");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      onHide();
    }
  };

  // Fetching Account Detail
  const fetchingAccountDetail = async (screen = 2) => {
    try {
      onShow();
      const response = await personDetailApi(screen);
      if (response && response?.data?.code === 200) {
        setAccountDetail({
          ...response?.data?.data?.bank,
          confirmAccountNumber: response?.data?.data?.bank?.accountNumber,
        });
      } else {
        toastMessage(response?.data?.message, "error", "personDetail");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      onHide();
    }
  };

  // Authorize Razorpay

  const authorizeRazorpayHandler = async () => {
    try {
      setLoading(true);
      const { data } = await authorizeRazorpayApi();
      if (data?.code === 200) {
        toastMessage(data?.message, "success", "AuthorizeRazorpay");
        fetchingTransactionListHandler();
        setRazorPayAuthorizeModal(false);
      } else {
        toastMessage(data?.message, "error", "AuthorizeRazorpay");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    if (Number(event) === 1) {
      searchParam.delete("page");
    } else {
      searchParam.set("page", event);
    }
    setSearchParam(searchParam);
  };

  useEffect(() => {
    fetchingTransactionListHandler();
  }, [searchParam, debouncedValue]);

  useEffect(() => {
    setLocalStorage("expertTransactionList", "search", search);
  }, [search]);

  useEffect(() => {
    fetchingAccountDetail();
  }, []);

  return (
    <>
      {loader}
      <div className="transition_history">
        <div className="dashboard_title">
          <h3>Transaction History</h3>
        </div>

        <div className="dashboard_content_inner">
          <div className="filter_wrap">
            <div className="has_child">
              <div className="searchBox">
                <input
                  type="search"
                  className="input"
                  placeholder="Search By Name"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <IoIosSearch />
              </div>
              <DateFilter
              className={"mx-2"}
                filterModal={filterModal}
                setFilterModal={setFilterModal}
              />
            </div>

            <div className="has_child">
              {!transactionList[0]?.user?.isRazorPayFundAccount && (
                <Button onClick={() => setRazorPayAuthorizeModal(true)}>
                  Auth Razorpay
                </Button>
              )}
            </div>
          </div>
          <div className="table_wrap">
            <div className="table_inner">
              <table className="table">
                <thead>
                  <tr>
                    <th className="nowrap">Transaction ID</th>
                    <th className="nowrap">Booking ID</th>
                    <th className="nowrap">Date & Time</th>
                    <th className="nowrap">Customer name</th>
                    <th className="nowrap">Service</th>
                    <th className="nowrap">Total Amount</th>
                    <th className="nowrap">Platform Charges</th>
                    <th className="nowrap">Transaction Charges</th>
                    <th className="nowrap">Cancellation</th>
                    <th className="nowrap">Total Earned</th>
                    <th className="nowrap">Payout Status</th>
                  </tr>
                </thead>
                <tbody>
                  {transactionList && transactionList.length > 0 ? (
                    transactionList.map((data) => {
                      return (
                        <tr>
                          <td>{data?.paymentId || "---"}</td>
                          <td>{data?.bookingId || "---"}</td>
                          <td className="nowrap">
                            {dateFormatWithTime(data?.createdAt)}
                          </td>
                          <td>
                            {data?.user
                              ? `${data.user.firstName || "---"} ${
                                  data.user.lastName || "---"
                                }`
                              : "---"}
                          </td>
                          <td className="nowrap">{data?.service || "---"}</td>

                          <td>{`INR ${data?.amount || "---"}`}</td>
                          <td>{`INR ${data?.platformCharge || 0}`}</td>
                          <td>{`INR ${data?.transactionCharge || 0}`}</td>
                          <td>{`INR ${data?.cancellationCharge || 0}`}</td>
                          <td>{`INR ${data?.expertEarnedAmount || 0}`}</td>
                          <td className="text-center">{data?.payoutStatus}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={11}>
                        <Placeholder text={"No Transaction Found"} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {
            <CommonPagination
              handlePageChange={handlePageChange}
              activePage={activePage}
              total={total}
            />
          }
        </div>
      </div>

      <CustomModal show={ReplyModal} handleClose={() => setReplyModal(false)}>
        <div className="PriorityDMS_reply">
          <h3 className="title">Reply to Vivek Kumar</h3>
          <p>Please enter your registered email address to continue</p>

          <div className="form_field">
            <label className="label">
              Enter Message{" "}
              <p className="file_upload">
                {attached ? (
                  <label htmlFor="uploadFile">
                    <input type="file" id="uploadFile" />
                    <GrAttachment />
                    <span className="text-decoration-underline">
                      Upload Document
                    </span>
                  </label>
                ) : (
                  <>
                    <img src={pdfIcon} alt="" />{" "}
                    <span className="text-decoration-underline">File name</span>
                    <button
                      type="button"
                      className="cancel_button"
                      onClick={() => setAttached(true)}
                    >
                      <IoClose />
                    </button>
                  </>
                )}
              </p>
            </label>
            <TextArea placeholder="Enter Message" />
          </div>
          <LoadingButton className="w-100">Submit</LoadingButton>
        </div>
      </CustomModal>

      {/* Razorpay Auth */}
      <CustomModal
        show={razorPayAuthorizeModal}
        handleClose={() => setRazorPayAuthorizeModal(false)}
        className={"md"}
      >
        <div className="form_field_wrap multiple">
          <div className="form_field">
            <Input
              type="text"
              name="firstName"
              disabled
              placeholder="Enter First Name"
              label="Email"
              value={personalInfo.email || ""}
              maxlength="25"
            />
          </div>
          <div className="form_field">
            <Input
              type="text"
              name="lastName"
              disabled
              placeholder="Enter Last Name"
              label="Phone"
              value={personalInfo?.phone || ""}
              maxlength="25"
            />
          </div>
          <div className="form_field">
            <Input
              type="text"
              name="lastName"
              disabled
              placeholder="Enter Last Name"
              label="IFSC"
              value={accountDetail?.ifsc || ""}
              maxlength="25"
            />
          </div>
          <div className="form_field">
            <Input
              type="text"
              disabled
              name="lastName"
              placeholder="Enter Last Name"
              label="Account Holder Name"
              value={accountDetail?.accountHolderName || ""}
              maxlength="25"
            />
          </div>
          <div className="form_field">
            <Input
              type="text"
              name="lastName"
              disabled
              placeholder="Enter Last Name"
              label="Account Number"
              value={accountDetail?.accountNumber || ""}
              maxlength="25"
            />
          </div>
          <div className="form_field"></div>
        </div>
        <div className="button_wrap button_right mt-3">
          <LoadingButton loading={loading} onClick={authorizeRazorpayHandler}>
            Authorize Account
          </LoadingButton>
        </div>
      </CustomModal>
    </>
  );
};

export default TransitionHistory;
