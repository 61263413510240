import React, { useEffect, useRef, useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { IoCloseSharp, IoCloudUploadOutline } from "react-icons/io5";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { imgUploadImg, profilePlaceholder } from "assets/icons";
import { toastMessage } from "utils/toastMessage";
import CustomModal from "components/modals/CustomModal";
import { base64ToFile } from "utils/base64ToFile";
// import profileImg from "../../assets/images/profileImg.png";
import { Button } from "components/form";

const CoverImageCropper = ({ imageSelect, prevImage = "", error, ...rest }) => {
  console.log(prevImage, error, "Error", "ImageSelect");
  //   console.log(error, "error");
  const imageRef = useRef();
  const [aspect] = useState(8 / 8);
  const [imageModal, setImageModal] = useState(false);
  const [cropperImage, setCropperImage] = useState("");
  const [image, setImage] = useState(prevImage);
  const [imageName, setImageName] = useState("");
  const [, setCropData] = useState("#");
  const [cropper, setCropper] = useState();

  const onChange = (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      const { type } = e.target.files[0];
      if (
        type === "image/webp" ||
        type === "image/png" ||
        type === "image/jpg" ||
        type === "image/jpeg"
      ) {
        let files;
        if (e?.dataTransfer) {
          files = e?.dataTransfer?.files;
        } else if (e.target) {
          files = e.target.files;
        }
        setImageName(files[0]?.name);
        const reader = new FileReader();
        reader.onload = () => {
          setCropperImage(reader?.result);
        };
        reader.readAsDataURL(files[0]);
        setImageModal(true);
      } else {
        toastMessage(
          "Please upload only jpeg,jpg and png image",
          "error",
          "imagePicker"
        );
      }
      e.target.value = "";
    }
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      setImage(base64ToFile(cropper.getCroppedCanvas().toDataURL(), imageName));
      imageSelect(
        base64ToFile(cropper.getCroppedCanvas().toDataURL(), imageName)
      );
      setImageModal(false);
    }
  };

  const cancelHandler = () => {
    setImageModal(false);
    setImage("");
    setImageName("");
    imageSelect("");
  };

  useEffect(() => {
    setImage(prevImage);
  }, [prevImage]);

  return (
    <>
      <div className="form_field position-relative">
        <label className="label d-block">Upload Cover Image</label>
        <label className="imagePreview" htmlFor="fileUpload">
          <input
            type="file"
            name="image"
            id="fileUpload"
            onChange={onChange}
            error={error?.image}
          />
          {image ? (
            <>
              <img
                src={image && image?.name ? URL.createObjectURL(image) : image}
                alt=""
              />
            </>
          ) : (
            <div className="content">
              <IoCloudUploadOutline />
              <label className="button" htmlFor="fileUpload">
                Browse
              </label>
              <span>drop a file here</span>
              <p>*File supported .png, .jpg, .webp</p>
            </div>
          )}
        </label>
      </div>

      {error?.image && (
        <span className="invalid-feedback d-block">{error?.image}</span>
      )}

      <CustomModal
        show={imageModal}
        handleClose={() => setImageModal(false)}
        className={"image_cropper"}
      >
        <div className="crop_image">
          <Cropper
            initialAspectRatio={aspect}
            dragMode="move"
            preview=".img-preview"
            src={cropperImage}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            cropBoxResizable={true}
            aspectRatio={aspect}
            movable={false}
          />
        </div>
        <div className="button_wrap button_right">
          <Button className="light" onClick={cancelHandler}>
            Cancel
          </Button>
          <Button className="" onClick={getCropData}>
            Crop
          </Button>
        </div>
      </CustomModal>
    </>
  );
};

export default CoverImageCropper;
