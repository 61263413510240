import { Button } from "components/form";
import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { serviceCardData } from "staticData";
import ServicesCard from "components/cards/servicesCard/ServicesCard";
import CustomModal from "components/modals/CustomModal";
import {
  PriorityDMsIcon,
  PriorityDMsIcon2,
  crownIcon2,
  on2onIcon2,
  webinarIcon,
} from "assets/icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getLocalStorage } from "config/config";
import { getServicesApi } from "services/expertServices/manage";
import { toastMessage } from "utils/toastMessage";
// import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import Placeholder from "components/placeholder/Placeholder";
import { charLimit } from "utils/mediaDataSender";
import { createArrayUpTo } from "utils/formValidator";
import { authorizeZoomAccountApi } from "services/details";

const ManageServices = () => {
  const [servicesList, setServicesList] = useState([]);
  const [isZoomAuth, setIsZoomAuth] = useState(false);
  // const [code, setCode] = useState(null);
  const [activeClass, setActiveClass] = useState("");
  const [searchParam, setSearchParam] = useSearchParams();
  let code = searchParam.get("code") || "";
  searchParam.forEach((value, key) => {
    console.log(key, value);
  });
  const [total, setTotal] = useState(null);
  // const [loader, onShow, onHide] = FullPageLoader();
  const [showSimmer, setShowShimmer] = useState(false);
  let activePage = +searchParam.get("page") || 0;
  let serviceType = searchParam.get("serviceType") || "oneToOne";
  const [subscriptionType, setSubscriptionType] = useState("");
  const [subscriptionTypeModal, setSubscriptionTypeModal] = useState(false);
  const [zoomAuthModal, setZoomAuthModal] = useState(false);

  const [addServiceModal, setAddServiceModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [loaderCode, setLoaderCode] = useState(false);

  // For Setting Heading...
  const serviceData = [
    {
      image: on2onIcon2,
      name: "1:1 Consultations",
      value: "oneToOne",
    },
    {
      image: webinarIcon,
      name: "Webinars",
      value: "webinar",
    },
    {
      image: PriorityDMsIcon2,
      name: "Trainings",
      value: "subscription",
    },
    {
      image: crownIcon2,
      name: "Priority DM",
      value: "prioritydm",
    },
  ];

  // Select subscription type
  const subscriptionData = [
    {
      image: on2onIcon2,
      name: "Batch Trainings",
      value: "subscription",
    },
    {
      image: webinarIcon,
      name: "Recurring Trainings",
      value: "recurring",
    },
  ];

  // Create Zoom Account
  const createZoomAccount = async (getCode) => {
    try {
      setLoading(true);
      const response = await authorizeZoomAccountApi(getCode);
      if (response?.data?.code === 200) {
        const zoomUrlProd = `${response?.data?.data}`;
        if (typeof zoomUrlProd!=="object") {
          // Open the URL in a new tab
          window.open(zoomUrlProd);
        } else if (response?.data?.data?.isZoomAuth) {
          toastMessage(
            "zoom account connected successfully",
            "success",
            "connectZoomAccount"
          );
          setZoomAuthModal(false);
          // searchParam.delete("code");
          // setSearchParam(searchParam);
        }
      }
      // else {
      //   throw new Error(`Unexpected response code: ${response?.data?.code}`);
      // }
    } catch (e) {
      console.log(e.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  // Select Subscription Navigate Part Handler
  const subscriptionNavigateHandler = (data) => {
    switch (data?.name) {
      case "Batch Trainings":
        navigate(
          `/dashboard/manage-services/add-subscription-services/${
            data?.value
          }/${"oneTime"}`
        );
        break;
      case "Recurring Trainings":
        navigate(
          `/dashboard/manage-services/add-subscription-services/recurring/${"subscription"}/${
            data?.value
          }`
        );
        break;
      default:
        console.log("Invalid service name");
        break;
    }
  };

  const fetchingServicesData = async () => {
    try {
      // onShow();
      setShowShimmer(true);
      const sendData = {
        page: `${activePage}`,
        pageSize: "10",
        serviceType: serviceType,
      };

      setServicesList([]);
      const { data } = await getServicesApi(sendData);
      if (data?.code === 200) {
        setServicesList(data?.data || []);
        setTotal(data?.result?.total || 0);
      } else {
        setServicesList([]);
        setTotal(0);
        toastMessage(data.message, "error", "offersList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setShowShimmer(false);
    }
  };

  const serviceButtonHandler = (service) => {
    searchParam.set("serviceType", service);
    setSearchParam(searchParam);
  };

  // For setting active class in services
  const activeClassHandler = (data) => {
    setActiveClass(data);
  };

  // For setting navigate path handler
  const navigatePathHandler = (data) => {
    switch (data?.name) {
      case "1:1 Consultations":
        navigate(
          `/dashboard/manage-services/add-consultation-services/${data?.value}`
        );
        break;
      case "Webinars":
        navigate(
          `/dashboard/manage-services/add-webinar-services/${data?.value}`
        );
        break;
      case "Trainings":
        setAddServiceModal(false);
        setSubscriptionTypeModal(true);
        break;
      case "Priority DM":
        navigate(
          `/dashboard/manage-services/add-priority-services/${data?.value}`
        );
        break;
      default:
        console.log("Invalid service name");
        break;
    }
  };

  // console.log(loaderCode, "LoaderCode");
  useEffect(() => {
    if (code) {
      createZoomAccount(code);
    }
  }, [code]);

  useEffect(() => {
    fetchingServicesData();
  }, [searchParam]);
  return (
    <>
      <div className="manage_services">
        <div className="dashboard_title has_child justify-content-between">
          <h3>Manage Services</h3>

          <button className="button" onClick={() => setZoomAuthModal(true)}>
            Connect / Update Zoom Account
          </button>
        </div>
        <div className="dashboard_content_inner">
          <div className="tab_section flex">
            <ul>
              {serviceData &&
                serviceData.length &&
                serviceData.map((data, index) => {
                  return (
                    <li key={index}>
                      <button
                        type="button"
                        onClick={() => serviceButtonHandler(data?.value)}
                        className={`tab_button ${
                          serviceType === data.value ? "active" : ""
                        }`}
                      >
                        {data?.name}
                      </button>
                    </li>
                  );
                })}
            </ul>

            <Button
              className={"light has_button dashboard_button black"}
              onClick={() => setAddServiceModal(true)}
            >
              <IoMdAdd /> Add Services
            </Button>
          </div>

          <div className="tab_content">
            {serviceType === "oneToOne" && (
              <div className="card_wrapper">
                {servicesList && servicesList.length > 0 ? (
                  servicesList.map((item) => {
                    return (
                      <>
                        <ServicesCard
                          key={item?._id}
                          showSimmer={showSimmer}
                          serviceId={item?._id}
                          url={`/dashboard/manage-services/consultation/detail/${item.serviceType}/${item?._id}`}
                          isScheduled={item?.isScheduled}
                          image={item?.image}
                          duration={item?.duration}
                          title={item?.title}
                          description={item?.description}
                          pricing={item?.pricing}
                          serviceType={serviceType}
                          webinarAvailability={item?.webinarAvailability}
                          isHidden={item?.isHidden}
                          fetchingServicesData={fetchingServicesData}
                        />
                      </>
                    );
                  })
                ) : showSimmer ? (
                  Array.from({ length: 6 }).map((_, i) => (
                    <ServicesCard key={i} showSimmer={showSimmer} />
                  ))
                ) : (
                  <Placeholder text={"1:1 Consultations not found"} />
                )}
              </div>
            )}
            {serviceType === "webinar" && (
              <div className="card_wrapper">
                {servicesList && servicesList.length > 0 ? (
                  servicesList.map((item) => {
                    return (
                      <>
                        <ServicesCard
                          url={`/dashboard/manage-services/webinar/detail/${item.serviceType}/${item?._id}`}
                          key={item?.id}
                          showSimmer={showSimmer}
                          serviceId={item?._id}
                          image={item?.image}
                          duration={item?.duration}
                          title={item?.title}
                          description={item?.description}
                          pricing={item?.pricing}
                          serviceType={serviceType}
                          webinarAvailability={item?.webinarAvailability}
                          isHidden={item?.isHidden}
                          fetchingServicesData={fetchingServicesData}
                        />
                      </>
                    );
                  })
                ) : showSimmer ? (
                  Array.from({ length: 6 }).map((_, i) => (
                    <ServicesCard key={i} showSimmer={showSimmer} />
                  ))
                ) : (
                  <Placeholder text={"Webinar services not found"} />
                )}
              </div>
            )}

            {serviceType === "subscription" && (
              <div className="card_wrapper">
                {servicesList && servicesList.length > 0 ? (
                  servicesList.map((item) => {
                    return (
                      <>
                        <ServicesCard
                          url={`/dashboard/manage-services/subscription/detail/${item.serviceType}/${item?._id}/${item?.subscriptionType}`}
                          key={item?.id}
                          showSimmer={showSimmer}
                          serviceId={item?._id}
                          image={item?.image}
                          duration={item?.duration}
                          title={item?.title}
                          description={item?.description}
                          pricing={item?.pricing}
                          serviceType={serviceType}
                          isHidden={item?.isHidden}
                          fetchingServicesData={fetchingServicesData}
                        />
                      </>
                    );
                  })
                ) : showSimmer ? (
                  Array.from({ length: 6 }).map((_, i) => (
                    <ServicesCard key={i} showSimmer={showSimmer} />
                  ))
                ) : (
                  <Placeholder text={"Training services not found"} />
                )}
              </div>
            )}
            {serviceType === "prioritydm" && (
              <div className="card_wrapper">
                {servicesList && servicesList.length > 0 ? (
                  servicesList.map((item, index) => {
                    return (
                      <>
                        <ServicesCard
                          key={index}
                          showSimmer={showSimmer}
                          url={`/dashboard/manage-services/prioritydms/detail/${item.serviceType}/${item?._id}`}
                          image={item?.image}
                          serviceId={item?._id}
                          duration={item?.duration}
                          title={item?.title}
                          description={item?.description}
                          pricing={item?.pricing}
                          schedule={item?.schedule}
                          serviceType={serviceType}
                          promisedResponseTime={item?.responseTime}
                          isHidden={item?.isHidden}
                          fetchingServicesData={fetchingServicesData}
                        />
                      </>
                    );
                  })
                ) : showSimmer ? (
                  Array.from({ length: 6 }).map((_, i) => (
                    <ServicesCard key={i} showSimmer={showSimmer} />
                  ))
                ) : (
                  <Placeholder text={"Priority DM services not found"} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <CustomModal
        show={addServiceModal}
        handleClose={() => setAddServiceModal(false)}
        className={"addServiceModal"}
      >
        <h3>Select Service</h3>
        <p>Please select the category in which you like to add this service</p>
        <ul>
          {serviceData &&
            serviceData?.length &&
            serviceData.map((data, index) => {
              return (
                <li
                  key={index}
                  className={activeClass?.name === data?.name ? "active" : ""}
                  onClick={() => activeClassHandler(data)}
                >
                  <img src={data?.image} alt="" />
                  <span>{data?.name}</span>
                </li>
              );
            })}
        </ul>
        <div className="button_wrap button_center">
          <button
            className="button w-100"
            onClick={() => navigatePathHandler(activeClass)}
          >
            Next
          </button>
        </div>
      </CustomModal>

      {/* Select Subscription */}
      <CustomModal
        show={subscriptionTypeModal}
        handleClose={() => setSubscriptionTypeModal(false)}
        className={"addServiceModal"}
      >
        <h3>Select Subscription Modal</h3>
        <p>
          Please select the category in which you like to add this subscription
        </p>
        <ul>
          {subscriptionData &&
            subscriptionData?.length &&
            subscriptionData.map((data, index) => {
              return (
                <li
                  key={index}
                  className={activeClass?.name === data?.name ? "active" : ""}
                  onClick={() => activeClassHandler(data)}
                >
                  <img src={data?.image} alt="" />
                  <span>{data?.name}</span>
                </li>
              );
            })}
        </ul>
        <div className="button_wrap button_center">
          <button
            className="button w-100"
            onClick={() => subscriptionNavigateHandler(activeClass)}
          >
            Next
          </button>
        </div>
      </CustomModal>

      {/* Zoom Authentication Modal */}
      <CustomModal
        show={zoomAuthModal}
        handleClose={() => setZoomAuthModal(false)}
        className={"addServiceModal"}
      >
        <h3>Connect Your Zoom Account</h3>
        <p>
          To use Zoom for hosting or joining meetings, you need to create and
          link your Zoom account. Would you like to proceed and create your Zoom
          account now?
        </p>

        <div className="button_wrap button_center">
          <button
            className="button w-100"
            onClick={() => createZoomAccount("")}
          >
            Create
          </button>
        </div>
      </CustomModal>
    </>
  );
};

export default ManageServices;
