import {
  Button,
  Input,
  LoadingButton,
  Select,
  TextArea,
} from "components/form";
import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { faqList } from "staticData";
import "./CMS.scss";
import { useSearchParams } from "react-router-dom";
import { getFaqList, submitQueryApi } from "services/master";
import { toastMessage } from "utils/toastMessage";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import CommonPagination from "common/CommonPagination";
import { reasonData} from "utils/common";
import { regex } from "utils/formValidator";
const ExpertFaq = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  let page = +searchParam.get("page") || 1;
  const [faqList, setFaqList] = useState([]);
  const [submitQuery, setSubmitQuery] = useState({});
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(null);
  const [loader, onShow, onHide] = FullPageLoader();
  const [error, setError] = useState({});
  const { email, name } = regex;

  const fetchFaqList = async () => {
    try {
      const sendData = {};
      sendData.page = page - 1;
      sendData.pageSize = 10;
      onShow();
      const { data } = await getFaqList(sendData);
      if (data?.code === 200) {
        setFaqList(data?.data || []);
        setTotal(data?.totalItems || 0);
      } else {
        toastMessage(data?.message, "error", "faqList");
        setFaqList([]);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      onHide();
    }
  };

  // Validation for submit query
  const validation = () => {
    let errors = {};
    let status = true;
    if (!submitQuery?.name) {
      errors["name"] = "Name is mandatory";
      status = false;
    }
    // if (!submitQuery?.reason) {
    //   errors["reason"] = "Reason is mandatory";
    //   status = false;
    // }
    if (!submitQuery?.email) {
      errors["email"] = "Email is mandatory";
      status = false;
    } else if (!submitQuery.email.match(email)) {
      errors["email"] = "Please enter valid email";
      errors["emailErr"] = true;
      status = false;
    }
    if (!submitQuery?.type) {
      errors["type"] = "Type is mandatory";
      status = false;
    }
    if (!submitQuery?.message) {
      errors["message"] = "Message is mandatory";
      status = false;
    }
    setError(errors);
    return status;
  };

  // Submit Query Handler
  const submitQueryHandler = async (e) => {
    e.preventDefault();
    if (validation()) {
      try {
        setLoading(true);
        const { data } = await submitQueryApi({ ...submitQuery, type: submitQuery?.type });
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "submitQuery");
          setSubmitQuery("");
        } else {
          toastMessage(data?.message, "error", "submitQuery");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  // Handle Page Change
  const handlePageChange = (event) => {
    if (Number(event) === 1) {
      searchParam.delete("page");
    } else {
      searchParam.set("page", event);
    }
    setSearchParam(searchParam);
  };

  useEffect(() => {
    fetchFaqList();
  }, []);

  return (
    <>
      {/* {loader} */}
      <div className="cms_content">
        <div className="dashboard_title">
          <h3>Frequently Asked Questions</h3>
        </div>
        <div className="dashboard_content_inner faq_wrap">
          <div className="faq">
            <h4>People often ask: Frequently Asked Questions</h4>
            <Accordion defaultActiveKey="0">
              {faqList &&
                faqList.length > 0 &&
                faqList.map((item) => {
                  return (
                    <Accordion.Item eventKey={item._id} key={item?._id}>
                      <Accordion.Header>
                        {item?.question || "---"}
                      </Accordion.Header>
                      <Accordion.Body>{item?.answer || "---"}</Accordion.Body>
                    </Accordion.Item>
                  );
                })}
            </Accordion>
            <CommonPagination
              activePage={page}
              total={total}
              handlePageChange={handlePageChange}
            />
          </div>
          <form>
            <h4>Let’s Talk</h4>
            {/* <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.{" "}
            </p> */}

            <div className="form_field_wrap">
              <div className="form_field">
                <Input
                  type="text"
                  label="Name"
                  placeholder="Your Name"
                  name="name"
                  value={submitQuery?.name || ""}
                  onChange={(e) => {
                    setSubmitQuery({ ...submitQuery, name: e.target.value });
                  }}
                  error={error?.name}
                />
              </div>
              <div className="form_field">
                <Input
                  type="email"
                  label="Email"
                  placeholder="Your Email"
                  name="email"
                  value={submitQuery?.email || ""}
                  onChange={(e) => {
                    setSubmitQuery({ ...submitQuery, email: e.target.value });
                  }}
                  error={error?.email}
                  extraError={error?.emailErr}
                />
              </div>
            </div>
            <div className="form_field">
              <Select
                label="Type"
                name="type"
                value={submitQuery?.type || ""}
                onChange={(e) => {
                  setSubmitQuery({ ...submitQuery, type: e.target.value });
                }}
                error={error?.type}
              >
                <option value="">Select Type</option>
                {reasonData.map((data) => (
                  <option value={data?.value} key={data?.value}>
                    {data?.name}
                  </option>
                ))}
              </Select>
            </div>
            <div className="form_field">
              <TextArea
                label=""
                placeholder="Your Message"
                name="message"
                value={submitQuery?.message || ""}
                onChange={(e) => {
                  setSubmitQuery({ ...submitQuery, message: e.target.value });
                }}
                error={error?.message}
              />
            </div>
            <div className="button_wrap">
              <LoadingButton
                loading={loading}
                onClick={submitQueryHandler}
                className="w-100"
              >
                Send Message
              </LoadingButton>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ExpertFaq;
