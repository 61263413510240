import React from "react";
import "./Aboutus.scss";
import {
  aboutUsBannerImg,
  aboutUsLogo,
  bannerBg,
  founderImg,
  personalADImg,
  webinarImg,
} from "assets/images";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";

const AboutUs = () => {
  return (
    <>
      <section className="aboutUs home_page">
        <div className="home_banner">
          <div className="container">
            <img src={aboutUsBannerImg} alt="" className="bgImage" />
            <div className="content">
              <span className="tag">About Us</span>
              <h1>The Ultimate Platform for Fitness Entrepreneurs</h1>
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </p> */}
            </div>
          </div>
        </div>

        <section className="expert_webinars spacing">
          <div className="container">
            <div className="content">
              <div className="page_title">
                <span className="_tag">About Fitzure</span>
                <p>
                  At <b>Fitzure</b>, we empower fitness professionals and
                  influencers to take their careers to the next level. Our
                  platform is designed to simplify the process of managing
                  services, booking clients, and processing payments—allowing
                  you to focus on what you do best: inspiring others to live
                  healthier lives. Our mission is to provide fitness creators
                  with the tools they need to turn their passion into a
                  business, offering everything from personal training and
                  consultations to digital courses and exclusive messaging
                  services.
                </p>
              </div>
            </div>
            <div className="image_wrap">
              <img src={aboutUsLogo} alt="" />
            </div>
          </div>
        </section>

        <section className="expert_webinars odd spacing">
          <div className="container">
            <div className="content">
              <div className="page_title">
                <span className="_tag">Meet Our Founder</span>
                {/* <h3>Get a personal advice on 1-to-1 video call with Sarah</h3> */}
              </div>

              <p>
                <b>Deepak Singh Bisht </b> is the visionary behind Fitzure, a
                platform designed to empower fitness professionals and
                influencers to turn their passion into a successful business. A
                B.Tech Computer Science Graduate with over 8 years of experience
                in Digital Marketing and 6 years in Indian & International
                eCommerce, Deepak has a strong foundation in both technology and
                business.
              </p>
              <p>
                Recognized for his achievements, Deepak was awarded by the IT
                Minister for winning the Digital India Quiz (Nov 2016). He has
                also won several Inter-College and State-Level Startup Pitching
                and Quiz Competitions, reflecting his entrepreneurial spirit and
                commitment to innovation.
              </p>
              <p>
                Deepak’s passion for fitness and his deep understanding of the
                challenges faced by fitness professionals inspired him to create
                Fitzure. His vision is to provide a platform where fitness
                creators can manage their business effortlessly and focus on
                what matters most—transforming lives through fitness.
              </p>
            </div>
            <div className="image_wrap">
              <img src={founderImg} alt="" />
              <h5>
                <span>Deepak Singh Bisht</span> <br />{" "}
                <b>Founder & CEO - Fitzure</b>
              </h5>
              <ul className="socialIcons">
                <li>
                  <Link to="https://www.instagram.com/enpdeepak/">
                    <FaInstagram />
                  </Link>
                </li>
                <li>
                  <Link to="https://www.facebook.com/enpdeepak/">
                    <FaFacebookF />
                  </Link>
                </li>
                <li>
                  <Link to="https://www.linkedin.com/in/enpdeepak">
                    <FaLinkedinIn />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default AboutUs;
