import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Input,
  LoadingButton,
  Select,
  TextArea,
} from "components/form";
import { IoCloudUploadOutline } from "react-icons/io5";
import { FaTimes } from "react-icons/fa";
import { BsCurrencyRupee } from "react-icons/bs";
import { testimonial } from "staticData";
import CustomModal from "components/modals/CustomModal";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import "components/cards/servicesCard/ServicesCard.scss";
import { RiEdit2Line } from "react-icons/ri";
import { LiaTimesSolid } from "react-icons/lia";
import { IoMdAdd } from "react-icons/io";
import JoditEditor from "jodit-react";
import { allowNumber, createArrayUpTo, getFileExtension, regex } from "utils/formValidator";
import commonMessages from "utils/commonMessages";
import { toastMessage } from "utils/toastMessage";
import { getTestimonials } from "services/master";
import GetTestimonials from "../manageServices/GetTestimonials";
import Placeholder from "components/placeholder/Placeholder";
import { useNavigate, useParams } from "react-router-dom";
import {
  addServicesApi,
  getServicesDetailApi,
  updateDigitalProductApi,
  updateServicesApi,
} from "services/expertServices/manage";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import AddChapter from "./AddChapter";
import EditChapter from "./EditChapter";
import { sendArrayDataId } from "utils/mediaDataSender";
import CoverImageCropper from "../manageServices/CoverImageCropper";

const EditDigitalProduct = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const { serviceType, serviceId, digitalType } = useParams();
  const [loader, onShow, onHide] = FullPageLoader();
  const [pricing, setPricing] = useState({});
  const [addChapters, setAddChapters] = useState([]);
  const [addTopic, setAddTopic] = useState([]);
  const [active, setActive] = useState("yes");
  const [inputField, setInputField] = useState({});
  const [image, setImage] = useState("");
  const [error, setError] = useState({});
  const { imageFileRegex, documentFileRegex } = regex;
  const { IMAGE_FORMAT } = commonMessages;
  const [modal, setModal] = useState(false);
  const [testimonials, setTestimonials] = useState([]);
  const [bestTestimonials, setBestTestimonials] = useState([]);
  const [showTestimonialModal, setShowTestimonilModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [addChapterModal, setAddChapterModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [add, setAdd] = useState({
    id: Date.now(),
    name: "",
    expert: JSON.parse(localStorage.getItem("fitzure_info"))._id,
    topics: [
      {
        _id: Date.now(),
        name: "",
        uploadType: "",
        topicsFiles: "",
        topicVIdeoLinks: "",
      },
    ],
  });
  const [removeChapter, setRemoveChapter] = useState([]);

  const fetchingServiceDetailHandler = async () => {
    try {
      onShow();
      const { data } = await getServicesDetailApi(serviceId);
      if (data?.code === 200) {
        setInputField({
          ...data?.data,
          time: new Date(data?.data?.webinarAvailability),
        });
        setImage(data?.data?.image);
        setPricing(
          {
            ...data?.data?.pricing,
            showSlashedAmount: data?.data?.pricing?.showSlashedAmount
              ? "yes"
              : "no",
          } || {}
        );
        setAddChapters(
          data?.data?.chapters.map((chapter) => ({
            ...chapter,
            topics: chapter.topics.map((topic) => {
              if (topic.uploadType === "video") {
                return {
                  ...topic,
                  topicVIdeoLinks: topic.url,
                  url: undefined, // Optionally, remove the old key
                };
              } else if (topic.uploadType === "file") {
                return {
                  ...topic,
                  topicsFiles: topic.url,
                  url: undefined, // Optionally, remove the old key
                };
              } else {
                return topic;
              }
            }),
          })) || []
        );
        setBestTestimonials(data?.data?.testimonials || []);
      } else {
        toastMessage(data?.message, "error", "ServiceDetail");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  useEffect(() => {
    fetchingServiceDetailHandler();
  }, []);

  //   Fetching Testimonial Handler
  const fetchingTestimonialHandler = async () => {
    try {
      setTestimonials([]);
      const sendData = {
        serviceType: "digital",
        page: 0,
        pageSize: 10,
      };
      const { data } = await getTestimonials(sendData);
      if (data?.code === 200) {
        setTestimonials(data?.data);
      } else {
        setTestimonials([]);
        toastMessage(data?.message, "error", "GetTestimonials");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImagePreview(file);
    e.target.value = null;
  };

  const deleteImg = (e) => {
    e.target.value = null;
    setImagePreview(null);
  };


  const noOfChapters=createArrayUpTo(71);


  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false,
      placeholer: "",
      toolbar: true,
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      uploader: {
        insertImageAsBase64URI: true,
      },
      controls: {
        font: {
          command: "fontname",
          list: {
            "'Open Sans',sans-serif": "Open Sans",
            "Helvetica,sans-serif": "Helvetica",
            "Arial,Helvetica,sans-serif": "Arial",
            "Georgia,serif": "Georgia",
            "Impact,Charcoal,sans-serif": "Impact",
            "Tahoma,Geneva,sans-serif": "Tahoma",
            "'Times New Roman',Times,serif": "Times New Roman",
            "Verdana,Geneva,sans-serif": "Verdana",
            "Consolas,monaco,monospace": "Consolas",
          },
        },
      },
    }),
    []
  );

  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        setImage(e.target.files[0]);
      } else {
        e.target.value = "";
        setImage("");
        toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      }
    }
  };

  const inputFieldHandler = (e) => {
    const { name, value } = e.target;

    if (name === "video") {
      setInputField({ ...inputField, [name]: value });
    } else if (name === "showSlashedAmount") {
      setInputField({ ...inputField, [name]: value, slashAmount: "" });
    } else if (name === "amount" || name === "slashAmount") {
      setInputField({ ...inputField, [name]: allowNumber(value) });
    } else {
      setInputField({ ...inputField, [name]: value });
    }
  };

  // Pricing Handler
  const pricingHandler = (e) => {
    const { name, value } = e.target;
    if (name === "showSlashedAmount") {
      setPricing({ ...pricing, [name]: value, slashAmount: "" });
    } else setPricing({ ...pricing, [name]: allowNumber(value) });
  };

  // Handle Chapter Change
  const handleChapterChange = (field, e) => {
    const { name, value } = e.target;
    setAdd({ ...add, [field]: value });
  };

  const handleChange = (field, e, topicIndex) => {
    const { value } = e.target;
    if (field === "uploadType") {
      setAdd((prevChapters) => {
        const newChapters = { ...prevChapters };
        const topic = newChapters?.topics[topicIndex];
        let newTopic = {
          ...topic,
          [field]: value,
          ["topicsFiles"]: "",
          ["topicVIdeoLinks"]: "",
        };
        newChapters.topics[topicIndex] = newTopic;
        return newChapters;
      });
    } else {
      setAdd((prevChapters) => {
        const newChapters = { ...prevChapters };
        const topic = newChapters?.topics[topicIndex];
        let newTopic = { ...topic, [field]: value };
        newChapters.topics[topicIndex] = newTopic;
        return newChapters;
      });
    }
  };

  //   Handle Document
  const handleDocument = (field, e, topicIndex) => {

    const { files } = e.target;

    if (files.length === 0) return;

    const file = files[0];
    const isValidFile = documentFileRegex.test(file.name);

    setAdd((prevChapters) => {
      const newChapters = { ...prevChapters };
      const topic = { ...newChapters.topics[topicIndex] };

      if (isValidFile) {
        topic[field] = file;
      } else {
        e.target.value = "";
        topic[field] = "";
        toastMessage("Please upload pdf, txt, or doc", "error", "fileFormat");
      }

      newChapters.topics[topicIndex] = topic;
      return newChapters;
    });
  };

  //   Handle selected Data
  const selectedDataHandler = (data) => {
    setSelectedData(data);
    setEditModal(true);
  };

  //   useEffect(() => {
  //     const currentChaptersLength = addChapters.length;
  //     const newChaptersLength = inputField.noOfChapters;

  //     if (newChaptersLength > currentChaptersLength) {
  //       const newChapters = Array.from(
  //         { length: newChaptersLength - currentChaptersLength },
  //         (_, index) => ({
  //           id: currentChaptersLength + index + 1,
  //           name: "",
  //           expert: JSON.parse(localStorage.getItem("fitzure_info"))._id,
  //           topics: [
  //             {
  //               topicId: Date.now(),
  //               name: "",
  //               uploadType: "",
  //               topicsFiles: "",
  //               topicVIdeoLinks: "",
  //             },
  //           ],
  //         })
  //       );
  //       setAddChapters((prevChapters) => [...prevChapters, ...newChapters]);
  //     } else if (newChaptersLength < currentChaptersLength) {
  //       setAddChapters((prevChapters) =>
  //         prevChapters.slice(0, newChaptersLength)
  //       );
  //     }
  //   }, [inputField.noOfChapters]);

  //   Add Topic
  const addTopicHandler = () => {
    setAdd((prev) => {
      const updatedChapter = { ...prev };
      updatedChapter.topics = [
        ...updatedChapter.topics,
        {
          topicId: Date.now(),
          name: "",
          uploadType: "",
          topicsFiles: "",
          topicVIdeoLinks: "",
        },
      ];

      return updatedChapter;
    });
  };

  //   Delete Topic
  const deleteTopicHandler = (topicIndex) => {
    setAdd((prev) => {
      const updatedChapters = { ...prev };
      const updatedTopics = [...updatedChapters.topics];
      updatedTopics.splice(topicIndex, 1);
      updatedChapters.topics = updatedTopics;

      return updatedChapters;
    });
  };

  //   Remove Chapter Handler
  const removeChapterHandler = (id) => {
    setAddChapters((prevChapters) => {
      return prevChapters.filter((data) => data?._id !== id);
    });
    setRemoveChapter((prevRemoveChapter) => [...prevRemoveChapter, id]);
  };

  //   For Adding CHapter in update digital Product
  const updateChapterHandler = async () => {
    const topicVIdeoLinks = add?.topics
      .filter((topic) => topic.topicVIdeoLinks)
      .map((topic) => topic.topicVIdeoLinks);

    // For getting indexes of url in chapters
    const topicVideoIndexes = add?.topics.reduce((acc, data, index) => {
      if (data?.topicVIdeoLinks) {
        acc.push(index);
      }
      return acc;
    }, []);

    const topicsFiles = add?.topics
      .map((topic) => topic.topicsFiles)
      .filter((file) => file?.name);

    const topics = add?.topics.map((data) => ({
      name: data?.name,
      uploadType: data?.uploadType,
    }));
    try {
      setLoading(true)
      const formData = new FormData();
      formData.append("topics", JSON.stringify(topics));
      formData.append("topicVIdeoLinks", JSON.stringify(topicVIdeoLinks));
      formData.append("topicVideoIndexes", JSON.stringify(topicVideoIndexes));
      if (topicsFiles && topicsFiles.length > 0) {
        topicsFiles.forEach((data, index) => {
          formData.append("topicsFiles", data);
        });
      }
      formData.append("chapterName", add?.name || "");
      formData.append("serviceId", serviceId || "");
      const { data } = await updateDigitalProductApi(formData);
      if (data?.code === 200) {
        toastMessage(data?.message, "success", "updateDigitalProduct");
        fetchingServiceDetailHandler();
        setError({});
        setAdd({});
        setAddChapterModal(false);
      } else {
        toastMessage(data?.message, "error", "updateDigitalOriduct");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  // Complete Update Digital Product
  const updateServiceHandler = async () => {
    // if (validation()) {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("_id", serviceId);
      if (image && image?.name) {
        formData.append("image", image);
      }
      formData.append(
        "pricing",
        JSON.stringify({
          ...pricing,
          showSlashedAmount:
            pricing?.showSlashedAmount === "yes" ? true : false,
        })
      );

      formData.append("description", inputField?.description);
      if (removeChapter && removeChapter.length) {
        formData.append("chaptersToRemove", JSON.stringify(removeChapter));
      }
      formData.append("digitalContentType", digitalType);
      formData.append("noOfChapters", addChapters.length || "---");
      if (inputField?.video) {
        formData.append("video", inputField?.video);
      } else {
        formData.append("video", "");
      }
      formData.append("title", inputField?.title);
      formData.append("serviceType", serviceType);
      if (bestTestimonials && bestTestimonials.length > 0) {
        const selectedTestimonials = sendArrayDataId(bestTestimonials);
        formData.append("testimonials", JSON.stringify(selectedTestimonials));
      } else {
        formData.append("testimonials", `[]`);
      }
      const { data } = await updateServicesApi(formData);
      if (data?.code === 200) {
        toastMessage(data?.message, "success", "updateDigitalProduct");
        setError({});
        navigate("/dashboard/digital-contents");
      } else {
        toastMessage(data?.message, "error", "updateDigitalProduct");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
    // }
  };

  useEffect(() => {
    if (showTestimonialModal) {
      fetchingTestimonialHandler();
    }
  }, [showTestimonialModal]);

  //   console.log(removeChapter, "RemoveChapter");

  return (
    <>
      <div className="edit_digital_content">
        <div className="dashboard_title">
          <h3>Edit Digital Course</h3>
        </div>

        <div className="dashboard_content_inner">
          <div className="add_services_from">
            <form>
              <div className="content_left">
                <div className="form_field">
                  <Input
                    label={"Title"}
                    name="title"
                    value={inputField?.title || ""}
                    placeholder={"Enter Title"}
                    onChange={inputFieldHandler}
                    maxlength="100"
                    error={error?.title}
                  />
                </div>
                <div className="form_field">
                <label className="label">Description</label>
                  <JoditEditor
                    ref={editor}
                    value={inputField?.description}
                    tabIndex={1}
                    onBlur={(value) =>
                      setInputField({ ...inputField, description: value })
                    }
                    config={config}
                  />
                  <p className="invalid-feedback d-block">
                    {error?.description}
                  </p>
                </div>
                <div className="form_field">
                  <Input
                    label={"Overview Video Link"}
                    name="video"
                    value={inputField?.video || ""}
                    placeholder={"Enter url"}
                    onChange={inputFieldHandler}
                    error={error?.video}
                    extraError={error?.videoErr}
                  />
                </div>
              </div>
              <div className="content_right">
                <div className="form_field">
                  <Select
                    label={"No. Of Chapters"}
                    name="noOfChapters"
                    value={addChapters.length || ""}
                    onChange={inputFieldHandler}
                    // error={error?.noOfChapters}
                    disabled
                  >
                    <option value="">Select No. Of Chapters</option>
                    {noOfChapters &&
                      noOfChapters.length &&
                      noOfChapters.map((data) => (
                        <option value={data}>{data}</option>
                      ))}
                  </Select>
                </div>

                {/* <div className="form_field position-relative">
                  <label className="label d-block">Upload Cover Image</label>
                  {imagePreview && (
                    <span className="delete_button" onClick={deleteImg}>
                      <FaTimes />
                    </span>
                  )}
                  <label className="imagePreview" htmlFor="coverImage">
                    <input
                      type="file"
                      name="image"
                      id="coverImage"
                      onChange={imageHandler}
                      error={error?.image}
                    />
                    {image ? (
                      <>
                        <img
                          src={
                            image && image?.name
                              ? URL.createObjectURL(image)
                              : image
                          }
                          alt=""
                        />
                      </>
                    ) : (
                      <div className="content">
                        <IoCloudUploadOutline />
                        <label className="button" htmlFor="coverImage">
                          Browse
                        </label>
                        <span>drop a file here</span>
                        <p>*File supported .png, .jpg, .webp</p>
                      </div>
                    )}
                  </label>
                </div>

                {error.image && (
                  <span className="invalid-feedback d-block">
                    {error?.image}
                  </span>
                )} */}

<CoverImageCropper
                  imageSelect={(img) => {
                    setImage(img);
                  }}
                  prevImage={image}
                  error={error?.image}
                />

              </div>
            </form>
            <div className="pricing">
              <h5>Pricing</h5>
              <div className="pricing_content">
                <div className="form_field">
                  <label className="label">Enter Amount</label>
                  <div className="input_wrap">
                    <Input
                      type={"text"}
                      name="amount"
                      value={pricing?.amount || ""}
                      placeholder="Enter Amount"
                      onChange={pricingHandler}
                      maxlength="6"
                      error={error?.amount}
                    />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>

                <div className="form_field">
                  <label className="label">Show Slash Pricing</label>
                  <div className="radio_button_wrap">
                    <label
                      htmlFor="yes"
                      className={`radio_button input ${
                        pricing?.showSlashedAmount == "yes" ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name="showSlashedAmount"
                        id="yes"
                        value="yes"
                        checked={pricing?.showSlashedAmount == "yes"}
                        onChange={pricingHandler}
                      />
                      <span>Yes</span>
                    </label>
                    <label
                      htmlFor="no"
                      className={`radio_button input ${
                        pricing?.showSlashedAmount == "no" ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name="showSlashedAmount"
                        id="no"
                        value="no"
                        checked={pricing?.showSlashedAmount == "no"}
                        onChange={pricingHandler}
                      />
                      <span>No</span>
                    </label>
                  </div>
                </div>

                <div className="form_field">
                  <label className="label">Actual amount</label>
                  <div className="input_wrap">
                    <Input
                      type={"text"}
                      disabled
                      name="amount"
                      value={pricing?.amount || 0}
                      placeholder="Enter Amount"
                      onChange={pricingHandler}
                      maxlength="6"
                    />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Slashed amount</label>
                  <div className="input_wrap">
                    <Input
                      type={"text"}
                      placeholder="Enter Amount"
                      disabled={pricing?.showSlashedAmount == "no"}
                      name="slashAmount"
                      value={pricing?.slashAmount || ""}
                      onChange={pricingHandler}
                      maxlength="6"
                      error={error?.slashAmount}
                      extraError={error?.slashAmountErr}
                    />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Display Price</label>
                  <div className="display_price input">
                  {!pricing?.showSlashedAmount ? (
                      <span>
                        {pricing?.amount
                          ? Number(pricing?.amount).toFixed(2)
                          : 0}
                      </span>
                    ) : (
                      <>
                        {" "}
                        <del>
                          <BsCurrencyRupee />{" "}
                          {pricing?.slashAmount ? Number(pricing?.slashAmount) : 0}
                        </del>
                        <span>
                          {pricing?.amount
                            ? (
                                Number(pricing?.amount) 
                                // -
                                // Number(pricing?.slashAmount || 0)
                              ).toFixed(2)
                            : 0}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="add_chapters spacing">
              <h5>Add Chapters</h5>
              <div className="button_wrap button_right">
                <Button
                  className={"light dashboard_button"}
                  onClick={() => setAddChapterModal(true)}
                >
                  <IoMdAdd /> Add Chapters
                </Button>
              </div>
              {/* {addChapters && addChapters.length > 0 ? (
                addChapters.map((data, index) => {
                  return (
                    <>
                 
                      <div className="form_field">
                        <Input
                          type="text"
                          label="Chapters Name"
                          name="name"
                          placeholder="Content management"
                          value={data?.name}
                          onChange={(e) =>
                            handleChapterChange(data?.id, "name", e)
                          }
                        />
                      </div>
                      {data?.topics && data?.topics.length > 0
                        ? data?.topics.map((topic, topicIndex) => {
                            return (
                              <>
                                <div className="form_field_wrap">
                                  <div className="form_field">
                                    <Input
                                      type="text"
                                      label="Topic Name"
                                      placeholder="Design Review"
                                      name="name"
                                      value={topic?.name}
                                      onChange={(e) =>
                                        handleChange(
                                          "name",
                                          index,
                                          e,
                                          topicIndex
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="form_field">
                                    <Select
                                      label="Upload Option"
                                      name="uploadType"
                                      value={topic?.uploadType}
                                      onChange={(e) =>
                                        handleChange(
                                          "uploadType",
                                          index,
                                          e,
                                          topicIndex
                                        )
                                      }
                                    >
                                      <option value="">
                                        Select Upload Type
                                      </option>
                                      <option value="file">Document</option>
                                      <option value="video">Link</option>
                                    </Select>
                                  </div>
                                </div>

                                <div className="form_field_wrap">
                                  {topic?.uploadType &&
                                    topic?.uploadType === "video" && (
                                      <div className="form_field">
                                        <Input
                                          type="url"
                                          label="Video Link"
                                          placeholder="Enter url"
                                          name="topicVIdeoLinks"
                                          value={topic?.topicVIdeoLinks}
                                          onChange={(e) =>
                                            handleChange(
                                              "topicVIdeoLinks",
                                              index,
                                              e,
                                              topicIndex
                                            )
                                          }
                                        />
                                      </div>
                                    )}{" "}
                                  {topic?.uploadType &&
                                    topic?.uploadType === "file" && (
                                      <>
                                        <label className="label d-block">
                                          Upload Document
                                        </label>
                                        <label
                                          className="imagePreview"
                                          htmlFor={`fileUpload-${index}-${topicIndex}`}
                                        >
                                          <input
                                            type="file"
                                            name="topicsFiles"
                                            id={`fileUpload-${index}-${topicIndex}`}
                                            onChange={(e) =>
                                              handleDocument(
                                                "topicsFiles",
                                                index,
                                                e,
                                                topicIndex
                                              )
                                            }
                                            
                                          />
                                          {topic?.topicsFiles ? (
                                            <>
                                              {topic?.topicsFiles &&
                                                topic?.topicsFiles?.name &&
                                                getFileExtension(
                                                  topic?.topicsFiles.name
                                                )}
                                            </>
                                          ) : (
                                            <div className="content">
                                              <IoCloudUploadOutline />
                                              <label
                                                className="button"
                                                htmlFor={`fileUpload-${index}-${topicIndex}`}
                                              >
                                                Browse
                                              </label>
                                              <span>drop a file here</span>
                                              <p>
                                                *File supported .pdf & .word &
                                                .txt
                                              </p>
                                            </div>
                                          )}
                                        </label>
                                      </>
                                    )}
                                  <div className="form_field">
                                    <div className="button_wrap button_right">
                                 

                                      <Button
                                        className={"light dashboard_button red"}
                                        onClick={() =>
                                          deleteTopicHandler(
                                            data?.id,
                                            topic?.id,
                                            index,
                                            topicIndex
                                          )
                                        }
                                      >
                                        Delete Topic
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })
                        : ""}
                    </>
                  );
                })
              ) : (
                <Placeholder text="Select No . Of Chapter" />
              )} */}
            </div>

            <div className="chapter_list spacing">
              <h5>Chapters List</h5>
              <ul>
                {addChapters && addChapters.length ? (
                  addChapters.map((data, chapterIndex) => (
                    <li>
                      <p>
                        <b>
                          Chapter {++chapterIndex} : {data?.name || "---"}
                        </b>
                        <span>
                          Total Topic :{" "}
                          {(data && data?.topics && data.topics.length) ||
                            "---"}
                        </span>
                      </p>
                      <div className="action">
                        <button type="button">
                          <RiEdit2Line
                            onClick={() => selectedDataHandler(data)}
                          />
                        </button>
                        <button type="button">
                          <LiaTimesSolid
                            onClick={() => removeChapterHandler(data?._id)}
                          />
                        </button>
                      </div>
                    </li>
                  ))
                ) : (
                  <Placeholder text="No Chapetrs Available" />
                )}
              </ul>
            </div>
            {/* <div className="table_radius spacing">
              <div class="_sub_title">
                <h4>
                  Display Testimonials for this service
                  <small>
                    Select the best testimonials that you want to display for
                    this service
                  </small>
                </h4>
                <Button
                  className={"dashboard_button"}
                  onClick={() => setShowTestimonilModal(true)}
                >
                  Select Testimonials
                </Button>
              </div>
              <div className="content">
                <div className="card_wrap">
                  {bestTestimonials && bestTestimonials.length ? (
                    <TestimonialCard
                      bestTestimonials={bestTestimonials}
                      setBestTestimonials={setBestTestimonials}
                    />
                  ) : (
                    <Placeholder text={"No Testimonial Found"} />
                  )}
                </div>
              </div>
            </div> */}
            <div className="button_wrap button_right">
              <LoadingButton loading={loading} onClick={updateServiceHandler}>
                Save
              </LoadingButton>
            </div>
          </div>

          <GetTestimonials
            testimonial={testimonials}
            setTestimonial={setTestimonials}
            showTestimonialModal={showTestimonialModal}
            setShowTestimonilModal={setShowTestimonilModal}
            bestTestimonials={bestTestimonials}
            setBestTestimonials={setBestTestimonials}
          />

          {addChapterModal && (
            <AddChapter
              addTopicHandler={addTopicHandler}
              add={add}
              setAdd={setAdd}
              setAddChapterModal={setAddChapterModal}
              addChapterModal={addChapterModal}
              handleChange={handleChange}
              handleDocument={handleDocument}
              handleChapterChange={handleChapterChange}
              deleteTopicHandler={deleteTopicHandler}
              updateChapterHandler={updateChapterHandler}
              
              loading={loading}
            />
          )}
          {editModal && (
            <EditChapter
              //   addTopicHandler={addTopicHandler}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              setEditModal={setEditModal}
              editModal={editModal}
              //   handleChange={handleChange}
              //   handleDocument={handleDocument}
              //   handleChapterChange={handleChapterChange}
              //   deleteTopicHandler={deleteTopicHandler}
              //   updateChapterHandler={updateChapterHandler}
              loading={loading}
              serviceId={serviceId}
              fetchingServiceDetailHandler={fetchingServiceDetailHandler}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EditDigitalProduct;
