import {
  badgesBooking,
  badgesRating,
  expert1Img,
  expertPlaceholderImg,
  service1,
  top10Icon,
  top1Icon,
  top5Icon,
  topInIcon,
  videoPoster,
} from "assets/images";
import { RatingStar } from "components/form";
import "../ExploreExperts.scss";
import React, { Fragment, useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import { calenderIcon } from "assets/icons";

// import VideoPlayer from "components/video-player/VideoPlayer";
import {
  getAvailaleServicesApi,
  getExpertDetailApi,
  getExpertServicesApi,
  getSelectedExpertTestimonialsApi,
} from "services/userServices/expertDetails";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { toastMessage } from "utils/toastMessage";
import VideoPlayer from "common/YouTubeVideoPlayer";
import { socialIcons } from "utils/staticDropdown";
import { dateFormat, dateFormatWithTime } from "utils/dateFormat";
import CommonPagination from "common/CommonPagination";
import Skeleton from "react-loading-skeleton";
import { charLimit } from "utils/mediaDataSender";
import Placeholder from "components/placeholder/Placeholder";
import { MdOutlineContentCopy } from "react-icons/md";
import InfiniteScroll from "react-infinite-scroll-component";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import { useDispatch, useSelector } from "react-redux";
import { setPixelInfo } from "store/slices/pixelSlice";
import AddTags from "config/AddTags";
import ReactGA from "react-ga4";
import GoogleAds from "config/GoogleAds";
import { IoMdStar } from "react-icons/io";

const ExpertDetail = () => {
  const [expertServiceDetail, setExpertServiceDetail] = useState([]);
  const [availableService, setAvailableService] = useState([]);
  const [expertDetail, setExpertDetail] = useState({});
  const [expertSocialLinks, setExpertSocialLinks] = useState([]);
  const [total, setTotal] = useState(null);
  const [loader, onShow, onHide] = FullPageLoader();
  const [searchParam, setSearchParam] = useSearchParams();
  const { _id } = useParams();
  let serviceType = searchParam.get("serviceType") || "";
  // let page = +searchParam.get("page") || 1;
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [testimonialsList, setTestimonialsList] = useState([]);
  const location = useLocation();

  const fetchingExpertDetail = async () => {
    try {
      onShow();
      const { data } = await getExpertDetailApi({ expert: _id });
      if (data?.code === 200 && data?.message !== "Expert not found") {
        setExpertDetail(data?.data || {});
        setExpertSocialLinks(
          data?.data?.socialLinks.reduce((acc, data) => {
            if (data?.name in socialIcons) {
              acc.push({ ...data, logo: socialIcons[data.name] });
            }
            return acc;
          }, [])
        );

        window?.fbq("init", data?.data?.facebookPixel);
        window?.fbq("track", "PageView");
        if (data?.data?.googleTagManager) {
          ReactGA.initialize(data.data.googleTagManager);
          ReactGA.send({
            hitType: "pageview",
            page: location.pathname,
            title: "",
          });
        }

        window.gtag("event", "conversion", {
          send_to: `${data?.data?.googlePixel}/${data?.data?.googleLabel}`,
        });
      } else if (data?.message === "Expert not found") {
        toastMessage(data?.message, "error", "ServiceDetail");
        navigate("/");
      } else {
        toastMessage(data?.message, "error", "ServiceDetail");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  const fetchingExpertServiceDetail = async (reset = true) => {
    if (expertDetail?._id) {
      try {
        onShow();
        const sendData = {};
        sendData.id = expertDetail?._id;
        // sendData.page = page - 1;
        sendData.page = page;
        sendData.pageSize = 10;
        sendData.serviceType = serviceType;
        const { data } = await getExpertServicesApi(sendData);
        if (data?.code === 200) {
          setTotal(data?.totalItems);
          // setExpertServiceDetail(data?.data || []);

          if (reset) {
            setExpertServiceDetail(data?.data || []);
          } else {
            setExpertServiceDetail((prev) => [...prev, ...(data?.data || [])]);
          }
        } else {
          toastMessage(data?.message, "error", "ServiceDetail");
        }
      } catch (e) {
        console.log(e.message || "something went wrong");
      } finally {
        onHide();
      }
    }
  };

  // Fetching Testimonials Of Particular Expert
  const fetchExpertTestimonialsList = async () => {
    try {
      let sendData = {};
      sendData.pageSize = 100;
      sendData.serviceType = "";
      // sendData.id = expertDetail?._id;
      const { data } = await getSelectedExpertTestimonialsApi(
        sendData,
        expertDetail?._id
      );
      if (data?.code === 200) {
        setTestimonialsList(data?.data || []);
      } else {
        toastMessage(data?.message, "error", "setTestimonialsList");
        setTestimonialsList([]);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      // onHide();
    }
  };

  // Fetching Only Services That Is Available
  const fetchAvailableServicesList = async () => {
    try {
      const { data } = await getAvailaleServicesApi(expertDetail?._id);
      if (data?.code === 200) {
        const filteredServices = serviceData.filter((service) => {
          const isAnyServiceIncluded = serviceData.some((service) =>
            data?.data?.includes(service.value)
          );
          if (service.value === "" && isAnyServiceIncluded) {
            return true;
          }
          return data?.data?.includes(service.value);
        });

        setAvailableService(filteredServices);
      } else {
        toastMessage(data?.message, "error", "setTestimonialsList");
        setAvailableService([]);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      onHide();
    }
  };

  const navigateHandler = (expertId, serviceId, name) => {
    const paths = {
      oneToOne: `/service/consultation/${expertId}/${serviceId}`,
      webinar: `/service/webinar/${expertId}/${serviceId}`,
      subscription: `/service/subscription/${expertId}/${serviceId}`,
      prioritydm: `/service/prioritydm/${expertId}/${serviceId}`,
      digital: `/service/digital/${expertId}/${serviceId}`,
      package: `/packages-list/detail/${expertId}/${serviceId}`,
    };

    if (name in paths) {
      navigate(paths[name]);
    }
  };

  useEffect(() => {
    if (expertDetail?._id) {
      fetchingExpertServiceDetail(true);
      // fetchingExpertServiceDetail();
      fetchExpertTestimonialsList();
      fetchAvailableServicesList();
    }
  }, [searchParam, expertDetail?._id]);

  useEffect(() => {
    if (page > 0 && expertDetail?._id) {
      fetchingExpertServiceDetail(false);
    }
  }, [page, expertDetail?._id]);

  useEffect(() => {
    fetchingExpertDetail();
  }, []);

  const serviceData = [
    {
      name: "All Services",
      value: "",
    },
    {
      name: "1 : 1 Consultation",
      value: "oneToOne",
    },
    {
      name: "Webinar",
      value: "webinar",
    },
    {
      name: "Training",
      value: "subscription",
    },
    {
      name: "Priority DM",
      value: "prioritydm",
    },
    {
      name: "Digital Contents",
      value: "digital",
    },
    {
      name: "Packages",
      value: "package",
    },
  ];

  const badgesData = ["topInCategory", "top1", "top5", "top10"];

  const serviceButtonHandler = (service) => {
    searchParam.set("serviceType", service);
    searchParam.set("page", 1);
    setSearchParam(searchParam);
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  return (
    <section className="expert_detail_section">
      <div className="container">
        <div className="expert_details">
          <div className="profile">
            {expertDetail?.image && <img src={expertDetail.image} alt="" />}
            <div className="detail">
              <h4>
                {`${expertDetail?.firstName || "---"} ${" "}${
                  expertDetail?.lastName || "---"
                }`}
              </h4>
              <span className="prime_category">
                {expertDetail?.expertise || "---"}
              </span>
              <strong>
                {`Experience : ${
                  expertDetail?.yoe?.years
                    ? `${expertDetail.yoe.years} Years`
                    : ""
                } ${
                  expertDetail?.yoe?.months
                    ? `${expertDetail?.yoe?.years ? " " : ""}${
                        expertDetail.yoe.months
                      } Months`
                    : ""
                }`}
              </strong>
              <ul className="certification_listing">
                {expertDetail?.education &&
                  expertDetail?.education.length > 0 &&
                  expertDetail.education.map((data, index) => (
                    <li key={index}>
                      <strong>{data?.education}</strong> -{" "}
                      <span>{data?.specialisation}</span>
                    </li>
                  ))}
              </ul>

              <ul className="certification_listing">
                {expertDetail?.certifications &&
                expertDetail?.certifications.length ? (
                  expertDetail.certifications.map((data, index) => (
                    <li key={index}>
                      <strong>{data?.certification}</strong> -{" "}
                      <span>{data?.institute}</span>
                    </li>
                  ))
                ) : (
                  <Skeleton count={3} />
                )}
              </ul>
            </div>
          </div>
          <RatingStar
            user={`${expertDetail?.rating?.total}${" "}(testimonials)`}
            rating={expertDetail?.rating?.avg}
            readonly
            initialRating={expertDetail?.rating?.avg}
          />

          <h5>Skills</h5>
          <ul className="skills_list">
            {expertDetail?.skills && expertDetail?.skills.length ? (
              expertDetail.skills.map((data, index) => (
                <li key={index}>{data?.name || "---"}</li>
              ))
            ) : (
              <Skeleton count={5} />
            )}
          </ul>
          <div className="batches_wrap">
            {/* <ul>
              {expertDetail?.badges && (
                <li className="top">
                  <small>Top</small>
                  <h3>
                    {expertDetail.badges[1] === "Top5"
                      ? "5"
                      : expertDetail.badges[1] === "Top1"
                      ? "1"
                      : expertDetail.badges[1] === "Top10"
                      ? "10"
                      : ""}%
                  </h3>
                </li>
              )}

              <li className="booking">
                <img src={badgesBooking} alt="" />
                <h3>{expertDetail?.badges && expertDetail?.badges[0]}</h3>
                <span>Bookings</span>
              </li>

              {expertDetail?.badges?.length >= 3 && (
                <li className="top_rated">
                  <img src={badgesRating} alt="" />
                  <span>
                    TOP <br /> RATED
                  </span>
                  <h3>{expertDetail?.expertise}</h3>
                </li>
              )}
            </ul> */}
            <div className="item">
              <div className="badges_card">
                <span>Top</span>
                <b>5%</b>
              </div>
            </div>
            <div className="item">
              <div className="badges_card">
                <span>Bookings</span>
                <b>673</b>
              </div>
            </div>
            <div className="item">
              <div className="badges_card">
                <span>Top Rated</span>
                <b>Marketing</b>
              </div>
            </div>
          </div>

          <h5>About Self</h5>

          <p>{expertDetail?.bio}</p>
          <div className="socialIcons">
            <ul>
              {expertSocialLinks && expertSocialLinks.length
                ? expertSocialLinks.map((data, index) => (
                    <li key={index}>
                      <a
                        href={
                          data?.url.startsWith("http")
                            ? data.url
                            : `https://${data.url}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {data?.logo}
                      </a>
                    </li>
                  ))
                : null}
            </ul>
          </div>
          {expertDetail?.introductionVideoLink && (
            <>
              <h5>Introduction Video</h5>
              <VideoPlayer
                width={"100%"}
                src={expertDetail.introductionVideoLink || ""}
              />
            </>
          )}
        </div>

        <div className="expert_services">
          <div className="servicesList">
            <ul>
              {availableService && availableService.length > 0 ? (
                availableService.map((data, index) => (
                  <li
                    key={index}
                    onClick={() => serviceButtonHandler(data?.value)}
                    className={`tab_button ${
                      serviceType === data.value ? "active" : ""
                    }`}
                  >
                    {data?.name}
                  </li>
                ))
              ) : (
                <Skeleton count={5} />
              )}
            </ul>
          </div>

          <InfiniteScroll
            height={1000}
            dataLength={expertServiceDetail.length}
            next={() => {
              setPage((prev) => prev + 1);
            }}
            hasMore={expertServiceDetail.length < total}
            loader={"Loading..."}
            endMessage={
              <p style={{ textAlign: "center" }}>
                {/* <b>No more services available</b> */}
              </p>
            }
          >
            <div className="service_card_wrap">
              {expertServiceDetail && expertServiceDetail.length ? (
                expertServiceDetail.map((service, index) => (
                  <Fragment key={index}>
                    <div className="services_card">
                      <button
                        onClick={() =>
                          navigateHandler(
                            expertDetail?._id,
                            service?._id,
                            service?.serviceType
                          )
                        }
                        className="item"
                      >
                        {service?.image && (
                          <div className="image_wrap">
                            {service?.image ? (
                              <img src={service.image} alt="" />
                            ) : (
                              <Skeleton height={200} />
                            )}
                          </div>
                        )}

                        <div className="content">
                          {/* <span className="service_tag">
                            {
                              availableService?.find(
                                (data) => data?.value === service?.serviceType
                              )?.name 
                              if(name==="Trainings" && service?.subscriptionType==="recurring" && service?.recurringServiceType==="public":"Group Trainings":"Personal Trainings":"Batch Trainings")
                            }
                          </span> */}
                          <span className="service_tag">
                            {(() => {
                              const serviceName = availableService?.find(
                                (data) => data?.value === service?.serviceType
                              )?.name;

                              return serviceName === "Training"
                                ? service?.subscriptionType === "recurring"
                                  ? service?.recurringServiceType === "public"
                                    ? "Group Training"
                                    : "Personal Training"
                                  : "Batch Training"
                                : serviceName;
                            })()}
                          </span>
                          <span>
                            {Array.from(
                              { length: service?.avgRating },
                              (v, i) => (
                                <IoMdStar
                                  key={i}
                                  className={
                                    i < service?.avgRating ? "filled" : "empty"
                                  }
                                />
                              )
                            )}
                          </span>
                          <h4>{service?.title || <Skeleton />}</h4>

                          {service?.serviceType === "prioritydm" ? (
                            ""
                          ) : service?.digitalContentType === "course" ? (
                            <span className="status">Digital Course</span>
                          ) : service?.digitalContentType === "product" ? (
                            <span className="status">Digital Product</span>
                          ) : service?.serviceType === "package" ? (
                            <span className="status">
                              {service?.services.length > 0 || (
                                <Skeleton width={50} />
                              )}{" "}
                              {`${service?.services.length || 1}${" "}Services`}
                            </span>
                          ) : (
                            <span className="status">
                              {service?.duration || <Skeleton width={50} />} min
                            </span>
                          )}

                          {service?.description ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: charLimit(service.description, 200),
                              }}
                            ></p>
                          ) : (
                            <p>
                              <Skeleton count={3} />
                            </p>
                          )}
                          <div className="card_date">
                            <div className="date">
                              {/* <img src={calenderIcon} alt="" /> */}
                              <p>
                                {service?.serviceType === "webinar" && (
                                  <>
                                    <b>{`${
                                      dateFormat(
                                        service?.webinarAvailability
                                      ) || "---"
                                    }`}</b>
                                    <span>Video Meeting</span>
                                  </>
                                )}
                                {service?.serviceType === "prioritydm" && (
                                  <b>{`${
                                    service?.responseTime || "---"
                                  } Response Time`}</b>
                                )}
                                {service?.serviceType === "oneToOne" && (
                                  <>
                                    <b>{`${
                                      service?.duration || "---"
                                    }-Mins`}</b>
                                    <span>Video Meeting</span>
                                  </>
                                )}

                                {service?.serviceType === "subscription" && (
                                  <>
                                    <span>
                                      {service?.subscriptionType === "oneTime"
                                        ? "Batch Training"
                                        : "Recurring Training"}
                                    </span>
                                  </>
                                )}

                                {service?.serviceType === "digital" && (
                                  <>
                                    <b>{`${
                                      service?.digitalContentType === "product"
                                        ? "Product"
                                        : "Course" || "---"
                                    }`}</b>
                                    <span>Digital Contents</span>
                                  </>
                                )}
                                {service?.serviceType === "package" && (
                                  <>
                                    <span>Package</span>
                                  </>
                                )}
                              </p>
                            </div>
                            <button type="button">
                              {service?.pricing?.showSlashedAmount ? (
                                <>
                                  <del>₹{service?.pricing?.slashAmount}</del>
                                  <b>
                                    ₹{Number(Number(service?.pricing?.amount))}
                                  </b>
                                </>
                              ) : (
                                <b>{`₹${Number(service?.pricing?.amount)}`}</b>
                              )}
                              <FiArrowRight />
                            </button>
                          </div>
                        </div>
                      </button>
                    </div>
                  </Fragment>
                ))
              ) : (
                <Placeholder text={"No Services Found For This Expert"} />
              )}
            </div>
          </InfiniteScroll>
        </div>
      </div>
      <div className="testimonial_section">
        <div className="container">
          {testimonialsList && testimonialsList.length > 0 ? (
            <TestimonialCard
              bestTestimonials={testimonialsList}
              setBestTestimonials={setTestimonialsList}
              display={false}
            />
          ) : (
            <Placeholder text="No Testimonials for this  service" />
          )}
        </div>
      </div>
    </section>
  );
};

export default ExpertDetail;
