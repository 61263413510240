import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import React, { useEffect, useState } from "react";
import { getTermsApi } from "services/master";
import { toastMessage } from "utils/toastMessage";

const TermsCondition = () => {
  const [terms,setTerms]=useState({});
  const [loader, onShow, onHide] = FullPageLoader();

  const fetchTerms = async () => {
    try {
      const sendData = {};
      sendData.type = "termsAndCondition";
      onShow();
      const { data } = await getTermsApi(sendData);
      if (data?.code === 200) {
        setTerms(data?.data || []);
        // setTotal(data?.totalItems || 0);
      } else {
        toastMessage(data?.message, "error", "terms");
        setTerms([]);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      onHide();
    }
  };

  useEffect(()=>{
    fetchTerms();
  },[])
  return (
    <>
      <div className="exploreExperts">
        <div className="container">
          <div className="page_title text-center">
            <span>Terms & conditions</span>
            {/* <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's
            </p> */}
          </div>
          <div className="spacing privacy-policy">

            {/* <p>
              Tone your body and improve your health without starving yourself
              or cutting out carbs Lorem Ipsum is simply dummy text of the
              printing and the printing.
            </p> */}
            {/* <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p> */}
            {/* <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p> */}

{terms?.text ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: terms?.text,
                    }}
                  ></p>
                ) : (
                  <p>---</p>
                )}
          
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsCondition;
