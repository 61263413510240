import React from "react";
import "./Testimonial.scss";
import { FaTimes } from "react-icons/fa";
import { dateFormat } from "utils/dateFormat";
import { IoMdStar } from "react-icons/io";

const TestimonialCard = ({
  bestTestimonials,
  setBestTestimonials,
  ...rest
}) => {
  console.log(bestTestimonials, "BestTestimonials");

  const deleteTestimonialsHandler = (testimonialId) => {
    const tempTestimonial = [...bestTestimonials];
    setBestTestimonials(
      tempTestimonial.filter((data) => data?._id !== testimonialId)
    );
  };

  return (
    <>
      {bestTestimonials && bestTestimonials?.length
        ? bestTestimonials.map((data) => (
            <div className="inner_testimonial_card">
              <div className="item">
                <div className="head">
                  {rest?.display && (
                    <span className="delete_button">
                      <FaTimes
                        onClick={() => deleteTestimonialsHandler(data?._id)}
                      />
                    </span>
                  )}

                  <h5>{`${data?.user[0]?.firstName || "---"}${" "}${
                    data?.user[0]?.lastName || "---"
                  }`}</h5>
                  <span>
                    {Array.from({ length: data?.rating }, (v, i) => (
                      <IoMdStar
                        key={i}
                        className={i < data?.rating ? "filled" : "empty"}
                      />
                    ))}
                  </span>
                </div>
                <p>{data?.message || "---"}</p>
              </div>
            </div>
          ))
        : "No Testimonials Available"}
    </>
  );
};

export default TestimonialCard;
